import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast-calendar',
  templateUrl: './toast-calendar.component.html',
  styleUrls: ['./toast-calendar.component.css']
})
export class ToastCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
