<div id="list-language" class="mt-5">
  <form class="" [formGroup]="basicForm">
    <div class="form-group row" *ngIf="itemResultList.length > 0">
      <div class="col-lg-12">
        <div class="row" *ngIf="itemResultList.length > 0">
          <ng-container *ngFor="let item of itemResultList">
            <div class="col-xl-4" *ngIf="!item._isEditMode">
              <div
                class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2"
              >
                <!--begin::Body-->
                <div class="card-body p-3">
                  <a
                    href="javascript:coid(0)"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{item.name}}</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Spoken:
                    <img
                      src="assets/media/stars/{{ item.spoken }}.png"
                      alt="image"
                      style="width: 5.5rem"
                    />
                  </span>
                  <span class="text-muted font-weight-bold d-block"
                    >Written:
                    <img
                      src="assets/media/stars/{{ item.written }}.png"
                      alt="image"
                      style="width: 5.5rem"
                    />
                  </span>
                  <div class="action-button">
                    <a
                      href="javascript:;"
                      (click)="editItemButtonOnClick(item)"
                      *ngIf="!isSwitchedToEditMode"
                      class="action-primary"
                      ><i class="fa fa-pen text-success"></i
                    ></a>
                    <a
                      href="javascript:;"
                      (click)="deleteItem(item)"
                      *ngIf="!isSwitchedToEditMode"
                      class="action-secondary"
                      ><i class="fa fa-trash text-danger"></i
                    ></a>
                  </div>
                </div>
                <!--end:: Body-->
              </div>
            </div>
          </ng-container>
        </div>
        <div
          id="edit-language"
          *ngFor="let item of itemResultList; let i = index"
        >
          <div
            class="form-group row align-items-center border-top pt-5"
            *ngIf="item._isEditMode"
          >
            <div class="col-lg-12">
              <div class="form-group row align-items-center">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3 mt-6">
                      <label>{{'RESUME.LANGUAGES.FIELD.LANGUAGE' | translate}}:</label>
                      <!-- <select formControlName="language" class="form-control">
                        <option value="">Language</option>
                        <option [value]="item.name" *ngFor="let item of allList">{{item.name}}</option>
                      </select> -->

                      <input
                        class="form-control"
                        type="text"
                        formControlName="language"
                        [placeholder]="'Language'"
                        list="languageList"
                      />
                      <datalist id="languageList">
                      
                          <option value="Arabic" class="ng-star-inserted">Arabic</option>
                          <option value="Chinese" class="ng-star-inserted">Chinese</option>
                          <option value="English" class="ng-star-inserted">English</option>
                          <option value="French" class="ng-star-inserted">French</option>
                          <option value="German" class="ng-star-inserted">German</option>
                          <option value="Hebrew (modern)" class="ng-star-inserted">Hebrew</option>
                          <option value="Hindi" class="ng-star-inserted">Hindi</option>
                          <option value="Italian" class="ng-star-inserted">Italian</option>
                          <option value="Japanese" class="ng-star-inserted">Japanese</option>
                          <option value="Korean" class="ng-star-inserted">Korean</option>
                          <option value="Portuguese" class="ng-star-inserted">Portuguese</option>
                          <option value="Russian" class="ng-star-inserted">Russian</option>
                          <option value="Spanish/Castilian" class="ng-star-inserted">Spanish/Castilian</option>
                    
                      </datalist>

                      <div
                        class="fv-plugins-message-container"
                        *ngIf="isControlHasError('language', 'required')"
                      >
                        <div class="fv-help-block">
                          <span>{{'RESUME.LANGUAGES.FIELD.LANGUAGE_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 mt-6">
                      <label>{{'RESUME.LANGUAGES.FIELD.SPOKEN' | translate}}:</label>
                      <select formControlName="spoken" class="form-control">
                        <option value="">{{'RESUME.LANGUAGES.FIELD.SPOKEN_INPUT' | translate}}</option>
                        <option value="basic">Basic</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                        <option value="native">Native</option>
      
                      </select>
                      <div
                        class="fv-plugins-message-container"
                        *ngIf="isControlHasError('spoken', 'required')"
                      >
                        <div class="fv-help-block">
                          <span>{{'RESUME.LANGUAGES.FIELD.SPOKEN_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 mt-6">
                      <label>{{'RESUME.LANGUAGES.FIELD.WRITTEN' | translate}}:</label>
                      <select formControlName="written" class="form-control">
                        <option value="">{{'RESUME.LANGUAGES.FIELD.WRITTEN_INPUT' | translate}}</option>
                        <option value="basic">Basic</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                        <option value="native">Native</option>
      
                         
                       
                      </select>
                      <div
                        class="fv-plugins-message-container"
                        *ngIf="isControlHasError('written', 'required')"
                      >
                        <div class="fv-help-block">
                          <span>{{'RESUME.LANGUAGES.FIELD.WRITTEN_REQUIRED' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 mt-15">
                      <button
                        (click)="saveUpdatedItem(item)"
                        class="btn btn-sm font-weight-bolder btn-light-success btn-icon mr-5"
                      >
                        <i class="la la-check"></i>
                      </button>
                      <button
                        (click)="cancelEditButtonOnClick(item)"
                        class="btn btn-sm font-weight-bolder btn-light-danger btn-icon mr-5"
                      >
                        <i class="la la-close"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="add-language">
      <div class="form-group row" *ngIf="itemForAdd._isEditMode">
        <div class="col-lg-12">
          <div class="form-group row align-items-center">
            <h3 class="col-lg-12 font-weight-bold">{{'RESUME.LANGUAGES.ADD_NEW_TITLE' | translate}}:</h3>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3 mt-6">
                  <label>{{'RESUME.LANGUAGES.FIELD.LANGUAGE' | translate}}:</label>
                  <!-- <select formControlName="addLanguage" class="form-control">
                    <option value="">Language</option>
                    <option [value]="item.name" *ngFor="let item of allList">{{item.name}}</option>
                  </select> -->
                  <input
                    class="form-control"
                    type="text"
                    formControlName="addLanguage"
                    [placeholder]="'Language'"
                    list="languageList"
                  />
                  <datalist id="languageList">
                    <option value="Arabic" class="ng-star-inserted">Arabic</option>
                    <option value="Chinese" class="ng-star-inserted">Chinese</option>
                    <option value="English" class="ng-star-inserted">English</option>
                    <option value="French" class="ng-star-inserted">French</option>
                    <option value="German" class="ng-star-inserted">German</option>
                    <option value="Hebrew (modern)" class="ng-star-inserted">Hebrew</option>
                    <option value="Hindi" class="ng-star-inserted">Hindi</option>
                    <option value="Italian" class="ng-star-inserted">Italian</option>
                    <option value="Japanese" class="ng-star-inserted">Japanese</option>
                    <option value="Korean" class="ng-star-inserted">Korean</option>
                    <option value="Portuguese" class="ng-star-inserted">Portuguese</option>
                    <option value="Russian" class="ng-star-inserted">Russian</option>
                    <option value="Spanish/Castilian" class="ng-star-inserted">Spanish/Castilian</option>
              
                  </datalist>

                  <div
                    class="fv-plugins-message-container"
                    *ngIf="isControlHasError('addLanguage', 'required')"
                  >
                    <div class="fv-help-block">
                      <span>{{'RESUME.LANGUAGES.FIELD.LANGUAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mt-6">
                  <label>{{'RESUME.LANGUAGES.FIELD.SPOKEN' | translate}}:</label>
                  <select formControlName="addSpoken" class="form-control">
                    <option value="">{{'RESUME.LANGUAGES.FIELD.SPOKEN_INPUT' | translate}}</option>
                    <option value="basic">Basic</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                    <option value="native">Native</option>
  
                  </select>
                  <div
                    class="fv-plugins-message-container"
                    *ngIf="isControlHasError('addSpoken', 'required')"
                  >
                    <div class="fv-help-block">
                      <span>{{'RESUME.LANGUAGES.FIELD.SPOKEN_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mt-6">
                  <label>{{'RESUME.LANGUAGES.FIELD.WRITTEN' | translate}}:</label>
                  <select formControlName="addWritten" class="form-control">
                    <option value="">{{'RESUME.LANGUAGES.FIELD.WRITTEN_INPUT' | translate}}</option>
                    <option value="basic">Basic</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                        <option value="native">Native</option>
      
                  </select>
                  <div
                    class="fv-plugins-message-container"
                    *ngIf="isControlHasError('addWritten', 'required')"
                  >
                    <div class="fv-help-block">
                      <span>{{'RESUME.LANGUAGES.FIELD.WRITTEN_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 mt-15">
                  <a
                    href="javascript:;"
                    (click)="saveNewItem()"
                    class="btn btn-sm font-weight-bolder btn-light-success btn-icon mr-5"
                  >
                    <i class="la la-check"></i
                  ></a>
                  <a
                    href="javascript:;"
                    (click)="cancelAddButtonOnClick()"
                    class="btn btn-sm font-weight-bolder btn-icon btn-light-danger mr-5"
                  >
                    <i class="la la-close"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div
    *ngIf="itemResultList.length === 0 && !isSwitchedToEditMode"
    class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b"
    role="alert"
  >
    <div class="alert-text">
      No Language added yet. Please click on the add button to add more
      languages.
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-4">
      <button
        *ngIf="!itemForAdd._isEditMode && !isSwitchedToEditMode"
        (click)="addItemButtonOnClick()"
        class="btn btn-sm font-weight-bolder btn-light-primary"
      >
        <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}
      </button>
    </div>
  </div>
</div>
<!--begin: Wizard Actions-->
<div class="d-flex justify-content-between border-top mt-5 pt-10">
  <div class="mr-2">
    <button
      *ngIf="!isPopup"
      type="button"
      class="btn btn-light-primary font-weight-bolder text-uppercase"
      (click)="prevStep()"
    >
    {{'BUTTON.PREVIOUS' | translate}}
    </button>
  </div>
  <div>
    <ng-container *ngIf="loading">
      <span class="spinner spinner-primary mr-10"></span>
    </ng-container>
    <button
      type="button"
      (click)="onSubmit()"
      class="btn btn-primary font-weight-bolder text-uppercase"
    >
    {{'BUTTON.SAVE_CONTINUE' | translate}}
    </button>
  </div>
</div>
<!--end: Wizard Actions-->
