
<div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{'ACCOUNT_SETTINGS.CHANGE_PASSWORD.TITLE' | translate}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">{{'ACCOUNT_SETTINGS.CHANGE_PASSWORD.SUB_TITLE' | translate}}</span>
      </div>
      <div class="card-toolbar">
        <ng-container >
          <!--<span class="spinner spinner-primary mr-10"></span>-->
        </ng-container>
        <button type="submit" (click)="onSubmit()" class="btn btn-primary mr-2">{{'BUTTON.SAVE' | translate}}</button>
        <button type="reset" class="btn btn-secondary">{{'BUTTON.CANCEL' | translate}}</button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" [formGroup]="changePasswordForm">
      <div class="card-body">
        <!--begin::Alert-->
        <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
          <div class="alert-icon">
            <span class="svg-icon svg-icon-3x svg-icon-danger">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                  <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                  <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span>
          </div>
          <div class="alert-text font-weight-bold">{{'ACCOUNT_SETTINGS.CHANGE_PASSWORD.WARNING' | translate}}
          </div>
          <div class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">
                <i class="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
        <!--end::Alert-->
        <div class="form-group row">            
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">
            {{'AUTH.FIELD.CURRENT_PASSWORD' | translate}}
            <a href="javascript:void(0)"  (click)="togglePassword(0)" ><i class="fa fa-{{passwordType[0] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
          </label>
          <div class="col-lg-9 col-xl-8">
            <input type="{{passwordType[0]}}" formControlName="currentPassword" minlength="8" [ngClass]="{ 'is-invalid': isControlHasError('currentPassword') }"  class="form-control form-control-lg form-control-solid mb-2" placeholder="Current Password" name="currentpassword" autocomplete="off" />
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('currentPassword','required')">
              <div class="fv-help-block">
                <span>{{ 'AUTH.VALIDATION.CURRENT_PASSWORD_REQUIRED' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">
            {{'AUTH.FIELD.NEW_PASSWORD' | translate}}
            <a href="javascript:void(0)" (click)="togglePassword(1)"><i class="fa fa-{{passwordType[1] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
            
          </label>
          <div class="col-lg-9 col-xl-8">
            <input type="{{passwordType[1]}}" formControlName="password" minlength="8" class="form-control form-control-lg form-control-solid" [ngClass]="{ 'is-invalid': isControlHasError('password') }" placeholder="Password" name="newPassword" autocomplete="off" [(ngModel)]="PasswordData.password" />
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password','required')">
              <div class="fv-help-block">
                <span>{{ 'AUTH.VALIDATION.NEW_PASSWORD_REQUIRED' | translate }}</span>
              </div>
            </div>
            <span class="form-text text-muted">{{ 'AUTH.FIELD.PASSWORD_HINT' | translate }}
            </span>
            
            <div class="password-strength">
              <span class="{{passwordStrength('minLength') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_LENGTH' | translate }}</span>
              <span class="{{passwordStrength('uppercase') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_UPPERCASE' | translate }}</span>
              <span class="{{passwordStrength('number') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_NUMERIC' | translate }}</span>
              <span class="{{passwordStrength('special') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_SPECIAL' | translate }}</span>
          </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">
            {{'AUTH.FIELD.CONFIRM_PASSWORD' | translate}}
            <a href="javascript:void(0)" (click)="togglePassword(2)"><i class="fa fa-{{passwordType[2] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
          </label>
          <div class="col-lg-9 col-xl-8">
            <input type="{{passwordType[2]}}" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': isControlHasError('confirmPassword') }"   class="form-control form-control-lg form-control-solid" placeholder="Confirm Password" name="confirmpassword" autocomplete="off" [(ngModel)]="PasswordData.confirmpassword"/>
            <div class="password-strength">
              <span class="{{passwordMatch() ? 'active' :''}}" >{{passwordmatch}}</span>                                          
          </div>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('confirmPassword','required')">
              <div class="fv-help-block">
                <span>{{ 'AUTH.VALIDATION.CONFIRM_PASSWORD_REQUIRED' | translate }}</span>
              </div>
            </div>
            <div class="fv-plugins-message-container" *ngIf="changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').errors.ConfirmPassword && changePasswordForm.get('confirmPassword').touched">
              <div class="fv-help-block">
                <span>{{ 'AUTH.VALIDATION.PASSWORD_MISSMATCH' | translate }}</span>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  