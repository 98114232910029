<div  class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert" *ngIf="!isAddOrEditShow && !(commitmentLists.length > 0)">
  <div class="alert-text">{{'SOCIAL.COMMITMENTS.NO_DATA' | translate }} </div>
  </div>
  
  <form class="" [formGroup]="basicForm" *ngIf="basicForm">
    <div *ngIf="commitmentLists.length > 0">
      <div class="row">
        <ng-container *ngFor="let list of commitmentLists">
          <div class="col-xl-6">
            <div class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2">
              <!--begin::Body-->
              <div class="card-body p-3">
                <div class="font-size-md">
                  <span class="text-dark-75 font-weight-bolder">{{list.type}}</span>
                </div>
                <div class="font-size-sm">
                  <span class="text-muted">{{'SOCIAL.COMMITMENTS.FIELD.AMOUNT' | translate}}: </span>
                  <span class="text-danger font-weight-bolder">{{list.currency}} {{list.amount}}</span>
                  <span class="ml-10 text-muted">Monthly EMI: </span>
                  <span class="text-success font-weight-bolder">{{list.currency}} {{list.emi}}</span>
                </div>
                <div class="pt-3 border-top mt-3">
                  <div class="font-size-sm">
                    <span class="text-muted">{{'SOCIAL.COMMITMENTS.FIELD.WITH_WHOM' | translate}}: </span>
                    <span class="text-danger font-weight-bolder">{{list.whom}}</span>
                  </div>
                  <div class="font-size-sm">
                    <span class="text-dark-20 font-weight-bold">{{list.description}}</span>
                  </div>
                <div class="action-button">
                  <a href="javascript:;"  *ngIf="!isAddOrEditShow" (click)="edit(list)" class="action-primary"><i class="fa fa-pen text-success"></i></a>
                  <a href="javascript:;" *ngIf="!isAddOrEditShow" (click)="delete(list)" class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                </div>
              </div>
              </div>
              <!--end:: Body-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  <div *ngIf="isAddOrEditShow">
    <div class="form-group row align-items-center border-top pt-5">
      <div class="col-lg-12">
        <div class="form-group row align-items-center">
          <h3 class="col-lg-12  font-weight-bold">{{'SOCIAL.COMMITMENTS.ADD_NEW_TITLE' | translate}}</h3>
          <div class="col-lg-12">
            <div class="row">
              
              <div class="col-lg-6 mt-6" >
                <label>{{'SOCIAL.COMMITMENTS.FIELD.TYPE' | translate}}</label>
                <input type="text" class="form-control" formControlName="type" [(ngModel)]="basicForm.type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }" placeholder="{{'SOCIAL.COMMITMENTS.FIELD.TYPE_INPUT' | translate}}" maxlength="40"/>
                <div class="fv-plugins-message-container float-right">
                  <div class="fv-help-block">
                    <span>({{ 40 - (basicForm.type ? basicForm.type.length : 0) }}/40)</span>
                  </div>
                </div>
                <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.type.errors" >
                  <div class="fv-help-block" *ngIf="f.type.errors.required">
                    <span>{{'SOCIAL.COMMITMENTS.FIELD.TYPE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6 mt-6">
                <label>{{'SOCIAL.COMMITMENTS.FIELD.WITH_WHOM' | translate}}</label>
                <input formControlName="whom" class="form-control" [(ngModel)]="basicForm.whom" [ngClass]="{ 'is-invalid': submitted && f.whom.errors }" placeholder="{{'SOCIAL.COMMITMENTS.FIELD.WITH_WHOM_INPUT' | translate}}" maxlength="40"/> 
                <div class="fv-plugins-message-container float-right">
                  <div class="fv-help-block">
                    <span>({{ 40 - (basicForm.whom ? basicForm.whom.length : 0) }}/40)</span>
                  </div>
                </div>
                <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.whom.errors" >
                  <div class="fv-help-block" *ngIf="f.whom.errors.required">
                    <span>{{'SOCIAL.COMMITMENTS.FIELD.WITH_WHOM_REQUIRED' | translate}}</span>
                  </div>
                 
                </div>
              </div>
              </div>
              <div class="row">
                <div class="col-lg-4 mt-6" >
                  <label>{{'SOCIAL.COMMITMENTS.FIELD.CURRENCY' | translate}}</label>
                  <select formControlName="currency" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }"> 
                    <option value="US$">US Dollar (US$)</option>
                    <option value="Q">Quetzal (Q)</option>
                    <option value="L">Lempira (L)</option>
                    <option value="C$">Córdoba (C$)</option>
                    <option value="EUR">EURO (€)</option>
                    <option value="GBP">GBP (£)</option>
                  </select>
                  <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.currency.errors" >
                    <div class="fv-help-block" *ngIf="f.currency.errors.required">
                      <span>{{'SOCIAL.COMMITMENTS.FIELD.CURRENCY_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mt-6" >
                  <label>{{'SOCIAL.COMMITMENTS.FIELD.AMOUNT' | translate}}</label>
                  <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57' class="form-control" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" placeholder="{{'SOCIAL.COMMITMENTS.FIELD.AMOUNT_INPUT' | translate}}"/>
                  <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.amount.errors" >
                    <div class="fv-help-block" *ngIf="f.amount.errors.required">
                      <span>{{'SOCIAL.COMMITMENTS.FIELD.AMOUNT_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 mt-6" >
                  <label>{{'SOCIAL.COMMITMENTS.FIELD.EMI' | translate}}</label>
                  <input  type="number" min="0" class="form-control" formControlName="emi" [ngClass]="{ 'is-invalid': submitted && f.emi.errors }" placeholder="{{'SOCIAL.COMMITMENTS.FIELD.EMI_INPUT' | translate}}"/>
                  <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.emi.errors" >
                    <div class="fv-help-block" *ngIf="f.emi.errors.required">
                      <span>{{'SOCIAL.COMMITMENTS.FIELD.EMI_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 mt-6">
                  <label>{{'SOCIAL.COMMITMENTS.FIELD.DESCRIPTION' | translate}}</label>
                  <textarea  autosize row="3" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" placeholder="{{'SOCIAL.COMMITMENTS.FIELD.DESCRIPTION_INPUT' | translate}}"> </textarea>
                  <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.description.errors" >
                    <div class="fv-help-block" *ngIf="f.description.errors.required">
                      <span>{{'SOCIAL.COMMITMENTS.FIELD.DESCRIPTION_REQUIRED' | translate}}</span>
                    </div>
                   
                  </div>
                </div>
                <div class="col-lg-6 mt-15">
                  <button  class="btn btn-sm font-weight-bolder btn-light-success mr-5" (click)="add()">
                    <i class="la la-check"></i></button>
                  <button  class="btn btn-sm font-weight-bolder btn-light-danger mr-5" (click)="close()">
                    <i class="la la-close"></i></button>
                </div>

              
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="!isAddOrEditShow"> 
  <div class="col-lg-4">
      <button  class="btn btn-sm font-weight-bolder btn-light-primary" (click)="addSocialLink()">
          <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
  </div>
  </div>
  <div class="d-flex justify-content-between border-top mt-5 pt-10">
      <div class="mr-2">
        <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
      </div>
      <div>
        <ng-container *ngIf="loading">
          <span class="spinner spinner-primary mr-10"></span>
        </ng-container>
        <button
          type="button"
          (click)="onSubmit()"
          class="btn btn-primary font-weight-bolder text-uppercase"
        >
         {{'BUTTON.SAVE_EXIT' | translate}}    </button>
      </div>
    </div>
  </form>
  