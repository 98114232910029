<div class="row">
    <div class="col-xl-12">
      <div id="list-computer" (click)="showTab1 = !showTab1" class="bg-light-primary pl-5 font-weight-bold py-4 mb-2 pr-5 mouse-over">
          {{'RESUME.COMPUTER_SKILLS.TITLE' | translate}}
          <span class="float-right">
            <i class="fa fa-{{!showTab1 ? 'plus' : 'minus'}}"></i>
          </span>
      </div>
      <ng-container *ngIf="showTab1">
        <form class="" [formGroup]="basicForm">
          <div class="form-group row" *ngIf="itemResultList.length > 0">
            <div class="col-lg-12">
              <div class="row" *ngIf="itemResultList.length > 0">
                <ng-container *ngFor="let item of itemResultList;let i = index">
                  <div class="col-xl-6" *ngIf="!item._isEditMode && (item.type.toLowerCase() === 'office automation')">
                    <div class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2">
                      <!--begin::Body-->
                      <div class="card-body p-3">
                        <a href="javascript:coid(0)" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ getOfficeAutomationLabel(item.name) | titlecase}}</a>
                        <span class="text-muted font-weight-bold d-block">
                          {{item.type | titlecase}}
                        </span>
                        <span class="text-muted font-weight-bold d-block">Level:
                          <img src="assets/media/stars/{{item.level}}.png" alt="image" style="width: 5.5rem">
                        </span>
                        <div class="action-button">
                          <a href="javascript:;" (click)="editItemButtonOnClick(item, 1, i)" *ngIf="!isSwitchedToEditMode" class="action-primary"><i class="fa fa-pen text-success"></i></a>
                          <a href="javascript:;" (click)="deleteItem(i)" *ngIf="!isSwitchedToEditMode" class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                        </div>
                      </div>
                      <!--end:: Body-->
                    </div>
                  </div>
                </ng-container>
              </div>
              <div id="edit-computer" *ngFor="let item of itemResultList; let i = index">
                <div class="form-group row align-items-center border-top pt-5" *ngIf="item._isEditMode && (actionTab === 1)">
                  <div class="col-lg-12">
                    <div class="form-group row align-items-center">
                      <div class="col-lg-12">
                        <div class="row">
                          
                          <div class="col-lg-6 mt-6">
                            <label>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME' | translate}}:</label>
                            <p-dropdown [options]="FilterOfficeAutomationlist" editable="true" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" [(ngModel)]="name" name="name"  optionLabel="name" optionValue="value" formControlName="name" [filter]="true"
                            filterBy="name"></p-dropdown>
                            <div class="fv-plugins-message-container" *ngIf="isControlHasError('name','required')">
                              <div class="fv-help-block">
                                <span>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mt-6">
                            <label>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL' | translate}}:</label>
                            <select formControlName="level" class="form-control form-control-lg">
                              <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                              <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | titlecase}}</option>
                            </select>
                            <div class="fv-plugins-message-container" *ngIf="isControlHasError('level','required')">
                              <div class="fv-help-block">
                                <span>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-12 d-flex justify-content-end mt-3">
                        <button (click)="saveUpdatedItem(item)" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                          <i class="la la-check"></i></button>
                        <button (click)="cancelEditButtonOnClick(item)" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                          <i class="la la-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="add-computer">
            <div class="form-group row border-top pt-5" *ngIf="itemForAdd._isEditMode && (actionTab === 1)">
              <div class="col-lg-12">
                <div class="form-group row align-items-center">
                  <h3 class="col-lg-12  font-weight-bold">{{'RESUME.COMPUTER_SKILLS.ADD_NEW_TITLE' | translate}}</h3>
                  <div class="col-lg-12">
                    <div class="row">
                      <!-- <div class="col-lg-3 mt-6">
                        <label>Type:</label>
                        <select formControlName="addType" class="form-control">
                          <option value="">Select Type</option>
                          <option value="office automation" selected>Office Automation</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addType','required')">
                          <div class="fv-help-block">
                            <span>Type is required</span>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-lg-6 mt-6">
                        <label>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME' | translate}}:</label>
                        <p-dropdown [options]="FilterOfficeAutomationlist" editable="true" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" [(ngModel)]="addName" name="addName"  optionLabel="name" optionValue="value" formControlName="addName" [filter]="true"
                            filterBy="name"></p-dropdown>
                        <!--<input type="text" class="form-control" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" formControlName="addName" list="FilterOfficeAutomationlist"/>
                        <datalist id="FilterOfficeAutomationlist">
                          <option *ngFor="let item of FilterOfficeAutomationlist" [value]="item">
                        </datalist>-->
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addName','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mt-6">
                        <label>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL' | translate}}:</label>
                        <select formControlName="addLevel" class="form-control form-control-lg">
                          <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                          <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | titlecase}}</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addLevel','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    
                  </div>
                  <div class="col-lg-12 d-flex justify-content-end mt-3">
                    <a href="javascript:;" (click)="saveNewItem()" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                      <i class="la la-check"></i></a>
                    <a href="javascript:;" (click)="cancelAddButtonOnClick()" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                      <i class="la la-close"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="!isSwitchedToEditMode && itemResultList.length < 1" class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert">
          <div class="alert-text">{{'RESUME.COMPUTER_SKILLS.NO_DATA_ADD_MESSAGE' | translate}}
             <!-- No computer skills added yet. Please click on the add button to add more skills. -->
            </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-4">
            <button *ngIf="!itemForAdd._isEditMode && !isSwitchedToEditMode" (click)="addItemButtonOnClick(1)" class="btn btn-sm font-weight-bolder btn-light-primary">
              <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
          </div>
        </div>
      </ng-container>
  
      <div id="list-additional" (click)="showTab2 = !showTab2" class="bg-light-primary pl-5 font-weight-bold py-4 mb-2 pr-5 mouse-over">
        {{'RESUME.COMPUTER_SKILLS.ADVANCED_TITLE' | translate}}
          <span class="float-right">
            <i class="fa fa-{{!showTab2 ? 'plus' : 'minus'}}"></i>
          </span>
      </div>
      <ng-container *ngIf="showTab2">
        
        <form class="" [formGroup]="basicForm">
          <div class="form-group row" *ngIf="itemResultList.length > 0">
            <div class="col-lg-12">
              <div class="row" *ngIf="itemResultList.length > 0">
                <ng-container *ngFor="let item of itemResultList; let i = index">
                  <div class="col-xl-6" *ngIf="!item._isEditMode && (item.type.toLowerCase() !== 'office automation')">
                    <div class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2">
                      <!--begin::Body-->
                      <div class="card-body p-3">
                        <a href="javascript:coid(0)" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item.name}}</a>
                        <span class="text-muted font-weight-bold d-block">
                          {{item.type | titlecase}}
                        </span>
                        <span class="text-muted font-weight-bold d-block">Level:
                          <img src="assets/media/stars/{{item.level}}.png" alt="image" style="width: 5.5rem">
                        </span>
                        <div class="action-button">
                          <a href="javascript:;" (click)="editItemButtonOnClick(item, 2, i)" *ngIf="!isSwitchedToEditMode" class="action-primary"><i class="fa fa-pen text-success"></i></a>
                          <a href="javascript:;" (click)="deleteItem(i)" *ngIf="!isSwitchedToEditMode" class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                        </div>
                      </div>
                      <!--end:: Body-->
                    </div>
                  </div>
                </ng-container>
              </div>
              <div id="edit-additional" *ngFor="let item of itemResultList; let i = index">
                <div class="form-group row align-items-center border-top pt-5" *ngIf="item._isEditMode && (actionTab === 2)">
                  <div class="col-lg-12">
                    <div class="form-group row align-items-center">
                      <div class="col-lg-12">
                        <div class="row">
                          <div class="col-lg-5 mt-6">
                            <label>{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE' | translate}}:</label>
                            <select formControlName="type" class="form-control" (change)='filterbyType($event)' [(ngModel)]="type">
                              <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE_INPUT' | translate}}</option>
                              <option value="Programming Languages / Frameworks">Programming Languages / Frameworks</option>
                              <option value="Databases">Databases</option>
                              <option value="Hardware / Servers / Cloud">Hardware / Servers / Cloud</option>
                            </select>
                            <div class="fv-plugins-message-container" *ngIf="isControlHasError('type','required')">
                              <div class="fv-help-block">
                                <span>{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 mt-6">
                            <label>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME' | translate}}:</label>
                            <p-dropdown [options]="Filterlist"  placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" optionLabel="name" optionValue="name" formControlName="name" [filter]="true"
                            filterBy="name" [(ngModel)]="name"></p-dropdown>
                            <!--<input type="text" class="form-control" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" formControlName="name" list="Filterlist"/>
                            <datalist id="Filterlist">
                              <option *ngFor="let item of Filterlist" [value]="item">
                            </datalist>-->
                            <div class="fv-plugins-message-container" *ngIf="isControlHasError('name','required')">
                              <div class="fv-help-block">
                                <span>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 mt-6">
                            <label>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL' | translate}}:</label>
                            <select formControlName="level" class="form-control" [(ngModel)]="level">
                              <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                              <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | translate | titlecase}}</option>
                            </select>
                            <div class="fv-plugins-message-container" *ngIf="isControlHasError('level','required')">
                              <div class="fv-help-block">
                                <span>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-3 mt-15 d-flex">
                        <button (click)="saveUpdatedItem(item)" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                          <i class="la la-check"></i></button>
                        <button (click)="cancelEditButtonOnClick(item)" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                          <i class="la la-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="add-additional">
            <div class="form-group row border-top pt-5" *ngIf="itemForAdd._isEditMode && (actionTab === 2)">
              <div class="col-lg-12">
                <div class="form-group row align-items-center">
                  <h3 class="col-lg-12  font-weight-bold">{{'RESUME.COMPUTER_SKILLS.ADD_NEW_TITLE' | translate}}</h3>
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-5 mt-6">
                        <label>{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE' | translate}}:</label>
                        <select formControlName="addType" class="form-control" (change)='filterbyType($event)'>
                          <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE_INPUT' | translate}}</option>
                          <option value="Programming Languages / Frameworks">{{'RESUME.COMPUTER_SKILLS.OPTIONS.PROGRAMMING' | translate}}</option>
                          <option value="Databases">{{'RESUME.COMPUTER_SKILLS.OPTIONS.DATABASES' | translate}}</option>
                          <option value="Hardware / Servers / Cloud">{{'RESUME.COMPUTER_SKILLS.OPTIONS.HARDWARE' | translate}}</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addType','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.COMPUTER_SKILLS.FIELD.TYPE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-7 mt-6">
                        <label>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME' | translate}}:</label>
                        <p-dropdown [options]="Filterlist"  placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" [(ngModel)]="addName" optionLabel="name" optionValue="name" formControlName="addName" [filter]="true"
                            filterBy="name" editable="true" ></p-dropdown>
                        <!--<p-dropdown [options]="SkillsFilterlist" editable="true" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" name="addName" optionLabel="name" optionValue="name" formControlName="addName" [filter]="true"
                            filterBy="name"></p-dropdown>-->
                        <!--<input type="text" class="form-control" placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" formControlName="addName" list="SkillsFilterlist"/>
                        <datalist id="SkillsFilterlist">
                          <option *ngFor="let item of Filterlist" [value]="item">
                        </datalist>-->
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addName','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 mt-6">
                        <label>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL' | translate}}:</label>
                        <select formControlName="addLevel" class="form-control">
                          <option value="">{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                          <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | titlecase}}</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('addLevel','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.COMPUTER_SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div class="col-lg-3 mt-15 d-flex">
                    <a href="javascript:;" (click)="saveNewItem()" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                      <i class="la la-check"></i></a>
                    <a href="javascript:;" (click)="cancelAddButtonOnClick()" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                      <i class="la la-close"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="!isSwitchedToEditMode && itemResultList.length < 1" class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert">
          <div class="alert-text">No advanced IT skills added yet. Please click on the add button to add more skills.</div>
        </div>
        <div class="form-group row">
          <div class="col-lg-4">
            <button *ngIf="!itemForAdd._isEditMode && !isSwitchedToEditMode" (click)="addItemButtonOnClick(2)" class="btn btn-sm font-weight-bolder btn-light-primary">
              <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
          </div>
        </div>
      </ng-container>
    </div>
  
  </div>
  <!--begin: Wizard Actions-->
  <div class="d-flex justify-content-between border-top mt-5 pt-10">
    <div>
      <button type="button" (click)="onSubmit()" class="btn btn-primary font-weight-bolder text-uppercase">{{!isUpdated ? ('BUTTON.SKIP_CONTINUE' | translate) : ('BUTTON.SAVE_CONTINUE' | translate) }}</button>
    </div>
  </div>
  <!--end: Wizard Actions-->
  