<form class="form" [formGroup]="basicForm">
  <div class="row">
    <label class="col-xl-3"></label>
    <div class=" col-lg-9 col-xl-9">
      <h5 class="font-weight-bold mt-10 mb-6">{{'SOCIAL.LOCATION.ADDRESS_TITLE' | translate}}
      </h5>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.STREET' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <textarea autosize class="form-control form-control-lg form-control-solid" type="text" rows="2" data-expandable
        maxlength="250" formControlName="addressLine1" [ngClass]="{ 'is-invalid': isControlHasError('addressLine1') }"
        placeholder="{{'SOCIAL.LOCATION.FIELD.STREET_INPUT' | translate}}"></textarea>
      <div class="fv-plugins-message-container float-right">
        <div class="fv-help-block">
          <span>({{(basicForm.get('addressLine1').value?.length || 0)}}/250)</span>
        </div>
      </div>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('addressLine1','required')">
        <div class="fv-help-block">
          <span>Street Address is required</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.ZONE' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <textarea autosize class="form-control form-control-lg form-control-solid" type="text" rows="2" data-expandable
        maxlength="250" formControlName="addressLine2" [ngClass]="{ 'is-invalid': isControlHasError('addressLine2') }"
        placeholder="{{'SOCIAL.LOCATION.FIELD.ZONE_INPUT' | translate}}"></textarea>
      <div class="fv-plugins-message-container float-right">
        <div class="fv-help-block">
          <span>({{(basicForm.get('addressLine2').value?.length || 0)}}/250)</span>
        </div>
      </div>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('addressLine2','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.ZONE_REQUIRED' | translate}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.COUNTRY' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <select formControlName="country" (change)="getStateList($event)"
        class="form-control form-control-lg form-control-solid" [(ngModel)]="country">
        <option value="" selected disabled>{{'SOCIAL.LOCATION.FIELD.COUNTRY_INPUT' | translate}}</option>
        <option *ngFor="let country of countryList" [value]="country.name">{{ country.name | titlecase }}</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('country','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.COUNTRY_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">
      {{'SOCIAL.LOCATION.FIELD.STATE' | translate}}
    </label>
    <div class="col-lg-9 col-xl-6">

      <select formControlName="state" (change)="getCityList($event)"
        class="form-control form-control-lg form-control-solid" [(ngModel)]="state">
        <option value="" selected disabled>{{'SOCIAL.LOCATION.FIELD.STATE_INPUT' | translate}}</option>
        <option *ngFor="let state of StateList" [value]="state.name">{{ state.name }}</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('state','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.STATE_REQUIRED' | translate}}</span>
        </div>
      </div>

    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">
      {{'SOCIAL.LOCATION.FIELD.CITY' | translate}}
    </label>
    <div class="col-lg-9 col-xl-6">
      <select formControlName="city" class="form-control form-control-lg form-control-solid" [(ngModel)]="city">
        <option value="" selected disabled>{{'SOCIAL.LOCATION.FIELD.CITY_INPUT' | translate}}</option>
        <option *ngFor="let city of CityList" [value]="city.name">{{ city.name }}</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('city','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.CITY_REQUIRED' | translate}}</span>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <label class="col-xl-3"></label>
    <div class=" col-lg-9 col-xl-9">
      <h5 class="font-weight-bold mt-10 mb-6">{{'SOCIAL.LOCATION.RESIDENCE_DETAILS' | translate}}
      </h5>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.HOUSE_TYPE' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <select formControlName="houseType" class="form-control form-control-lg form-control-solid">
        <option value="">{{'SOCIAL.LOCATION.FIELD.HOUSE_TYPE_INPUT' | translate}}</option>
        <option value="own house">{{'SOCIAL.LOCATION.HOUSE_TYPE.OWN_HOUSE' | translate}}</option>
        <option value="rented house">{{'SOCIAL.LOCATION.HOUSE_TYPE.RENTED_HOUSE' | translate}}</option>
        <option value="family">{{'SOCIAL.LOCATION.HOUSE_TYPE.FAMILY' | translate}}</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('houseType','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.HOUSE_TYPE_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.YEARS_LIVING' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <input class="form-control form-control-lg form-control-solid" type="text" max="99"
        onKeyPress="if(this.value.length==2) return false;return event.charCode >= 48 && event.charCode <= 57"
        formControlName="yearOfLiving" [ngClass]="{ 'is-invalid': isControlHasError('yearOfLiving') }"
        placeholder="{{'SOCIAL.LOCATION.FIELD.YEARS_LIVING_INPUT' | translate}}" />
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('yearOfLiving','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.YEARS_LIVING_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.LOCATION.FIELD.NO_PEOPLE' | translate}}</label>
    <div class="col-lg-9 col-xl-6">
      <input class="form-control form-control-lg form-control-solid" type="text" max="99"
        onKeyPress="if(this.value.length==2) return false;return event.charCode >= 48 && event.charCode <= 57"
        formControlName="noOfPeople" [ngClass]="{ 'is-invalid': isControlHasError('noOfPeople') }"
        placeholder="{{'SOCIAL.LOCATION.FIELD.NO_PEOPLE_INPUT' | translate}}" />
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('noOfPeople','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.LOCATION.FIELD.NO_PEOPLE_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</form>
<!--begin: Wizard Actions-->
<div class="d-flex justify-content-between border-top mt-5 pt-10">
  <div class="mr-2">
    <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" data-wizard-type="action-prev">Previous</button> -->
  </div>
  <div>
    <ng-container *ngIf="loading">
      <span class="spinner spinner-primary mr-10"></span>
    </ng-container>
    <button type="button" (click)="onSubmit()"
      class="btn btn-primary font-weight-bolder text-uppercase">{{'BUTTON.SAVE_EXIT' | translate}}</button>
  </div>
</div>
<!--end: Wizard Actions-->