<!--begin::Subheader-->
<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
  <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
              <!--begin::Page Title-->
              <h5 class="text-dark font-weight-bold my-1 mr-5"> My Account</h5>
              <!--end::Page Title-->
              <!--begin::Breadcrumb-->

              <!--end::Breadcrumb-->
          </div>
          <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->

  </div>
</div>
<!--end::Subheader-->
<div class="d-flex flex-column-fluid" >
    <!--begin::Container-->
    <div class="container">
      <!--begin::Profile Personal Information-->
      <div class="row">
        <!--begin::Aside-->
        <div class="col-lg-4 mb-10" id="kt_profile_aside">
          <!--begin::Profile Card-->
          <div class="card card-custom card-stretch">
            <!--begin::Body-->
            <div class="card-body pt-4">
              <!--begin::Toolbar-->
              <div class="d-flex justify-content-end">
              </div>
              <!--end::Toolbar-->
              <!--begin::User-->
              <div class="d-flex align-items-center">
                <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div class="symbol-label" >
                    <img class="symbol-label" [src]="profileImage ? profileImage : '../../assets/media/users/blank.png'">
                  </div>
                  <i class="symbol-badge bg-success"></i>
                </div>
                <div>
                  <a href="javascript:void(0);" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{candidateName ? candidateName : ''}}</a>
                  <div class="text-muted font-size-sm">{{candidateEmail}}</div>
                  <div class="mt-2">
                  </div>
                </div>
              </div>
              <!--end::User-->
              <!--end::Contact-->
              <!--begin::Nav-->
              <div class="navi navi-bold navi-hover navi-active navi-link-rounded mt-10">
                <div class="navi-item mb-2">
                  <a href="javascript:void(0)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/account/account-info" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text font-size-lg">{{'ACCOUNT_SETTINGS.ACCOUNT.TITLE' | translate}}</span>
                  </a>
                </div>
                <!--<div class="navi-item mb-2" >
                  <a href="javascript:void(0)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/account/change-password" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                            <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3" />
                            <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span class="navi-text font-size-lg">{{'ACCOUNT_SETTINGS.CHANGE_PASSWORD.TITLE' | translate}}</span>
                  </a>
                </div>-->
                <div class="navi-item mb-2">
                  <a href="javascript:void(0)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/account/notification-preference" class="navi-link py-4">
                    <span class="navi-icon mr-2">
                      <span class="svg-icon">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Stockholm-icons-/-Communication-/-RSS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                <circle id="Oval-40" fill="#000000" cx="6" cy="18" r="3"></circle>
                                <path d="M16.5,21 L13.5,21 C13.5,15.2010101 8.79898987,10.5 3,10.5 L3,7.5 C10.4558441,7.5 16.5,13.5441559 16.5,21 Z" id="Oval-53" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path d="M22.5,21 L19.5,21 C19.5,12.163444 11.836556,4.5 3,4.5 L3,1.5 C13.4934102,1.5 22.5,10.5065898 22.5,21 Z" id="Oval-53-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="navi-text font-size-lg">{{'ACCOUNT_SETTINGS.NOTIFICATION_PREFERENCE.TITLE' | translate}}</span>
                  </a>
                </div>
              </div>
              <!--end::Nav-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Profile Card-->
        </div>
        <!--end::Aside-->
        <!--begin::Content-->
        <div class="col-lg-8 mb-10">
          <!--begin::Card-->
          <router-outlet></router-outlet>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Profile Personal Information-->
    </div>
    <!--end::Container-->
  </div>
  
