<div class="pbmb-5">
<div class="card card-custom card-stretch">
  <!--begin::Header-->
  <div class="card-header py-3">
    <div class="card-title align-items-start flex-column">
      <h3 class="card-label font-weight-bolder text-dark">{{'ACCOUNT_SETTINGS.ACCOUNT.TITLE' | translate}}   </h3>
      <span class="text-muted font-weight-bold font-size-sm mt-1">{{'ACCOUNT_SETTINGS.ACCOUNT.SUB_TITLE' | translate}} </span>
    </div>
    <div class="card-toolbar">
      <!--<ng-container >
        <span class="spinner spinner-primary mr-10"></span>
      </ng-container>-->
      <button type="submit" (click)="onSubmit()"  class="btn btn-primary mr-2">{{'BUTTON.SAVE' | translate}}</button>
      <button type="reset" (click)="initForm()" class="btn btn-secondary">{{'BUTTON.RESET' | translate}}</button>
    </div>
  </div>
  <!--end::Header-->
  <!--begin::Form-->
  <form class="form" *ngIf="userUpdateForm" [formGroup]="userUpdateForm">
    <!--begin::Body-->
    <div class="card-body">      
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">{{'AUTH.FIELD.NICKNAME' | translate}}</label>
        <div class="col-lg-9 col-xl-8">
          <div class="input-group input-group-lg input-group-solid">
            <input type="text" class="form-control form-control-lg form-control-solid" formControlName="nickName" [(ngModel)]="basicData.nickName" [ngClass]="" [placeholder]="" />
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">{{'AUTH.FIELD.EMAIL' | translate}}</label>
        <div class="col-lg-9 col-xl-8">
          <div class="input-group input-group-lg input-group-solid">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="la la-at"></i>
              </span>
            </div>
            <input type="email" class="form-control form-control-lg form-control-solid" formControlName="emailID" [(ngModel)]="basicData.emailID" readonly="" [ngClass]="" [placeholder]="" />
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">{{'AUTH.FIELD.PHONE' | translate}}</label>
        <div class="col-lg-9 col-xl-8">
          <div class="input-group input-group-lg input-group-solid">
            <div class="input-group-prepend row">
              <span class="input-group-text">
                <i class="la la-phone"></i>
              </span>
            </div>
            <div class="row">
              <div class="col-6 p-0">
                <!-- placeholder="{{'AUTH.FIELD.PHONE_INPUT_COUNTRY' | translate}}"  -->
                <p-dropdown class="form-control h-auto border-0 rounded-lg font-size-h6" 
                  id="uniquePhoneCode"
                  placeholder="{{basicData.phoneCode}}" 
                  name="phoneCode" 
                  formControlName="phoneCode" 
                  [(ngModel)]="basicData.phoneCode" 
                  [options]="phonecodeiso"
                  optionLabel="value"
                  optionValue="value"
                  [filter]="true"
                  filterBy="value">
                </p-dropdown>                       
              </div>
              <div class="col-6 pl-1 pt-2">
                <input type="text" class="form-control form-control-lg form-control-solid" formControlName="phone" [(ngModel)]="basicData.phoneNumber" readonly="" [ngClass]="" [placeholder]=""  />
              </div>
            </div>
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
          <div class="fv-plugins-message-container" >
            <div class="fv-help-block">
              <span></span>
            </div>
          </div>
          <!-- <span class="form-text text-muted">Enter your phone number along with country code to continue (eg:+919876543210)</span> -->

          <!-- <button type="button" class="btn btn-light-primary font-weight-bold btn-sm">Change Login Email</button> -->
        </div>
      </div>

      <div class="form-group row">            
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">
          {{'AUTH.FIELD.CURRENT_PASSWORD' | translate}}
          <a href="javascript:void(0)"  (click)="togglePassword(0)" ><i class="fa fa-{{passwordType[0] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
        </label>
        <div class="col-lg-9 col-xl-8">
          <input type="{{passwordType[0]}}" formControlName="currentPassword" minlength="8" [ngClass]="{ 'is-invalid': isControlHasError('currentPassword') }"  
          class="form-control form-control-lg form-control-solid mb-2" placeholder="{{'AUTH.FIELD.CURRENT_PASSWORD' | translate}}" name="currentpassword" autocomplete="off" />
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('currentPassword','required')">
            <div class="fv-help-block">
              <span>{{ 'AUTH.VALIDATION.CURRENT_PASSWORD_REQUIRED' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">
          {{'AUTH.FIELD.NEW_PASSWORD' | translate}}
          <a href="javascript:void(0)" (click)="togglePassword(1)"><i class="fa fa-{{passwordType[1] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
          
        </label>
        <div class="col-lg-9 col-xl-8">
          <input type="{{passwordType[1]}}" formControlName="password" [(ngModel)]="password" minlength="8" class="form-control form-control-lg form-control-solid" 
          [ngClass]="{ 'is-invalid': isControlHasError('password') }" placeholder=" {{'AUTH.FIELD.NEW_PASSWORD' | translate}}" name="newPassword" autocomplete="off" [(ngModel)]="PasswordData.password" />
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('password','required')">
            <div class="fv-help-block">
              <span>{{ 'AUTH.VALIDATION.NEW_PASSWORD_REQUIRED' | translate }}</span>
            </div>
          </div>
          <span class="form-text text-muted">{{ 'AUTH.FIELD.PASSWORD_HINT' | translate }}
          </span>
          
          <div class="password-strength">
            <span class="{{passwordStrength('minLength') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_LENGTH' | translate }}</span>
            <span class="{{passwordStrength('uppercase') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_UPPERCASE' | translate }}</span>
            <span class="{{passwordStrength('number') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_NUMERIC' | translate }}</span>
            <span class="{{passwordStrength('special') ? 'active' : 'default'}}">{{ 'AUTH.VALIDATION.PASSWORD_SPECIAL' | translate }}</span>
        </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-alert">
          {{'AUTH.FIELD.CONFIRM_PASSWORD' | translate}}
          <a href="javascript:void(0)" (click)="togglePassword(2)"><i class="fa fa-{{passwordType[2] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
        </label>
        <div class="col-lg-9 col-xl-8">
          <input type="{{passwordType[2]}}" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': isControlHasError('confirmPassword') }"   
          class="form-control form-control-lg form-control-solid" placeholder="{{'AUTH.FIELD.CONFIRM_NEW_PASSWORD' | translate}}" name="confirmpassword" autocomplete="off" [(ngModel)]="PasswordData.confirmpassword"/>
          <div class="password-strength">
            <span class="{{passwordMatch() ? 'active' :''}}" >{{passwordmatch}}</span>                                          
        </div>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('confirmPassword','required')">
            <div class="fv-help-block">
              <span>{{ 'AUTH.VALIDATION.CONFIRM_PASSWORD_REQUIRED' | translate }}</span>
            </div>
          </div>
          <div class="fv-plugins-message-container" *ngIf="userUpdateForm.get('confirmPassword').errors && userUpdateForm.get('confirmPassword').errors.ConfirmPassword && userUpdateForm.get('confirmPassword').touched">
            <div class="fv-help-block">
              <span>{{ 'AUTH.VALIDATION.PASSWORD_MISSMATCH' | translate }}</span>
            </div>
          </div>
        </div>
       
      </div>      

      <!--begin::Form Group-->
      <div class="separator separator-dashed my-5"></div>
      <!--begin::Form Group-->
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold mb-6">{{'ACCOUNT_SETTINGS.ACCOUNT.ADDITIONAL.TITLE' | translate}}</h5>
        </div>
      </div>
      <!--begin::Form Group-->
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label">{{'ACCOUNT_SETTINGS.ACCOUNT.ADDITIONAL.LABEL' | translate}}</label>
        <div class="col-lg-9 col-xl-6">
          <p class="form-text text-muted py-2">{{'ACCOUNT_SETTINGS.ACCOUNT.ADDITIONAL.HINT' | translate}}</p>
          <button type="button" class="btn btn-light-danger font-weight-bold btn-sm"   (click)="deleteStudyItem(basicData.id)" >{{'ACCOUNT_SETTINGS.ACCOUNT.ADDITIONAL.DEACTIVATE_BTN' | translate}}</button>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </form>

  <!--end::Form-->
</div>
</div>

