<form class="form" [formGroup]="basicForm">
    <!-- <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">Religion</label>
      <div class=" col-lg-4 col-xl-4">
        <div class="input-group input-group-lg input-group-solid">
          <input class="form-control form-control-solid" formControlName="religion" [ngClass]="{ 'is-invalid': isControlHasError('religion') }" placeholder="Enter Religion"/>
        </div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('religion','required')">
          <div class="fv-help-block">
            <span>Religion is required</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION' | translate}}</label>
      <div class=" col-lg-9 col-xl-9">
        <div class="input-group input-group-lg input-group-solid">
          <textarea autosize class="form-control form-control-solid" rows="6" data-expandable maxlength="300" formControlName="description" 
          [ngClass]="{ 'is-invalid': isControlHasError('description') }" placeholder="{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION_INPUT' | translate}}" spellcheck="false"></textarea>
        </div>
       
        <div class="fv-plugins-message-container float-right">
          <div class="fv-help-block">
            <span>({{(basicForm.get('description').value?.length || 0)}}/300)</span>
          </div>
        </div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('description','required')">
          <div class="fv-help-block">
            <span>{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION_REQUIRED' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!--begin: Wizard Actions-->
  <div class="d-flex justify-content-between border-top mt-5 pt-10">
    <div class="mr-2">
      <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
    </div>
    <div>
      <ng-container *ngIf="loading">
        <span class="spinner spinner-primary mr-10"></span>
      </ng-container>
      <button type="button" (click)="onSubmit()" class="btn btn-primary font-weight-bolder text-uppercase">{{'BUTTON.SAVE' | translate}}</button>
    </div>
  </div>
  <!--end: Wizard Actions-->
  