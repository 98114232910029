import { Component, OnInit } from '@angular/core';
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  phoneCode: any;
  candidateId: any;
  user: any;
  profileImage: any;
  candidateName = null;
  candidateEmail = null;
  constructor(
    private service: CommonService,
    public helper: CommonHelper,
    public http: HttpClient) {
  }

  ngOnInit(): void {
    this.getUserData();
    this.candidateName = this.helper.GetUserInfo()?.name + " " + this.helper.GetUserInfo()?.surname;
    this.candidateEmail = this.helper.GetUserInfo()?.email;
  }

  getUserData() {
    this.helper.ShowSpinner();
    this.candidateId = this.helper.GetUserInfo()?.candidateId;
    this.service
      .GetById(this.helper.GetUserInfo().candidateId, "candidate")
      .then(
        (res) => {
          if (res) {
            this.user = res;
            this.getAddress();
            this.candidateName = this.user.firstName + " " + this.user.lastName;
            this.candidateEmail = this.user.emailID;
            this.profileImage = this.user.profileImage ? this.user.profileImage : '';
            this.helper.HideSpinner();
          }
        })
  }

  getAddress() {
    this.helper.ShowSpinner();
    this.http.get("https://ipapi.co/json").subscribe((res) => {
      this.phoneCode = "(" + res['country_code_iso3'] + ")" + res['country_calling_code'];
      this.user.phoneCode = this.user.phoneCode ? this.user.phoneCode : this.phoneCode;

    });
    this.helper.HideSpinner();
  }

}
