<div  class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert" *ngIf="!isAddOrEditShow && !(socialLinks.length > 0)">
<div class="alert-text">{{'SOCIAL.SOCIAL_LINKS.NO_DATA' | translate}}. </div>
</div>

<form class="" [formGroup]="basicForm" *ngIf="basicForm">
  <div *ngIf="socialLinks.length > 0">
    <div class="row">
      <ng-container *ngFor="let link of socialLinks">
        <div class="col-xl-6">
          <div class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2">
            <!--begin::Body-->
            <div class="card-body p-3">
              <span *ngIf="!link.custom" class="btn btn-icon btn-circle btn-primary mr-3">
                <i class="socicon-{{link.type}}"></i>
              </span>
              <span *ngIf="link.custom" class="btn btn-icon btn-circle btn-light-primary mr-3">
                <i class="fa fa-globe"></i>
              </span>
              <span class="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg">
                {{link.custom?link.title:link.type}}
              </span>
              <span class="text-muted d-block font-size-sm">
                {{link.url}}
              </span>
              <div class="action-button">
                <a href="javascript:;"  *ngIf="!isAddOrEditShow" (click)="edit(link)" class="action-primary"><i class="fa fa-pen text-success"></i></a>
                <a href="javascript:;" *ngIf="!isAddOrEditShow" (click)="delete(link)" class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
              </div>
            </div>
            <!--end:: Body-->
          </div>
        </div>
      </ng-container>
    </div>
  </div>
<div *ngIf="isAddOrEditShow">
  <div class="form-group row align-items-center border-top pt-5">
    <div class="col-lg-12">
      <div class="form-group row align-items-center">
        <h3 class="col-lg-12  font-weight-bold">{{'SOCIAL.SOCIAL_LINKS.ADD_NEW_TITLE' | translate}}:</h3>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-5 mt-6" *ngIf="!basicForm.get('custom').value">
              <label>{{'SOCIAL.SOCIAL_LINKS.FIELD.TYPE' | translate}}.</label>
              <select class="form-control" formControlName="type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                  <option value="">{{'SOCIAL.SOCIAL_LINKS.FIELD.TYPE_INPUT' | translate}}</option>
                  <option [value]="item.icon" *ngFor="let item of iconList">{{item.name}}</option>
                </select>
                <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.type.errors" >
                  <div class="fv-help-block" *ngIf="f.type.errors.required">
                    <span>{{'SOCIAL.SOCIAL_LINKS.FIELD.TYPE_REQUIRED' | translate}}</span>
                  </div>
                </div>
            </div>
            <div class="col-lg-5 mt-6" *ngIf="basicForm.get('custom').value">
              <label>{{'SOCIAL.SOCIAL_LINKS.FIELD.TITLE' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'SOCIAL.SOCIAL_LINKS.FIELD.TITLE_INPUT' | translate}}" formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"/>
              <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.title.errors" >
                <div class="fv-help-block" *ngIf="f.title.errors.required">
                  <span>{{'SOCIAL.SOCIAL_LINKS.FIELD.TITLE_REQUIRED' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mt-15">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="primary" formControlName="custom" />
                  <span></span>{{'SOCIAL.SOCIAL_LINKS.FIELD.CUSTOME_LINK' | translate}}</label>
              </div>
            </div>
            <div class="col-lg-8 mt-6">
              <label>{{'SOCIAL.SOCIAL_LINKS.FIELD.URL' | translate}}</label>
              <input type="text"  pattern=".*\S+.*" formControlName="url" maxlength="100" placeholder="{{'SOCIAL.SOCIAL_LINKS.FIELD.URL_INPUT' | translate}}" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.url.errors }"/>
              <div class="fv-plugins-message-container float-right">
                <div class="fv-help-block">
                  <span>({{ 100 - (basicForm.url ? basicForm.url.length : 0) }}/100)</span>
                </div>
              </div>
              <div class="fv-plugins-message-container invalid-feedback" *ngIf="submitted && f.url.errors" >
                <div class="fv-help-block" *ngIf="f.url.errors.required">
                  <span>{{'SOCIAL.SOCIAL_LINKS.FIELD.URL_REQUIRED' | translate}}</span>
                </div>
                <div class="fv-help-block" *ngIf="f.url.errors.pattern">
                  <span>{{'SOCIAL.SOCIAL_LINKS.FIELD.URL_INVALID' | translate}}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-15">
              <button  class="btn btn-sm font-weight-bolder btn-light-success mr-5" (click)="add()">
                <i class="la la-check"></i></button>
              <button  class="btn btn-sm font-weight-bolder btn-light-danger mr-5" (click)="close()">
                <i class="la la-close"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="!isAddOrEditShow"> 
<div class="col-lg-4">
    <button  class="btn btn-sm font-weight-bolder btn-light-primary" (click)="addSocialLink()">
        <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
</div>
</div>
<div class="d-flex justify-content-between border-top mt-5 pt-10">
    <div class="mr-2">
      <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
    </div>
    <div>
      <ng-container *ngIf="loading">
        <span class="spinner spinner-primary mr-10"></span>
      </ng-container>
      <button (click)="onSubmit()"
        type="button"
        class="btn btn-primary font-weight-bolder text-uppercase"
      >
       {{'BUTTON.SAVE_EXIT' | translate}}    </button>
    </div>
  </div>
</form>
