export class GeneralDetails {
  nationality: string[];
  idCard: string;
  idCardType: string;
  nitNumber: string;
  bloodGroup: string;
  religion: string;
  visaPermit: string[];
  ssNumber: string;
  ssnType: string;
  vehicles: any[];
  haveVehicle: boolean;
  maritalStatus: string;
  dependentsNumber: number;

  clear(): void {
    this.nationality = [];
    this.idCard = "";
    this.idCardType = "";
    this.nitNumber = "";
    this.bloodGroup = "";
    this.religion = "";
    this.visaPermit = [];
    this.ssNumber = "";
    this.ssnType = "";
    this.vehicles = [];
    this.haveVehicle = true;
    this.maritalStatus = "";
    this.dependentsNumber = undefined;
  }
}
