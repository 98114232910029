export class Family {
    id: number;
    type: string;
    name: string;
    lastName: string;
    relation: string;
    email: string;
    phoneCode: string;
    phone: string;
    age: number;
    alive: boolean;
    dependent: boolean;
    working: boolean;
    profession: string;
    company: string;
    _isEditMode: boolean;

    clear(): void {
        this.id = undefined;
        this.type = '';
        this.name = '';
        this.lastName = '';
        this.relation = '';
        this.email = '';
        this.phoneCode = '';
        this.phone = '';
        this.age = 0;
        this.alive = false;
        this.dependent = false;
        this.working = false;
        this.profession = '';
        this.company = '';
        this._isEditMode = false;
    }
}
