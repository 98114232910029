<div class="mt-5" id="list-skill">
    <form class="" [formGroup]="basicForm">
      <div class="form-group row" *ngIf="itemResultList.length > 0">
        <div class="col-lg-12">
          <div class="row" *ngIf="itemResultList.length > 0">
            <ng-container *ngFor="let item of itemResultList; let i = index">
              <div class="col-xl-6" *ngIf="!item._isEditMode">
                <div class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2">
                  <!--begin::Body-->
                  <div class="card-body p-3">
                    <a href="javascript:coid(0)" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item.name | titlecase}}</a>
                    <span class="text-muted font-weight-bold d-block">
                      {{item.type | titlecase}}
                    </span>
                    <span class="text-muted font-weight-bold d-block">{{'RESUME.SKILLS.FIELD.LEVEL' | translate}}:
                      <img src="assets/media/stars/{{item.level}}.png" alt="image" style="width: 5.5rem">
                    </span>
                    <div class="action-button">
                      <a href="javascript:;" (click)="editItemButtonOnClick(item, i)" *ngIf="!isSwitchedToEditMode" class="action-primary"><i class="fa fa-pen text-success"></i></a>
                      <a href="javascript:;" (click)="deleteItem(i)" *ngIf="!isSwitchedToEditMode" class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                    </div>
                  </div>
                  <!--end:: Body-->
                </div>
              </div>
            </ng-container>
          </div>
          <div id="edit-skill" *ngFor="let item of itemResultList; let i = index">
            <div class="form-group row align-items-center border-top pt-5" *ngIf="item._isEditMode">
              <div class="col-lg-12">
                <div class="form-group row align-items-center">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-4 mt-6">
                        <label>{{'RESUME.SKILLS.FIELD.TYPE' | translate}}:</label>
                        <select formControlName="type" class="form-control" (change)='filterbyType($event)'>
                          <option value="">{{'RESUME.SKILLS.FIELD.TYPE_INPUT' | translate}}</option>
                          <option value="Techniques">Techniques</option>
                          <option value="Competences">Competences</option>
                          <option value="Artistic, Sports and Social Activities">Artistic, Sports and Social Activities</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('type','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.SKILLS.FIELD.TYPE_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 mt-6">
                        <label>{{'RESUME.SKILLS.FIELD.NAME' | translate}}:</label>
                        <p-dropdown [options]="Filterlist"  placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" optionLabel="name" optionValue="name" formControlName="name" [filter]="true"
                        filterBy="name"></p-dropdown>
                        <!--<input type="text" class="form-control" placeholder="{{'RESUME.SKILLS.FIELD.NAME_INPUT' | translate}}" formControlName="name" list="skills" maxlength="40"/>
                        <datalist id="skills">
                          <option *ngFor="let item of Filterlist" [value]="item">
                        </datalist>-->
                        <div class="fv-plugins-message-container float-right">
                          <div class="fv-help-block">
                            <span>({{(basicForm.get('name').value?.length || 0)}}/40)</span>
                          </div>
                        </div>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('name','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 mt-6">
                        <label>{{'RESUME.SKILLS.FIELD.LEVEL' | translate}}:</label>
                        <select formControlName="level" class="form-control">
                          <option value="">{{'RESUME.SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                          <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | titlecase}}</option>
                        </select>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('level','required')">
                          <div class="fv-help-block">
                            <span>{{'RESUME.SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 mt-15">
                        <button (click)="saveUpdatedItem(item)" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                          <i class="la la-check"></i></button>
                        <button (click)="cancelEditButtonOnClick(item)" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                          <i class="la la-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="add-skill">
        <div class="form-group row border-top pt-5" *ngIf="itemForAdd._isEditMode">
          <div class="col-lg-12">
            <div class="form-group row align-items-center">
              <h3 class="col-lg-12  font-weight-bold">{{'RESUME.SKILLS.ADD_NEW_TITLE' | translate}}:</h3>
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4 mt-6">
                    <label>{{'RESUME.SKILLS.FIELD.TYPE' | translate}}:</label>
                    <select formControlName="addType" class="form-control" (change)='filterbyType($event)'>
                      <option value="">{{'RESUME.SKILLS.FIELD.TYPE_INPUT' | translate}}</option>
                      <option value="Techniques">{{'RESUME.SKILLS.OPTIONS.TECHNICAL_SKILLS' | translate}}</option>
                      <option value="Competences">{{'RESUME.SKILLS.OPTIONS.COMPETENCIES' | translate}}</option>
                      <option value="Artistic, Sports and Social Activities">{{'RESUME.SKILLS.OPTIONS.ARTS_SPORTS_SOCIAL_ACTIVITIES' | translate}}</option>
                    </select>
                    <div class="fv-plugins-message-container" *ngIf="isControlHasError('addType','required')">
                      <div class="fv-help-block">
                        <span>{{'RESUME.SKILLS.FIELD.TYPE_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 mt-6">
                    <label>{{'RESUME.SKILLS.FIELD.NAME' | translate}}:</label>
                    <p-dropdown [options]="Filterlist"  placeholder="{{'RESUME.COMPUTER_SKILLS.FIELD.NAME_INPUT' | translate}}" [(ngModel)]="addName" optionLabel="name" optionValue="name" formControlName="addName" [filter]="true"
                    filterBy="name" editable="true"></p-dropdown>
                    <!--<input type="text" class="form-control" placeholder="{{'RESUME.SKILLS.FIELD.NAME_INPUT' | translate}}" formControlName="addName" list="skills" maxlength="40"/>
                    <datalist id="skills">
                      <option *ngFor="let item of Filterlist" [value]="item">
                    </datalist>-->
                    <div class="fv-plugins-message-container float-right">
                      <div class="fv-help-block">
                        <span>({{(basicForm.get('addName').value?.length || 0)}}/40)</span>
                      </div>
                    </div>
                    <div class="fv-plugins-message-container" *ngIf="isControlHasError('addName','required')">
                      <div class="fv-help-block">
                        <span>{{'RESUME.SKILLS.FIELD.NAME_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4 mt-6">
                    <label>{{'RESUME.SKILLS.FIELD.LEVEL' | translate}}:</label>
                    <select formControlName="addLevel" class="form-control">
                      <option value="">{{'RESUME.SKILLS.FIELD.LEVEL_INPUT' | translate}}</option>
                      <option [value]="item.levelValue" *ngFor="let item of levelList">{{item.level | titlecase}}</option>
                    </select>
                    <div class="fv-plugins-message-container" *ngIf="isControlHasError('addLevel','required')">
                      <div class="fv-help-block">
                        <span>{{'RESUME.SKILLS.FIELD.LEVEL_REQUIRED' | translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 mt-15">
                    <a href="javascript:;" (click)="saveNewItem()" class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                      <i class="la la-check"></i></a>
                    <a href="javascript:;" (click)="cancelAddButtonOnClick()" class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                      <i class="la la-close"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="itemResultList.length === 0 && !isSwitchedToEditMode" class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert">
      <div class="alert-text">{{'RESUME.SKILLS.NO_DATA' | translate}}.</div>
    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <button *ngIf="!itemForAdd._isEditMode && !isSwitchedToEditMode" (click)="addItemButtonOnClick()" class="btn btn-sm font-weight-bolder btn-light-primary">
          <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
      </div>
    </div>
  </div>
  <!--begin: Wizard Actions-->
  <div class="d-flex justify-content-between border-top mt-5 pt-10">
    <div>
      <button type="button" (click)="onSubmit()" class="btn btn-primary font-weight-bolder text-uppercase">{{'BUTTON.SAVE_CONTINUE' | translate}}</button>
    </div>
  </div>
  <!--end: Wizard Actions-->
  