import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/Service/Common.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  verification : any = {};
  constructor( 
    private service: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const payload = [{params: params['code']}, {params: params['token']}];
      try{
        this.service.GetWithParameter("candidate/api/verification", payload).then((data) =>{
          this.router.navigate(['/Login', {title: "verified"}])
        })
       }   
       catch(error)
       {
        console.error('Here is the error message', error);
         //this.helper.ErrorToastr(error);                      
        //   this.helper.ErrorToastr("Cannot update, please try again");
       }
  });
    

  }

}
