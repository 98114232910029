import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-pconfirmation-entity-component',
  templateUrl: './pconfirmation-entity-component.component.html',
  styleUrls: ['./pconfirmation-entity-component.component.css']
})
export class PConfirmationEntityComponentComponent implements OnInit {
// Public properties
viewLoading: boolean = false;

modalData: any;

timmerIn: any;

data: any;

  constructor(public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
	  this.data = this.config.data;
  }
  onCancel(): void {
		this.dialogRef.close();
	}

	onConfirm(): void {
		this.viewLoading = true;
		setTimeout(() => {
			this.dialogRef.close(true); // Keep only this row
		}, 500);
	}

	getIcon(type) {
		switch (type) {
			case "delete":
				return 'fa-trash-alt';
				break;
			case "logout":
				return 'fa-times';
				break;
			case "danger":
				return 'fa-times';
				break;
			case "incomplete":
				return 'fa-exclamation-triangle ';
				break;
			case "info":
				return 'fa-info';
				break;
			case "warning":
				return 'fa-exclamation';
				break;
			case "success":
				return 'fa-check';
				break;
			default:
				return 'fa-trash-alt';
				break;
		}
	}


}
