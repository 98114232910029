// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

      //API_URL: "http://194.195.119.137:8080/LISTO",
     // google_Client_Id: '572329727816-pv1odkmgta8l68m55r6lj78l1pdtomhi.apps.googleusercontent.com',
      google_Client_Id: '161435338582-cks12v65q48kkdug5uuvdasp3lku6mct.apps.googleusercontent.com',
      fb_Client_Id: '89623805841260',
      
      // testing server
      API_URL:"https://listopat.com/listo"


      // production server
    //API_URL:"https://listoparatrabajar.com/listo"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
