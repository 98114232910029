import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { CommonService } from 'src/Service/Common.service';
import { LayoutserviceService, MessageType } from 'src/app/core/utils/layoutservice.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmPasswordValidator } from 'src/app/authenticate-browser/confirm-password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  //changePasswordForm: FormGroup;
  changePasswordForm: FormGroup;
  //changePasswordForm: FormGroup[] = new Array<FormGroup>();
  PasswordData: any ={};
  candidateId = null;
  isLoading: boolean = false;
  user: Array<String>;

  passwordmatch :string;
  passwordType: string[] = ['password', 'password', 'password'];
  isValid : boolean;

  changePasswordEnabled: boolean = false;

  constructor(
    private formbuilder: FormBuilder,
    private service: CommonService,
    public helper: CommonHelper,
    private layoutUtilsService: LayoutserviceService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isValid=true;
    this.initForm();
  }

  initForm() {
    this.candidateId = this.helper.GetUserInfo()?.candidateId;
    this.changePasswordForm = this.formbuilder.group({
      candidateId: [this.candidateId, Validators.compose([
        Validators.required,
      ])
      ],
      currentPassword: ['', Validators.compose([
        Validators.required
      ])
      ],
      password: ['', Validators.compose([
        Validators.required
      ])
      ],
      confirmPassword: ['',
        Validators.required
      ]
    },{
			validator: ConfirmPasswordValidator.MatchPassword
		});
  }

  get f() { return this.changePasswordForm.controls; }


  passwordMatch():boolean{
    let isValidflag: boolean = false;
    let save_data = Object.assign({}, this.PasswordData);
    let password=save_data["password"];
    let confirmPassword =save_data["confirmpassword"];
    this.passwordmatch='';
    if(password==undefined) {   
        return;  
    }
    
    if(confirmPassword==undefined) { 
        return;  
    }

    if(confirmPassword==null) { 
        return false;  
    }


    if(password===confirmPassword){
        isValidflag= true;
        this.passwordmatch='Password and confirm Password are matched'
    }
    this.isValid=isValidflag;
    return isValidflag;   

}

  onSubmit(){  
    const controls = this.changePasswordForm.controls;
		if (this.changePasswordForm.invalid || !this.changePasswordEnabled) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			if (!this.changePasswordEnabled) {
				this.layoutUtilsService.showActionNotification(MessageType.warning, this.translate.instant('ACCOUNT_SETTINGS.CHANGE_PASSWORD.INVALID'), "You don't have provision to change the password.");
			}
			return;
		}
    this.isLoading = true;
		this.cdr.detectChanges();
		let self = this;
    this.user = this.changePasswordForm.value;
    this.service
      .CommonPut(
        this.user,
        "profile/api/updatePw/"
      )
      .then((data) => {
        if(data){
        this.layoutUtilsService.showActionNotification(
          MessageType.success,
          this.translate.instant('RESUME.UPDATED_SUCCESSFULLY')
        ); 
        } else{
          this.layoutUtilsService.showActionNotification(
          MessageType.warning,
          "Something went wrong , Please try again later"
        ); 
        }
  
      });
  }

  togglePassword(index) {
    if (this.passwordType[index] === 'password') {
      this.passwordType[index] = 'text';
    } else {
      this.passwordType[index] = 'password';
    }
  }


  passwordStrength(type: string): boolean {
    const controls = this.changePasswordForm.controls;
    let isValid: boolean = false;
    let save_data = this.changePasswordForm.value;
    let password=save_data["password"];
   
    if(password==undefined) {   
        return;  
    }  
    if (type === 'minLength') {
      isValid = /^[a-zA-Z, 0-9, #?!@$%^&*_+=-]{8,}/.test(password);
    } else if (type === 'special') {
      isValid = /[#?!@$%^&*_+=-]/.test(password);
    } else if (type === 'uppercase') {
      isValid = /[A-Z]/.test(password);
    } else if (type === 'number') {
      isValid = /\d/.test(password);
    }
    if (isValid) {
      
      return true;
    }
    if (controls.password.value) {
			controls.password.setErrors({pattern: true});
		}else {
      
			controls.password.setErrors({pattern: true, required: true});
		}
    return false;
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
		const control = this.changePasswordForm.controls[controlName];
		if (!control) {
			return false;
		}
		if (validationType) {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
		if (control.invalid &&control.errors && (control.dirty || control.touched)) {
			return true;
		}
		return false;
	}

}

