import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QueryParamsModel } from 'src/app/core/model/query-models/query-params.model';
import { Extra } from 'src/app/core/socialprofile/extra.model';
import { Social } from 'src/app/core/socialprofile/social.model';
import { HttpUtilsService } from 'src/app/core/utils/http-utils.service';
//Moment
import * as moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/Service/Common.service';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { LayoutserviceService } from 'src/app/core/utils/layoutservice.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.css']
})
export class HobbiesComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;
  isEditing: boolean;
  isSwitchedToEditMode = false;
  itemForEdit: Extra;
  itemForAdd: Extra;

  language: string = 'en';

  itemResultList: Extra[] = [];

  @Input() stepCount$: BehaviorSubject<number>;

  @Input() socialDetails$: BehaviorSubject<Social>;
  @Input() isPopup: boolean;
  socialDetails: Social = new Social();

  hobbiesList: Extra[] = [];

  constructor(public fb: FormBuilder,
    private httpUtils: HttpUtilsService,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: CommonService, public helper: CommonHelper,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.socialDetails.clear();
    if (this.config.data && this.config.data.hobbies && this.config.data.hobbies.length > 0) {
      this.config.data.hobbies.map((data, index) => {
        this.hobbiesList.push({ ...data })
      });
      this.loaditemList();
    }

    this.createFormGroup();
  }


  loaditemList(id: string = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.hobbiesList.forEach(item => {
      let newItem = new Extra();
      newItem.clear();
      newItem.id = item.id;
      newItem.title = item.title;
      newItem.description = item.description;
      newItem._isEditMode = false;
      if (item.id === id) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.hobbiesList = this.itemResultList;
  }

  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addTitle: ['', Validators.compose([Validators.required])],
      addDescription: ['', Validators.compose([])]
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addTitle.setValue('');
    controls.addTitle.markAsPristine();
    controls.addTitle.markAsUntouched();
    controls.addDescription.setValue('');
    controls.addDescription.markAsPristine();
    controls.addDescription.markAsUntouched();

    this.itemForAdd = new Extra();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }

  checkAddForm() {
    const controls = this.basicForm.controls;
    if (controls.addTitle.invalid || controls.addDescription.invalid) {
      controls.addTitle.markAsTouched();
      controls.addDescription.markAsTouched();
      return false;
    }
    return true;
  }



  addItemButtonOnClick() {
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-recognition');
  }

  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    this.scrollTo('list-recognition');
  }

  saveNewItem() {
    const controls = this.basicForm.controls;

    if (!this.checkAddForm()) {
      return;
    }
    this.itemForAdd.id = moment().valueOf().toString();

    this.itemForAdd.title = controls.addTitle.value;
    this.itemForAdd.description = controls.addDescription.value;
    this.itemForAdd._isEditMode = false;

    this.hobbiesList.push(this.itemForAdd);
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.addTitle.setValue('');
    controls.addTitle.markAsPristine();
    controls.addTitle.markAsUntouched();
    controls.addDescription.setValue('');
    controls.addDescription.markAsPristine();
    controls.addDescription.markAsUntouched();

    this.itemForAdd = new Extra();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
    this.isEditing = false;
  }

  checkEditForm() {
    const controls = this.basicForm.controls;
    if (controls.addTitle.invalid || controls.addDescription.invalid) {
      controls.addTitle.markAsTouched();
      controls.addDescription.markAsTouched();
      return false;
    }
    return true;
  }

  editItemButtonOnClick(_item: any) {
    // _item = this.hobbiesList.find(data => data._isEditMode);
    this.itemForAdd = _item;
    this.itemForAdd._isEditMode = true;
    this.loaditemList(_item.id, true);
    const controls = this.basicForm.controls;
    this.isEditing = true;
    controls.addTitle.setValue(_item.title);
    controls.addDescription.setValue(_item.description);
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-recognition');
  }


  cancelEditButtonOnClick(_item: any) {
    // _item = this.hobbiesList.find(data => data._isEditMode)
    this.loaditemList(_item.id, false);
    this.isSwitchedToEditMode = false;
    this.isEditing = false;
    this.scrollTo('list-recognition');
    this.clearEditForm();
  }

  saveUpdatedItem(_item: Extra) {
    if (!this.checkEditForm()) {
      return;
    }

    if (!_item) {
      _item = this.itemResultList.find((data) => (data._isEditMode === true));
    }

    const controls = this.basicForm.controls;

    this.hobbiesList.forEach(item => {
      if (item.id === _item.id) {
        item.title = controls.addTitle.value;
        item.description = controls.addDescription.value;
      }
    });
    this.isSwitchedToEditMode = false;
    this.isEditing = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  deleteItem(_item: Extra) {
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _description = this.translate.instant('SOCIAL.HOBBIES.MESSAGES.DELETE');
    let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (res) {

        let newItem: Extra[] = [];
        this.itemResultList.forEach(item => {
          if (item.id !== _item.id) {
            newItem.push(item);
          }

          this.isUpdated = true;
          this.hobbiesList = newItem;
          this.loaditemList();

        })
      }
    })
  }

  onSubmit(): void {

    if ((this.itemResultList.length === 0)) {
      //  this.layoutUtilsService.showActionNotification(MessageType.warning, this.translate.instant('SOCIAL.FAMILY.HOBBIES.EMPTY'));
      return;
    }

    if (!this.isUpdated) {
      this.stepCount$.next(6);
      return;
    }

    this.loading = true;
    let hobbyList = [];
    this.hobbiesList.map(data => {
      delete data._isEditMode;
      hobbyList.push({ ...data })
    })
    this.service
      .CommonPostObservable(hobbyList, "socialprofile/api/hobbies/" + this.config.data.general.socialProfileId)
      .subscribe((data) => {
        this.loading = false;
        console.log(data);
        this.ref.close(true);
      }, err => {
        this.helper.ErrorToastr("Cannot Update! Please retry");
      });

  }

  prevStep() {
    this.stepCount$.next(3);
  }

  scrollTo(id): void {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  baseFilter(array, sort, dir, filter: any = {}) {
    const queryParams = new QueryParamsModel(filter, dir, sort, 0, 1000);
    const result = this.httpUtils.baseFilter(array, queryParams, []);
    return result.items;
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

}


