<ng-container *ngIf="data">
	<div class="modal-body p-4 pt-0 pb-4 text-center">
	  <div class="icon-box-c icon-{{data.type}}">
	    <i class="fa {{getIcon(data.type)}}"></i>
	  </div>
	  <h5 class="modal-title font-size-16 mt-2 mb-1 text-dark text-center">{{data.title}}</h5>
	  <p class="font-size-4 text-dark text-center">{{data.description}}</p>
	  <div class="row pb-5">
	    <div class="col-12">
	      <div class="col-md-12 text-center">
	        <button type="button" class="btn btn-secondary w-sm mr-3" (click)="onCancel()">
			{{data.cancelButton}}
	        </button>
	        <button type="button" class="btn btn-primary w-sm" (click)="onConfirm()" [disabled]="viewLoading">
	          {{data.confirmButton}}
	          <i *ngIf="viewLoading" class="bx bx-loader bx-spin"></i>
	        </button>
	      </div>
	    </div>
	  </div>
	</div>
</ng-container>
