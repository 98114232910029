import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { LayoutserviceService } from 'src/app/core/utils/layoutservice.service';
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";

@Component({
  selector: 'app-commitments',
  templateUrl: './commitments.component.html',
  styleUrls: ['./commitments.component.css']
})
export class CommitmentsComponent implements OnInit {

  constructor(    public fb: FormBuilder,
    public service: CommonService,
    public helper: CommonHelper,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService) { }
  loading:boolean;
  submitted:boolean;
  isAddOrEditShow:boolean;
  basicForm:FormGroup;
  commitmentLists:any[]=[];
  editId:any;
  
  ngOnInit(): void {
    if(this.config.data && this.config.data.commitments){
      this.commitmentLists = this.config.data.commitments
    }
    this.createCommitments();
  }

  addSocialLink(){
    this.isAddOrEditShow = true;

  };
  get f() { return this.basicForm.controls; }
  createCommitments(){
    this.basicForm = this.fb.group({
      type: ['', Validators.required],
      whom: ['', Validators.required],
      currency: ['', Validators.required],
      amount: ['', Validators.required],
      emi: ['', Validators.required],
      description: ['', Validators.required]
  });
}
editSocialLink(data){
  this.editId=data.id;
  this.basicForm = this.fb.group({
    type: [data.type, Validators.required],
    whom: [data.whom, Validators.required],
    currency: [data.currency, Validators.required],
    amount: [data.amount, Validators.required],
    emi: [data.emi, Validators.required],
    description: [data.description, Validators.required]
});
}
add() {

  this.submitted = true;
  const controls = this.basicForm.controls; 
  if(this.basicForm.invalid && this.submitted){
return false
  }
    this.addAndResetForm();
    
  
}

addAndResetForm() {
  const controls = this.basicForm.controls;
  if(!this.editId){
    this.commitmentLists.push({...this.basicForm.value, id:moment().valueOf()});

  }
  else{
    this.commitmentLists = this.commitmentLists.map(data=>{
      if(data.id===this.editId){
        return {...data, ...this.basicForm.value}
      }
      return data;
    })

  }
  this.createCommitments();
  this.submitted=false;
  this.editId=null;
  this.close();

}
edit(data){
  this.isAddOrEditShow=true;

  this.editSocialLink(data);
}

delete(deleteData){
  let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _description = this.translate.instant('SOCIAL.COMMITMENTS.MESSAGES.DELETE');
  let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _cancelButton = this.translate.instant('BUTTON.CANCEL');
	
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (res) {

  this.commitmentLists.map((data, index)=>{
    if(data.id=== deleteData.id){
      this.commitmentLists.splice(index, 1);
    }
  })
}
    })
}
close(){
  this.isAddOrEditShow = false;
  this.editId=null;
};
onSubmit() {
  this.loading = true;
  this.service
    .CommonPostObservable(
      this.commitmentLists,
      "/socialprofile/api/commitment/" +
        this.config.data.general.socialProfileId
    )
    .subscribe((data) => {
this.ref.close(true);
this.loading = false;
    },err=>{
      this.loading = true;
      this.helper.ErrorToastr('Cannot update try again');
    });
}
}
