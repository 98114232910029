<div class="modal-body">
  <div class="row">
    <div class="col-xxl-12 col-xl-12 col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <button class="btn btn-light-primary btn-sm mr-5 my-1" style="position: relative;">
            {{ 'PHOTO_EDITOR.BUTTONS.CHANGE_IMAGES' | translate}}
            <input type="file" accept="image/*" (change)="fileChangeEvent($event)" />
          </button>
          <button (click)="rotateLeft()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
            <i class="fa fa-undo"></i>
          </button>
          <button (click)="rotateRight()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
            <i class="fa fa-redo" aria-hidden="true"></i>
          </button>
          <!--<button (click)="flipHorizontal()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
              <i class="fa fa-arrows-alt-h"></i>
            </button>
            <button (click)="flipVertical()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
              <i class="fa fa-arrows-alt-v"></i>
            </button>-->
          <button (click)="zoomIn()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
            <i class="fa fa-search-plus"></i>
          </button>
          <button (click)="zoomOut()" class="btn btn-icon btn-light-primary btn-sm mr-5 my-1">
            <i class="fa fa-search-minus"></i>
          </button>
          <!-- <button *ngIf="!data.maintainRatio" (click)="maintainAspectRatio()" class="btn btn-light-primary btn-sm mr-5 my-1">
            </button> -->
          <button (click)="resetImage()" class="btn btn-light-primary btn-sm mr-5 my-1">
            {{ 'PHOTO_EDITOR.BUTTONS.RESET' | translate}}
          </button>
        </div>
      </div>
      <div class="row pt-5" *ngIf="!imageChange">
        <div class="col-lg-12">
          <div class="upload-box">
            <div class="overlay">
              <div class="content">
                <i class="fa fa-upload"></i>
                <p>{{'DOCUMENTS.EDIT_VIEW.UPLOAD_HINT' | translate}}</p>
                <p>{{'DOCUMENTS.EDIT_VIEW.UPLOAD_HINT2' | translate}}</p>
              <p>{{'DOCUMENTS.EDIT_VIEW.UPLOAD_HINT1' | translate}}</p>
              </div>
            </div>
            <input type="file" accept="image/*" (change)="fileChangeEvent($event)" />
          </div>
        </div>
      </div>
      <div class="row pt-5 justify-content-center" *ngIf="imageChange">
        <div class="col-6">
          <h6>Editor</h6>
          <div class="crop-box">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainRatio"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio"
              [canvasRotation]="canvasRotation" [transform]="transform" [onlyScaleDown]="true" format="png"
              autoCrop="true" (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()"
              [resizeToWidth]="100" [resizeToHeight]="100" [imageQuality]="100" [imageSmoothingEnabled]="true"
              [imageSmoothingQuality]="high"></image-cropper>
          </div>
        </div>
        <!--<div class="col-lg-6">
            <h6>Preview</h6> 
            <div class="preview-box">
              <img style="width: 100%;" [src]="croppedImage" />
            </div>
          </div>-->
      </div>
      <!--begin: Wizard Actions-->
      <div class="d-flex justify-content-between border-top mt-5 pt-10">
        <div class="mr-2">
        </div>
        <div>
          <ng-container *ngIf="loading">
            <span class="spinner spinner-primary mr-10"></span>
          </ng-container>
          <button type="button" (click)="onClose()"
            class="btn btn-light-primary font-weight-bolder text-uppercase mr-5">{{ 'PHOTO_EDITOR.BUTTONS.CANCEL' |
            translate}}</button>
          <button type="button" (click)="onSubmit()" class="btn btn-primary font-weight-bolder text-uppercase">{{
            'PHOTO_EDITOR.BUTTONS.SANE_AND_EXIT' | translate}}</button>
        </div>
      </div>
      <!--end: Wizard Actions-->
    </div>
  </div>
</div>