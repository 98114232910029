import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor( public translate: TranslateService) {
    translate.addLangs(['en', 'es']);  
    if (localStorage.getItem('locale')) {  
      const browserLang = localStorage.getItem('locale');  
      translate.use(browserLang.match(/en|es/) ? browserLang : 'en');  
    } else {  
      localStorage.setItem('locale', 'en');  
      translate.setDefaultLang('en');  
    }
    
   }

  private userPhoto: Subject<any> = new Subject<any>();
  private wizardSubmissionTriggered: Subject<any> = new Subject<any>();

  setwizardSubmissionTriggered(isTriggeredFrom: any){
    this.wizardSubmissionTriggered.next(isTriggeredFrom);
  }

  getwizardSubmissionTriggered() : Observable<any>{
    return this.wizardSubmissionTriggered.asObservable();
  }

  setUserProfilePhoto(image: any){
    this.userPhoto.next(image);
  }

  getUserProfilePhoto() : Observable<any>{
    return this.userPhoto.asObservable();
  }

  changeLang(event, language){
    localStorage.setItem('locale', language);  
    this.translate.use(language);
  }
}
