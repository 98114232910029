import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  @ViewChild('fc', { static: false }) fc: any;
  items: MenuItem[];
  daytoggle: boolean = true;
  weektoggle: boolean = false;
  monthToggle: boolean = false;
  calendars: any = [{ name: 'Personal', value: '1' }, { name: 'Official', value: '2' }, { name: 'Team', value: '3' }];
  calendar: any;
  weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  week: any = [];
  events: any;
  showCalender: boolean;

  options: any;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [
      { title: 'Pavithran Birthday', date: '2021-09-03' },
      { title: 'Going on vaccation', date: '2021-09-17' },
      { title: 'Going on vaccation', date: '2021-10-11' }
    ],
    eventClick: function (info) {
      alert(info.event.title);
    }
  };

  handleDateClick(arg) {
    alert('date click! ' + arg.dateStr)
  }

  constructor() { }

  ngOnInit(): void {
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('week');
    for (var i = 0; i <= 6; i++) {
      const formattedDate = (moment(weekStart).add(i, 'days').format("DD-MMM-YYYY"));
      this.week.push(formattedDate)
    }
    this.options = {
      plugins: [dayGridPlugin, interactionPlugin],
      defaultDate: new Date(),
      defaultView: 'dayGridMonth',
      titleFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
      columnFormat: {
        month: 'ddd',
        week: 'ddd d/M',
        day: 'dddd d/M'
      },
      header: {
        left: 'prev',
        center: 'title',
        right: 'next' // 'dayGridMonth,timeGridWeek,timeGridDay'
      }, eventClick: (e) => {
        //this.openPatientProfile(e.event.extendedProps.appointment)
      }
    }

    setTimeout(() => {
      this.showCalender = true;
    }, 2000);
  }

  calendertoggle() {
    this.daytoggle = true;
    this.weektoggle = false;
    this.monthToggle = false;
    this.getData();
  }

  weekToggle() {
    this.monthToggle = false;
    this.daytoggle = false;
    this.weektoggle = true;
    this.options.defaultView = 'timeGridWeek';
    this.getData();
  }
  monthtoggle() {
    this.monthToggle = true;
    this.weektoggle = false;
    this.daytoggle = false;
    this.options.defaultView = 'dayGridMonth';
    this.getData();
  }

  personalCalendar() {
    this.calendarOptions.events = [
      { title: 'Pavithran Birthday', date: '2021-09-03' },
      { title: 'Going on vaccation', date: '2021-09-17' },
      { title: 'Going on vaccation', date: '2021-10-11' }
    ]
  }

  officialCalendar() {
    this.calendarOptions.events = [
      { title: 'Interview with vignesh', date: '2021-09-14' },
      { title: 'Recruitment starts for Sisesoft', date: '2021-09-17' },
      { title: 'Recruitment starts for Sisesoft', date: '2021-09-12' }
    ]
  }

  teamCalendar() {
    this.calendarOptions.events = [
      { title: 'Pavithran posted a new job', date: '2021-09-21' },
      { title: 'Girish selected the candidate', date: '2021-09-20' },
      { title: 'Girish selected the candidate', date: '2021-09-04' }
    ]
  }

  getData() {

  }

  getCalendar() {

  }

}
