<div class="row mt-2 pb-3">
    <div class="col-md-8">
        <div class="btn-dash-z togglebutton btn-group btn-group-toggle ml-2 mrgn-rght" data-toggle="buttons">
            <label class="btn btn-secondarydt active">
                <input type="radio" name="options" id="option1" (click)="personalCalendar()" autocomplete="off"
                    [checked]="!weektoggle">Personal
            </label>
            <label class="btn btn-secondarydt ">
                <input type="radio" name="options" (click)="officialCalendar()" id="option2" autocomplete="off"
                    [checked]="weektoggle">Official
            </label>
            <label class="btn btn-secondarydt ">
                <input type="radio" name="options" (click)="teamCalendar()" id="option2" autocomplete="off"
                    [checked]="monthToggle">Team
            </label>
        </div>
    </div>
    <div class="col-md-4">
        <div class="btn-dash-z btn-group btn-group-toggle mrgn-rght right" *ngIf="weektoggle">
            <label class="btn btn-secondarydt style-1">
                <span>Monday</span>{{week[0] | date : 'dd/MM/yyyy'}}
            </label> to
            <label class="btn btn-secondarydt style-1">
                <span>Sunday</span>{{week[6] | date : 'dd/MM/yyyy'}}
            </label>
        </div>
        <div class="btn-dash-z btn-group btn-group-toggle mrgn-rght right" *ngIf="!weektoggle">
            <label class="btn btn-primary">
                Add
            </label>
        </div>
    </div>
</div>
<div class="row">
    <full-calendar class="mt-22" [options]="calendarOptions"></full-calendar>
</div>