import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { CommonService } from 'src/Service/Common.service';
import { SharedService } from '../services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { HttpClient } from '@angular/common/http';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentInit {
  language: string;
  Menu: any = [];
  items: MenuItem[];
  CompanyData: any = [];
  CurrentCompanyData: any = {};
  IsMenuOpened: boolean;
  userProfileImage: any;
  userData: any;
  countryList: any[] = [];
  countryCode: string = '';
  defaultCountry: any;
  currentCountry: any;
  ddlLanguage: boolean = false;
  //27-dec-2021
  completionPercentage: any = 0;

  constructor(
    public helper: CommonHelper,
    private sharedService: SharedService,
    private service: CommonService,
    public authService: SocialAuthService,
    private router: Router,
    public translate: TranslateService,
    private _adapter: DateAdapter<any>,
    private http: HttpClient
  ) {

    translate.addLangs(['en', 'es']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
      this._adapter.setLocale("es-SP");
      this.language = browserLang;
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
      this._adapter.setLocale("en-US");
    }

    this.translate.onLangChange.subscribe((res) => {
      this.language = localStorage.getItem("locale");
      if (this.language == "es") {
        this._adapter.setLocale("es-GB");
      } else {
        this._adapter.setLocale("en-GB");
      }
      this.buildJobMenus();
    })
    this.buildJobMenus();
  }

  buildJobMenus() {
    this.items = [
      {
        label: this.language == "en" ? 'Jobs' : 'Trabajos',
        items: [
          // { label: this.language == "en" ? 'Search Jobs' : 'Buscar trabajo', "routerLink": "/searchJobs", visible: true },
          // { label: this.language == "en" ? 'Favourite Jobs' : 'trabajos favoritos', "routerLink": "/favouriteJobs", visible: true },
          // { label: this.language == "en" ? 'Applied Jobs' : 'Empleos aplicados', "routerLink": "/appliedJobs", visible: true }
          { label: this.language == "en" ? 'Search Jobs' : 'Buscar trabajo', visible: true, command: () => { this.goToHomePage(); } },
          { label: this.language == "en" ? 'Favourite Jobs' : 'trabajos favoritos', visible: true, command: () => { this.goToHomePage(); } },
          { label: this.language == "en" ? 'Applied Jobs' : 'Empleos aplicados', visible: true, command: () => { this.goToHomePage(); } }
        ]
      },
    ];
  }

  private goToHomePage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/home`])
    );
    window.open(url, '_blank');
  }


  ngAfterViewInit() {


  }

  ngAfterContentInit() {

  }

  ngAfterViewChecked() {
  }

  async ngOnInit() {
    await this.getAddress();
    await this.getCurrentCountry();
    this.setImage();
    this.sharedService.getUserProfilePhoto().subscribe(data => {
      this.userProfileImage = data;
    })

    document.body.className = "header-fixed header-mobile-fixed subheader-enabled page-loading";

    // 21-dec-2021
    this.getResumeExperience();

    //27-dec-2021
    this.getCompletionPercentage();
    this.getCurrentCountry();

  }


  async getAddress() {
    this.helper.ShowSpinner();
    // this.http.get("https://ipapi.co/json").subscribe((res)=>{
    //       this.defaultCountry = res['country_name'].toUpperCase();
    // });
    let x = await this.http.get("https://ipapi.co/json").toPromise();
    if (x) {
      this.defaultCountry = x['country_name'].toUpperCase();
    }
    this.helper.HideSpinner();
  }

  setImage() {
    this.service.GetById(this.helper.GetUserInfo().candidateId, "/candidate/").then(data => {
      this.userData = data;
      this.userData.firstName = this.userData.firstName && this.userData.firstName.toUpperCase();
      this.userData.lastName = this.userData.lastName && this.userData.lastName.toUpperCase();
      if (data.profileImage) {
        this.userProfileImage = data.profileImage;
      }
      this.getCountryList();
    });

  }

  getCountryList() {
    this.service.GetAll('api/v1/country').then(data => {
      this.countryList = data.sort((a, b) => a.name.localeCompare(b.name));
      let selected = this.countryList && this.countryList.filter(el => el.name === this.userData.country);
      if (selected && selected.length > 0) {
      }
    })
  }

  async changeLocation(isoCode) {
    this.countryCode = isoCode;
    await this.getCurrentCountry();
  }

  async getCurrentCountry() {
    if (!this.countryCode) {
      // let userObj = this.helper.GetLocalStorage("User");
      // if (userObj) {
      //   this.currentCountry = JSON.parse(userObj).country;
      //   return;
      // }
      // else {
      if (!this.defaultCountry) {
        await this.getAddress();
      }
      this.currentCountry = this.defaultCountry;
      return;
      // }
    }
    let selected = this.countryList && this.countryList.filter(el => el.iso2 === this.countryCode);
    if (selected && selected.length > 0) {
      this.currentCountry = selected[0].name;
    } else {
      this.currentCountry = this.translate.instant('MENU.LOCATION');//'Select Location';
    }
  }

  async signOut() {
    let language = localStorage.getItem('locale');

    try {
      let x = await this.service.CommonGetParam("/auth/logout/" + this.helper.GetUserInfo().candidateId).toPromise();
      if (this.authService.authState) {
        this.authService.signOut();
      }
    } catch (error) {

    }

    localStorage.removeItem('User');
    localStorage.clear();
    localStorage.setItem('locale', language);
    this.router.navigate(['/Login']);
  }
  menuToggle() {
    this.IsMenuOpened = !this.IsMenuOpened
  }
  menuHide() {
    this.IsMenuOpened = false;
  }

  changeLang(event, language) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    this.language = language;
    this.ddlLanguage = false;
  }

  // added on 23-nov-2021
  resumeClick() {
    if (this.helper.GetUserInfo()?.resumeCompletion == null || this.helper.GetUserInfo()?.resumeCompletion == 0 || this.helper.GetUserInfo()?.resumeCompletion <= 39) {
      this.helper.ErrorToastr(this.translate.instant('RESUME.ERROR_MSG.PLEASE_CREATE_RESUME_FIRST'));
    }
  }
  socialProfileClick() {
    if (this.helper.GetUserInfo()?.resumeCompletion == null || this.helper.GetUserInfo()?.resumeCompletion == 0 || this.helper.GetUserInfo()?.resumeCompletion <= 34) {
      this.helper.ErrorToastr(this.translate.instant('RESUME.ERROR_MSG.PLEASE_CREATE_RESUME_FIRST_AND_UPDATE_SOCIAL_PROFILE_DETAILS'));
    }
  }
  documentClick() {
    if (this.helper.GetUserInfo()?.resumeCompletion == null || this.helper.GetUserInfo()?.resumeCompletion == 0 || this.helper.GetUserInfo()?.resumeCompletion <= 34) {
      this.helper.ErrorToastr(this.translate.instant('RESUME.ERROR_MSG.PLEASE_CREATE_RESUME_FIRST'));
    }
  }

  getResumeExperience() {
    this.helper.ShowSpinner();
    this.service
      .GetById(this.helper.GetUserInfo().candidateId, "resume/api/expPrecentage")
      .then(
        (res) => {
          this.helper.HideSpinner();
          if (res) {
          }
        },
        (error) => {
          this.helper.HideSpinner();
        }
      );
  }

  // 27-dec-2021
  getCompletionPercentage() {
    this.helper.ShowSpinner();
    this.completionPercentage = this.helper.GetUserInfo()?.resumeCompletion;
    this.service
      .GetById(this.helper.GetUserInfo().candidateId, "/candidate/")
      .then((data) => {
        this.helper.HideSpinner();
        if (data && data.resume && data.resume[0] && data.resume[0].resumeCompletion) {
          this.helper.SetLocalStorage("User", {
            ...this.helper.GetUserInfo(),
            resumeCompletion: data.resume[0].resumeCompletion,
          });
        }
        this.completionPercentage = this.helper.GetUserInfo()?.resumeCompletion;
      });
  }

}


