import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { CommonService } from 'src/Service/Common.service';
import { Skill } from "../../core/resume/model/Skills.module";
import { QueryParamsModel } from "../../core/model/query-models/query-params.model";
import { HttpUtilsService } from "../../core/utils/http-utils.service";
import * as moment from 'moment';
import { LayoutserviceService } from 'src/app/core/utils/layoutservice.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;
  ResumeSkillData: any = {};
  isSwitchedToEditMode = false;
  itemForEdit: Skill;
  itemForAdd: Skill;
  @Input() isPopup: boolean;
  language: string = 'en';
  SkillLists: any = [];
  AllSkillLists: any = [];
  skills: any[] = [];
  skillList: Skill[] = [];
  itemResultList: Skill[] = [];
  Filterlist: any = [];
  constructor(public config: DynamicDialogConfig, private httpUtils: HttpUtilsService, public ref: DynamicDialogRef, private helper: CommonHelper,
    private service: CommonService, public fb: FormBuilder, private cdr: ChangeDetectorRef, public layoutUtilsService: LayoutserviceService, private translate: TranslateService) { }

  async ngOnInit() {
    this.language = localStorage.getItem("locale");
    this.ResumeSkillData = this.config.data;
    this.skillList = this.ResumeSkillData;
    this.createFormGroup();
    this.loaditemList();
    await this.GetSkillList();
    if (this.skillList.length === 0) {
      this.addItemButtonOnClick();
    } else {
      this.cancelAddButtonOnClick();
    }

  }
  filterbyType(event) {
    this.Filterlist = [];
    const value = event.target.value;
    this.SkillLists = this.AllSkillLists.filter(s => s.type == value);
    for (var skill of this.SkillLists) {
      for (var sss of skill.name) {
        if (sss.language == this.language)
          this.Filterlist.push({ name: sss.value })
      }
    }
    return this.Filterlist;
  }

  filterbyTypeName(type) {
    this.Filterlist = [];
    const value = type;
    this.SkillLists = this.AllSkillLists.filter(s => s.type == value);
    for (var skill of this.SkillLists) {
      for (var sss of skill.name) {
        if (sss.language == this.language)
          this.Filterlist.push({ name: sss.value })
      }
    }
    return this.Filterlist;
  }

  async GetSkillList() {
    try {
      let res = await this.service.GetAll("api/v1/getSkills");
      this.SkillLists = res;
      this.AllSkillLists = res;
    }
    catch
    {
      this.helper.ErrorToastr("Cannot fetch");
    }
  }


  levelList: any[] = [
    {
      level: 'Basic',
      levelValue: 1
    },
    {
      level: 'intermediate',
      levelValue: 2
    },
    {
      level: 'advanced',
      levelValue: 3
    },
    {
      level: 'expert',
      levelValue: 4
    },
  ];

  loaditemList(index: number = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.skillList.forEach((item, i) => {
      let newItem = new Skill();
      newItem.clear();
      newItem.id = item.id;
      newItem._id = item._id;
      newItem.name = item.name;
      newItem.type = item.type;
      newItem.level = item.level;
      newItem._isEditMode = false;

      if (i === index) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.skillList = this.itemResultList;
    this.skillList = this.itemResultList = this.baseFilter(this.itemResultList, 'level', 'desc');
    this.cdr.detectChanges();
  }

  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addName: ['', Validators.compose([Validators.required])],
      addType: ['', Validators.compose([Validators.required])],
      addLevel: ['', Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      level: ['', Validators.compose([Validators.required])],
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addName.setValue('');
    controls.addName.markAsPristine();
    controls.addName.markAsUntouched();
    controls.addType.setValue('');
    controls.addType.markAsPristine();
    controls.addType.markAsUntouched();
    controls.addLevel.setValue('');
    controls.addLevel.markAsPristine();
    controls.addLevel.markAsUntouched();

    this.itemForAdd = new Skill();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }
  baseFilter(array, sort, dir, filter: any = {}) {
    const queryParams = new QueryParamsModel(filter, dir, sort, 0, 100);
    const result = this.httpUtils.baseFilter(array, queryParams, []);
    return result.items;
  }
  checkAddForm() {
    const controls = this.basicForm.controls;
    if (controls.addName.invalid || controls.addType.invalid || controls.addLevel.invalid) {
      controls.addName.markAsTouched();
      controls.addType.markAsTouched();
      controls.addLevel.markAsTouched();
      return false;
    }
    return true;
  }

  addItemButtonOnClick() {
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-skill');
  }

  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    this.scrollTo('list-skill');
  }

  saveNewItem() {
    const controls = this.basicForm.controls;

    if (!this.checkAddForm()) {
      return;
    }

    this.itemForAdd.name = controls.addName.value;
    this.itemForAdd.type = controls.addType.value;
    this.itemForAdd.level = controls.addLevel.value;

    const isSkillsAdded = this.skillList.find(
      (data) => data.name.toLowerCase() === this.itemForAdd.name.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase()
    );

    if (isSkillsAdded) {
      this.skillList.map((data) => {
        if (data.name.toLowerCase() === this.itemForAdd.name.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase()) {
          data.type = this.itemForAdd.type;
          data.level = this.itemForAdd.level;
        }
      });
    } else {
      this.skillList.push(this.itemForAdd);
    }
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.name.setValue('');
    controls.name.markAsPristine();
    controls.name.markAsUntouched();
    controls.type.setValue('');
    controls.type.markAsPristine();
    controls.type.markAsUntouched();
    controls.level.setValue('');
    controls.level.markAsPristine();
    controls.level.markAsUntouched();

    this.itemForEdit = new Skill();
    this.itemForEdit.clear();
    this.itemForEdit._isEditMode = false;
  }

  checkEditForm() {
    const controls = this.basicForm.controls;
    if (controls.name.invalid || controls.type.invalid || controls.level.invalid) {
      controls.name.markAsTouched();
      controls.type.markAsTouched();
      controls.level.markAsTouched();
      return false;
    }
    return true;
  }

  editItemButtonOnClick(_item: Skill, index) {
    this.filterbyTypeName(_item.type);
    const controls = this.basicForm.controls;
    controls.name.setValue(_item.name);
    controls.type.setValue(_item.type);
    controls.level.setValue(_item.level);
    this.loaditemList(index, true);
    this.isSwitchedToEditMode = true;
    this.scrollTo('edit-skill');
  }


  cancelEditButtonOnClick(_item: Skill) {
    let index;
    this.itemResultList.map((data, i) => {
      if (data._isEditMode) {
        index = i;
      }
    })
    this.loaditemList(index, false);
    this.isSwitchedToEditMode = false;
    this.scrollTo('list-skill');
  }

  saveUpdatedItem(_item: Skill) {
    if (!this.checkEditForm()) {
      return;
    }

    const controls = this.basicForm.controls;

    const isSkillsAdded = this.itemResultList.find(
      (data) =>
        data.name.toLowerCase() === controls.name.value.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase() && data._isEditMode === false
    );

    if (isSkillsAdded) {
      let newList = [];
      this.itemResultList.forEach((item, i) => {
        if (!item._isEditMode) {
          newList.push(item);
          this.isUpdated = true;
        }
      });
      this.skillList = newList;

      this.skillList.map((data, index) => {
        if (data.name.toLowerCase() === controls.name.value.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase()) {
          data.type = controls.type.value;
          data.level = controls.level.value;
          this.isUpdated = true;
        }
      });
    } else {
      this.skillList.forEach((item, i) => {
        if (item._isEditMode) {
          item.name = controls.name.value;
          item.type = controls.type.value;
          item.level = controls.level.value;
          this.isUpdated = true;
        }
      });
    }
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  deleteItem(index) {
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _description = this.translate.instant('RESUME.SKILLS.MESSAGES.DELETE');
    let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (!res) {
        return;
      }
      let newItem: Skill[] = [];
      this.itemResultList.forEach((item, i) => {
        if (i != index) {
          newItem.push(item);
        }
      })
      this.isUpdated = true;
      this.skillList = newItem;
      this.loaditemList();
    });
  }

  onSubmit(): void {

    if ((this.itemResultList.length === 0)) {
      //    this.layoutUtilsService.showActionNotification(MessageType.warning, "Need to add Skills!", "You need to add minimum one skill to continue");
      return;
    }

    if (!this.isUpdated) {
      //   this.stepCount$.next(11);
      return;
    }

    this.loading = true;
    this.cdr.detectChanges();

    const data: any = {};
    data._id = this.ResumeSkillData?._id;
    data.type = "skills";
    data.language = this.language;
    data.skills = this.skillList;
    try {
      this.ResumeSkillData["candidateId"] = this.helper.GetUserInfo().candidateId;
      this.service.CommonPost(data.skills, "resume/api/skills/" + this.helper.GetLocalStorage("ResumeId", true)).then(data => {
        if (this.language == "en")
          this.helper.SucessToastr("Updated Successfully!");
        else
          this.helper.SucessToastr("¡Actualizado con éxito!");
        this.ref.close(true);
      })
    } catch
    {
      this.helper.ErrorToastr("Cannot update, please try again");
    }


    this.cdr.detectChanges();
  }

  prevStep() {
    // this.stepCount$.next(9);
  }

  scrollTo(id): void {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

}
