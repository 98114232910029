import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { Social } from "src/app/core/socialprofile/social.model";
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";

@Component({
  selector: "app-religion",
  templateUrl: "./religion.component.html",
  styleUrls: ["./religion.component.css"],
})
export class ReligionComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  @Input() socialDetails$: BehaviorSubject<Social>;

  socialDetails: Social = new Social();

  language: string = "en";

  // Subscriptions
  private subscriptions: Subscription[] = [];
  constructor(
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public service: CommonService,
    public helper: CommonHelper
  ) {}

  ngOnInit(): void {
    this.socialDetails.clear();
    if (this.config.data && this.config.data.religion) {
      this.socialDetails = this.config.data;
      this.socialDetails.religion = this.config.data.religion[0];
    } 
    this.initForm();

  }
  onSubmit(): void {
    const controls = this.basicForm.controls;
    if (this.basicForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    const data = {
      candidateId: 0,
      language: this.language,
      religiondesc: controls.description.value,
      socialProfileId: this.config.data
        ? this.config.data.general.socialProfileId
        : null,
    };

    this.loading = true;

    this.service
      .CommonPostObservable(data, "socialprofile/api/religiondesc")
      .subscribe(
        (data) => {
          this.loading = false;
          console.log(data);
          this.ref.close(true);
        },
        (err) => {
          this.helper.ErrorToastr("Cannot Update! Please retry");
        }
      );
    return;

    // this.loading = true;
    // this.cdr.detectChanges();

    // const data: any = {};
    // data._id = this.socialDetails?._id;
    // data.language = this.language;
    // data.type = "religion";
    // data.religion = {
    //   //religion: controls.religion.value,
    //   description:  controls.description.value
    // }
  }
  isControlHasError(
    controlName: string,
    validationType: string = undefined
  ): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result =
        control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }
  initForm() {
    this.basicForm = this.fb.group({
      // religion: [this.socialDetails?.religion?.religion, Validators.compose([
      // 	Validators.required
      // ])
      // ],
      description: [
        this.socialDetails?.religion?.religiondesc,
        Validators.compose([Validators.required]),
      ],
    });
  }
}
