export class Skill {
    _id: string;
    id :number;
    type: string;
    level: string;
    levelValue: number;
    name: any;
    _isEditMode: boolean;

    clear(): void {
        this._id = undefined;
        
        this.type = '';
        this.level = '';
        this.levelValue = 0;
        this.name = '';
        this._isEditMode = false;
    }
}
