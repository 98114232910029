import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { CommonService } from "src/Service/Common.service";
import { CommonHelper } from "src/Helper/CommonHelper";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { LayoutserviceService, MessageType } from 'src/app/core/utils/layoutservice.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmPasswordValidator } from 'src/app/authenticate-browser/confirm-password.validator';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})

export class AccountInfoComponent implements OnInit, OnChanges {

  user: Array<string>;
  //@Input() user: any;

  PasswordData: any = {};
  basicData: any = {};

  password: any;

  countryList = [];
  phoneCodeList = [];
  cityList = [];
  stateList = [];

  passwordmatch: string;
  passwordType: string[] = ['password', 'password', 'password'];

  loadState: boolean = false;
  loadCity: boolean = false;

  userUpdateForm: FormGroup;
  candidateId = null;
  isValid: boolean;
  student: any;

  private subscriptions: Subscription[] = [];
  phonecodeiso: any = [];
  phoneCode = '';

  constructor(
    private service: CommonService,
    public helper: CommonHelper,
    public fb: FormBuilder,
    private layoutUtilsService: LayoutserviceService,
    public translate: TranslateService,
    public http: HttpClient
  ) {
    translate.addLangs(['en', 'es']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit(): void {
    this.getCountryList();
    this.candidateId = this.helper.GetUserInfo()?.candidateId;
  }

  getUserData() {
    this.service
      .GetById(this.helper.GetUserInfo().candidateId, "candidate")
      .then(
        (res) => {
          if (res) {
            this.basicData = res;
            this.getStateList(this.basicData.country);
            this.getCityList(this.basicData.state);
            if (res["profileImage"] != undefined && res["profileImage"] != null) {
              if (!this.basicData.profileImage) {
                this.basicData.profileImage = res["profileImage"];
              }
            }
            this.initForm();
          }
        })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.basicData) {
      if (this.basicData.country) {
        this.getStateList(this.basicData.country);
      }
      if (this.basicData.state) {
        this.getCityList(this.basicData.state);
      }
    }

  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      candidateId: [this.basicData.id, Validators.compose([
        Validators.required,
      ])
      ],
      nickName: [this.basicData.nickName, Validators.compose([
        Validators.required
      ])
      ],
      phoneCode: [this.basicData.phoneCode, Validators.compose([
        Validators.required
      ])
      ],
      phone: [this.basicData.phoneNumber, Validators.compose([
        Validators.required,
        Validators.pattern("[0-9]{8,13}")
      ])
      ],
      emailID: [this.basicData.emailID, Validators.compose([
        Validators.required,
        Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")
      ])
      ],
      currentPassword: ['', Validators.compose([
        Validators.required
      ])
      ],
      password: ['', Validators.compose([
        Validators.required
      ])
      ],
      confirmPassword: ['',
        Validators.required
      ]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  getCityList(state) {
    this.service.GetAll("api/v1/cities/" + state).then(data => {
      this.cityList = data.sort((a, b) => a.name.localeCompare(b.name));
    }, err => {
      this.helper.ErrorToastr("Cannot fetch");
    })
  }

  getStateList(country) {
    this.service.GetAll("api/v1/states/" + country).then(data => {
      this.stateList = data.sort((a, b) => a.name.localeCompare(b.name));
    }, err => {
      this.helper.ErrorToastr("Cannot fetch");
    })
  }

  getCountryList() {
    this.service.GetAll("api/v1/allcountry").then(data => {
      this.getUserData();
      this.countryList = data.sort((a, b) => a.name.localeCompare(b.name));
      this.phoneCodeList = data.sort((a, b) => a.name.localeCompare(b.name));
      this.phoneCodeList.forEach(element => {
        this.phonecodeiso.push({ value: "(" + element.iso3 + ")" + element.phoneCode });
      });
      
    }, err => {
      this.helper.ErrorToastr("Cannot fetch");
    })
  }

  onSubmit() {
    this.user = this.userUpdateForm.value;
    let newObject = {
      candidateId  : this.candidateId,
      nickName : this.basicData.nickName,
      phoneCode : this.basicData.phoneCode,
      phone : this.basicData.phoneNumber,
      emailID : this.basicData.emailID
    };
    this.service
      .CommonPost(
        newObject,
        "profile/api/update/"
      )
      .then((data) => {
        if (data) {
          if (this.password) {
            this.service
              .CommonPost(
                this.user,
                "profile/api/updatePw/"
              )
              .then((data) => {
                this.layoutUtilsService.showActionNotification(
                  MessageType.success,
                  this.translate.instant('RESUME.UPDATED_SUCCESSFULLY')
                );
              });
          } else {
            this.layoutUtilsService.showActionNotification(
              MessageType.success,
              this.translate.instant('RESUME.UPDATED_SUCCESSFULLY')
            );
          }

        } else {
          this.layoutUtilsService.showActionNotification(
            MessageType.warning,
            "Something went wrong , Please try again later"
          );
        }

      });
  }

  togglePassword(index) {
    if (this.passwordType[index] === 'password') {
      this.passwordType[index] = 'text';
    } else {
      this.passwordType[index] = 'password';
    }
  }

  passwordMatch(): boolean {
    let isValidflag: boolean = false;
    let save_data = Object.assign({}, this.PasswordData);
    let password = save_data["password"];
    let confirmPassword = save_data["confirmpassword"];
    this.passwordmatch = '';
    if (password == undefined) {
      return;
    }

    if (confirmPassword == undefined) {
      return;
    }

    if (confirmPassword == null) {
      return false;
    }

    if (password === confirmPassword) {
      isValidflag = true;
      this.passwordmatch = 'Password and confirm Password are matched'
    }
    this.isValid = isValidflag;
    return isValidflag;

  }


  passwordStrength(type: string): boolean {
    const controls = this.userUpdateForm.controls;
    let isValid: boolean = false;
    let save_data = this.userUpdateForm.value;
    let password = save_data["password"];

    if (password == undefined) {
      return;
    }
    if (type === 'minLength') {
      isValid = /^[a-zA-Z, 0-9, #?!@$%^&*_+=-]{8,}/.test(password);
    } else if (type === 'special') {
      isValid = /[#?!@$%^&*_+=-]/.test(password);
    } else if (type === 'uppercase') {
      isValid = /[A-Z]/.test(password);
    } else if (type === 'number') {
      isValid = /\d/.test(password);
    }
    if (isValid) {

      return true;
    }
    if (controls.password.value) {
      controls.password.setErrors({ pattern: true });
    } else {

      controls.password.setErrors({ pattern: true, required: true });
    }
    return false;
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.userUpdateForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.invalid && control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }



  // 24-dec-2021

  async deleteStudyItem(id) {
    let _type = this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.BTN_DEACTIVATE');
    let _title = this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.BTN_DEACTIVATE');
    let _description = this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.SUB_TITLE_DEACTIVATE');
    let _confirmButton = this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.BTN_DEACTIVATE');
    let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe(async (res) => {
      if (res) {
        this.service.CommonGetParam("profile/api/deactivation/" + this.candidateId).subscribe((res1 => {
          if (res1) {
            this.layoutUtilsService.showActionNotification(
              MessageType.success,
              this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.DEACTIVATE_SUCCESS')
            );
            this.helper.redirectTo("Login");
          }
          else {
            this.layoutUtilsService.showActionNotification(
              MessageType.warning,
              this.translate.instant('ACCOUNT_SETTINGS.ACCOUNT.STATUS_CHANGE.DEACTIVATE_FAILURE')
            );
          }
        }));
      }
    });
  }

}
