<div id="list-recognition" class="mt-5">
    
  <div class="form-group row" *ngIf="itemResultList.length > 0">
    <div class="col-lg-12">
      <div class="row" *ngIf="itemResultList.length > 0">
        <ng-container *ngFor="let item of itemResultList">
          <div class="col-xl-4" *ngIf="!item._isEditMode">
            <div
              class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2"
            >
              <!--begin::Body-->
              <div class="card-body p-3">
                <div class="font-size-md">
                  <span class="text-dark-75 font-weight-bold">
                  {{item.title}}</span>
                </div>
                <div class="font-size-sm">
                  <span class="text-muted">
                  {{item.description}}</span>
                </div>
                <div class="action-button">
                  <a
                    href="javascript:;"
                    (click)="editItemButtonOnClick(item)"
                    *ngIf="!isSwitchedToEditMode"
                    class="action-primary"
                    ><i class="fa fa-pen text-success"></i
                  ></a>
                  <a
                    href="javascript:;"
                    (click)="deleteItem(item)"
                    *ngIf="!isSwitchedToEditMode"
                    class="action-secondary"
                    ><i class="fa fa-trash text-danger"></i
                  ></a>
                </div>
              </div>
              <!--end:: Body-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <form class="" [formGroup]="basicForm">
  <div id="add-recognition">
    <div class="form-group row" *ngIf="itemForAdd && itemForAdd._isEditMode">
      <div class="col-lg-12">
        <div class="form-group row align-items-center">
          <h3 class="col-lg-12 font-weight-bold" *ngIf="!isEditing">{{'SOCIAL.RECOGNITIONS.ADD_NEW_TITLE' | translate}}</h3>
          <h3 class="col-lg-12 font-weight-bold" *ngIf="isEditing">{{'SOCIAL.RECOGNITIONS.EDIT_TITLE' | translate}}</h3>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-7 mt-6">
                <label>{{'SOCIAL.RECOGNITIONS.FIELD.ENTER_ACHIEVEMENT_TITLE' | translate}}:</label>
                <input
                  class="form-control"
                  type="text" maxlength="150"
                  formControlName="addTitle"
                  [(ngModel)] = "itemForAdd.title"
                  placeholder="{{'SOCIAL.RECOGNITIONS.FIELD.TITLE_INPUT' | translate}}" />
                  <div class="fv-plugins-message-container float-right">
                    <div class="fv-help-block">
                      <span>({{ 150 - (basicForm.addTitle ? basicForm.addTitle.length : 0) }}/150)</span>
                    </div>
                  </div>
                
                <div
                  class="fv-plugins-message-container"
                  *ngIf="isControlHasError('addTitle', 'required')"
                >
                  <div class="fv-help-block">
                    <span>{{'SOCIAL.RECOGNITIONS.FIELD.TITLE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7 mt-6">
                <label>{{'SOCIAL.RECOGNITIONS.FIELD.DESCRIPTION' | translate}}:</label>
                <textarea
                  class="form-control"
                  type="text"
                  formControlName="addDescription"
                  [(ngModel)] = "itemForAdd.description"
                  placeholder="{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION_INPUT' | translate}}"
                  maxlength="300"
                ></textarea>
                <div class="fv-plugins-message-container float-right">
                  <div class="fv-help-block">
                    <span>({{ 300 - (basicForm.addDescription ? basicForm.addDescription.length : 0) }}/300)</span>
                  </div>
                </div>
                <div
                  class="fv-plugins-message-container"
                  *ngIf="isControlHasError('addDescription', 'required')"
                >
                  <div class="fv-help-block">
                    <span>
                      {{'SOCIAL.RECOGNITIONS.FIELD.DESCRIPTION_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mt-15" *ngIf="!isEditing">
                <a
                  href="javascript:;"
                  (click)="saveNewItem()"
                  class="btn btn-sm font-weight-bolder btn-light-success mr-5"
                >
                  <i class="la la-check"></i
                ></a>
                <a
                  href="javascript:;"
                  (click)="cancelAddButtonOnClick()"
                  class="btn btn-sm font-weight-bolder btn-light-danger mr-5"
                >
                  <i class="la la-close"></i
                ></a>
              </div>
              <div class="col-lg-3 mt-15" *ngIf="isEditing">
                <button
                  (click)="saveUpdatedItem('')"
                  class="btn btn-sm font-weight-bolder btn-light-success mr-5"
                >
                  <i class="la la-check"></i>
                </button>
                <button
                  (click)="cancelEditButtonOnClick('')"
                  class="btn btn-sm font-weight-bolder btn-light-danger mr-5"
                >
                  <i class="la la-close"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div
  *ngIf="itemResultList && itemResultList.length === 0 && !isSwitchedToEditMode"
  class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b"
  role="alert"
>
  <div class="alert-text">{{'SOCIAL.RECOGNITIONS.NO_DATA' | translate}}</div>
</div>
<div class="form-group row">
  <div class="col-lg-4">
    <button
      *ngIf="itemForAdd && !itemForAdd._isEditMode && !isSwitchedToEditMode"
      (click)="addItemButtonOnClick()"
      class="btn btn-sm font-weight-bolder btn-light-primary"
    >
      <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}
    </button>
  </div>
</div>
</div>
<!--begin: Wizard Actions-->
<div class="d-flex justify-content-between border-top mt-5 pt-10">
<div class="mr-2">
  <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
</div>
<div>
  <ng-container *ngIf="loading">
    <span class="spinner spinner-primary mr-10"></span>
  </ng-container>
  <button
    type="button"
    (click)="onSubmit()"
    class="btn btn-primary font-weight-bolder text-uppercase"
  >
  {{'BUTTON.SAVE_EXIT' | translate}}   </button>
</div>
</div>
<!--end: Wizard Actions-->
