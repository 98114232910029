import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { LayoutserviceService } from "src/app/core/utils/layoutservice.service";
import { Extra } from 'src/app/core/socialprofile/extra.model';
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-recognitions",
  templateUrl: "./recognitions.component.html",
  styleUrls: ["./recognitions.component.css"],
})
export class RecognitionsComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    public service: CommonService,
    public helper: CommonHelper,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService
  ) {}
  loading: boolean;
  submitted: boolean;
  isAddOrEditShow: boolean;
  basicForm: FormGroup;
  isUpdated = false;
  recognitionsLists: any[] = [];
  isSwitchedToEditMode = false;
  editId: any;
  itemForEdit: Extra;
  itemForAdd: Extra;
  isEditing: boolean;

  itemResultList: Extra[] = [];
  recognitionsList: Extra[] = [];

  ngOnInit(): void {
    if (this.config.data && this.config.data.recognitions) {
      this.config.data.recognitions.map((data, index) => {
        this.recognitionsList.push({...data })
      });
      this.loaditemList();
    }
    this.createFormGroup();
  }

  loaditemList(id: string = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.recognitionsList.forEach(item => {
      let newItem = new Extra();
      newItem.clear();
      newItem.id = item.id;
      newItem.title = item.title;
      newItem.description = item.description;
      newItem._isEditMode = false;
      if (item.id === id) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.recognitionsList = this.itemResultList;
  }

  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addTitle: ['', Validators.compose([Validators.required])],
      addDescription: ['', Validators.compose([])]
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addTitle.setValue('');
    controls.addTitle.markAsPristine();
    controls.addTitle.markAsUntouched();
    controls.addDescription.setValue('');
    controls.addDescription.markAsPristine();
    controls.addDescription.markAsUntouched();

    this.itemForAdd = new Extra();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }

  checkAddForm() {
    const controls = this.basicForm.controls;
    if (controls.addTitle.invalid || controls.addDescription.invalid) {
      controls.addTitle.markAsTouched();
      controls.addDescription.markAsTouched();
      return false;
    }
    return true;
  }

  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.addTitle.setValue('');
    controls.addTitle.markAsPristine();
    controls.addTitle.markAsUntouched();
    controls.addDescription.setValue('');
    controls.addDescription.markAsPristine();
    controls.addDescription.markAsUntouched();

    this.itemForAdd = new Extra();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
    this.isEditing = false;
  }

  addItemButtonOnClick() {
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-recognition');
  }

  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    this.scrollTo('list-recognition');
  }

  editItemButtonOnClick(_item: any) {
    // _item = this.hobbiesList.find(data => data._isEditMode);
    this.itemForAdd = _item;
    this.itemForAdd._isEditMode = true;
    this.loaditemList(_item.id, true);
    const controls = this.basicForm.controls;
    this.isEditing = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-recognition');
  }

  cancelEditButtonOnClick(_item: any) {
    // _item = this.hobbiesList.find(data => data._isEditMode)
    this.loaditemList(_item.id, false);
    this.isSwitchedToEditMode = false;
    this.isEditing = false;
    this.scrollTo('list-recognition');
    this.clearEditForm();
  }

  saveNewItem() {
    const controls = this.basicForm.controls;

    if (!this.checkAddForm()) {
      return;
    }
    this.itemForAdd.id = moment().valueOf().toString();

    this.itemForAdd.title =  controls.addTitle.value;
    this.itemForAdd.description = controls.addDescription.value;
    this.itemForAdd._isEditMode = false;

    this.recognitionsList.push(JSON.parse(JSON.stringify(this.itemForAdd)));
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  checkEditForm() {
    const controls = this.basicForm.controls;
    if (controls.addTitle.invalid || controls.addDescription.invalid) {
      controls.addTitle.markAsTouched();
      controls.addDescription.markAsTouched();
      return false;
    }
    return true;
  }

  saveUpdatedItem(_item: Extra) {
    if (!this.checkEditForm()) {
      return;
    }

    if (!_item) {
      _item = this.itemResultList.find((data) => (data._isEditMode === true));
    }

    const controls = this.basicForm.controls;

    this.recognitionsList.forEach(item => {
      if (item.id === _item.id) {
        item.title = controls.addTitle.value;
        item.description = controls.addDescription.value;
      }
    });
    this.isSwitchedToEditMode = false;
    this.isEditing = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  

  scrollTo(id): void {
    document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  deleteItem(_item: Extra) {
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _description = this.translate.instant('SOCIAL.RECOGNITIONS.MESSAGES.DELETE');
      let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (res) {
   
      let newItem: Extra[] = [];
      this.itemResultList.forEach(item => {
        if (item.id !== _item.id) {
          newItem.push(item);
        }
    
      this.isUpdated = true;
      this.recognitionsList = newItem;
      this.loaditemList();
    
  })
}
})
}

  close() {
    this.isAddOrEditShow = false;
    this.editId = null;
  }
  onSubmit() {
    if ((this.itemResultList.length === 0)) {
      //  this.layoutUtilsService.showActionNotification(MessageType.warning, this.translate.instant('SOCIAL.FAMILY.HOBBIES.EMPTY'));
        return;
      }
  
      if (!this.isUpdated) {
        //this.stepCount$.next(6);
        return;
      }
  
      this.loading = true;
      let recognitionList = [];
      this.recognitionsList.map(data => {
        delete data._isEditMode;
        recognitionList.push({...data})
      })
      this.service
          .CommonPostObservable(recognitionList, "socialprofile/api/recognitions/"+this.config.data.general.socialProfileId)
          .subscribe((data) => {
            this.loading = false;
            console.log(data);
            this.ref.close(true);
          }, err => {
            this.helper.ErrorToastr("Cannot Update! Please retry");
      });
  }
}
