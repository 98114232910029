import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { CommonHelper } from "../Helper/CommonHelper";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  headers;
  constructor(private httpClient: HttpClient, private helper: CommonHelper) {
    this.headers = new HttpHeaders();
    this.headers.append("Access-Control-Allow-Headers", "Authorization");
    this.headers.append("Access-Control-Allow-Origin", "*");
    this.headers.append("Content-Type", "multipart/form-data");
  }
  public GetAll(UrlName: string) {
    return this.httpClient
      .get<any>(`${this.helper.ApiURL}/${UrlName}`)
      .toPromise<any>();
  }
  public GetAllReference(resumeId) {
    return this.httpClient
      .get<any>(`${this.helper.ApiURL}/resume/api/requestedRefer/${resumeId}`)
      .toPromise<any>();
  }
  public GetById(id: any, UrlName: string) {
    return this.httpClient
      .get<any>(`${this.helper.ApiURL}/${UrlName}/${id}`)
      .toPromise<any>();
  }

  public CommonPost(model: any, UrlName: string) {
    return this.httpClient
      .post(`${this.helper.ApiURL}/${UrlName}`, model)
      .toPromise<any>();
  }

  public CommonPostObservableRaw(model: any, UrlName: string) {
    return this.httpClient.post(`${this.helper.ApiURL}/${UrlName}`, model, {
      responseType: "text",
    });
  }

  public CommonPostObservable(model: any, UrlName: string) {
    return this.httpClient.post(`${this.helper.ApiURL}/${UrlName}`, model);
  }

  public CommonGet(model: any, UrlName: string) {
    return this.httpClient
      .get(`${this.helper.ApiURL}/${UrlName}`, model)
      .toPromise<any>();
  }

  public CommonGetParam(UrlName: string) {
    return this.httpClient
      .get(`${this.helper.ApiURL}/${UrlName}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }
  public CommonPostRaw(model: any, UrlName: string) {
    return this.httpClient
      .post(`${this.helper.ApiURL}/${UrlName}`, model, { responseType: "text" })
      .toPromise<any>();
  }

  public CommonPut(model: any, UrlName: string) {
    return this.httpClient
      .put(`${this.helper.ApiURL}/${UrlName}`, model)
      .toPromise<any>();
  }

  public CommonPutObservable(model: any, UrlName: string) {
    return this.httpClient.put(`${this.helper.ApiURL}/${UrlName}`, model);
  }

  public CommonPutObservableRaw(model: any, UrlName: string) {
    return this.httpClient.put(`${this.helper.ApiURL}/${UrlName}`, model, {
      responseType: "text",
    });
  }

  public CommonPatch(model: any, UrlName: string) {
    return this.httpClient
      .patch(`${this.helper.ApiURL}/${UrlName}`, model)
      .toPromise<any>();
  }
  public CommonPatchRaw(model: any, UrlName: string) {
    return this.httpClient
      .patch(`${this.helper.ApiURL}/${UrlName}`, model, {
        responseType: "text",
      })
      .toPromise<any>();
  }

  public Delete(UrlName: string) {
    return this.httpClient
      .delete(`${this.helper.ApiURL}/${UrlName}`)
      .toPromise<any>();
  }

  public PostWithParameter(model: any, UrlName: string, params: any) {
    let url = `${this.helper.ApiURL}/${UrlName}`;
    params.forEach((e) => {
      url = url + "/" + e.params;
    });
    return this.httpClient.post(url, model).toPromise<any>();
  }

  public GetWithParam(params: any, UrlName: string) {
    let url = `${this.helper.ApiURL}/${UrlName}`;
    return this.httpClient
      .get(`${this.helper.ApiURL}/${UrlName}`, params)
      .toPromise<any>();
  }

  public GetWithParameter(UrlName: string, params: any) {
    let url = `${this.helper.ApiURL}/${UrlName}`;
    params.forEach((e) => {
      url = url + "/" + e.params;
    });
    return this.httpClient.get<any>(url).toPromise<any>();
  }

  public FullUrlGet(UrlName: string) {
    return this.httpClient.get<any>(UrlName).toPromise<any>();
  }

  public UploadUrlPost(UrlName: string, model: any) {
    return this.httpClient.post(`${this.helper.ApiURL}/${UrlName}`, model, {
      headers: this.headers,
    });
  }
}
