import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { Subject, Observable, BehaviorSubject, Subscription } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
import { QueryParamsModel } from "../../core/model/query-models/query-params.model";
import { HttpUtilsService } from "../../core/utils/http-utils.service";
import * as moment from "moment";
import { Skill } from "src/app/core/resume/model/Skills.module";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";
import { LayoutserviceService } from "src/app/core/utils/layoutservice.service";
import { TranslateService } from "@ngx-translate/core";

// NGRX

@Component({
  selector: "app-itskills",
  templateUrl: "./itskills.component.html",
  styleUrls: ["./itskills.component.css"],
})
export class ItskillsComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;

  language: string = "en";

  isSwitchedToEditMode = false;
  itemForEdit: Skill;
  itemForAdd: Skill;

  actionTab: number = 1;
  ResumeComputerData: any = {};
  showTab1: boolean = true;
  showTab2: boolean = false;

  computerSkills: any[] = [];

  itemResultList: Skill[] = [];

  levelList: any[] = [];

  @Input() stepCount$: BehaviorSubject<number>;

  @Input() isPopup: boolean;

  skillList: Skill[] = [];
  ItSkillList: any = [];
  Filterlist: any = [];
  SkillsFilterlist: any = [];
  FilterOfficeAutomationlist: any = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];
  name: any;
  type: any;
  level: any;

  constructor(
    public config: DynamicDialogConfig,
    private helper: CommonHelper,
    public ref: DynamicDialogRef,
    private service: CommonService,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private httpUtils: HttpUtilsService,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    this.language = localStorage.getItem("locale");
    this.createFormGroup();
    this.ResumeComputerData = this.config.data;
    this.skillList = this.ResumeComputerData;
    this.computerSkills = this.ResumeComputerData;
    await this.GetITList();
    await this.filteryOfficeAutomation();
    this.loaditemList();
    this.levelList = [
      {
        level: this.translate.instant('RESUME.COMPUTER_SKILLS.LEVEL.BASIC'),
        levelValue: 1,
      },
      {
        level: this.translate.instant('RESUME.COMPUTER_SKILLS.LEVEL.INTERMEDIATE'),
        levelValue: 2,
      },
      {
        level: this.translate.instant('RESUME.COMPUTER_SKILLS.LEVEL.ADVANCED'),
        levelValue: 3,
      },
      {
        level: this.translate.instant('RESUME.COMPUTER_SKILLS.LEVEL.EXPERT'),
        levelValue: 4,
      },
    ]
  }
  loaditemList(index: number = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.skillList.forEach((item, i) => {
      let newItem = new Skill();
      newItem.clear();
      newItem._id = item._id;
      newItem.id = item.id;
      newItem.name = item.name;
      newItem.type = item.type;
      newItem.level = item.level;
      newItem._isEditMode = false;
      if (i === index) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.skillList = this.itemResultList;
    this.skillList = this.itemResultList = this.baseFilter(
      this.itemResultList,
      "level",
      "desc"
    );
    this.cdr.detectChanges();
  }

  filteryOfficeAutomation() {
    this.FilterOfficeAutomationlist = [];
    const type = "Office Automation";
    const TempItSkillList = this.ItSkillList.filter((s) => s.type == type);
    for (var product of TempItSkillList) {
      var label = null;
      var value = null;
      for (var sss of product.name) {
        if (sss.language == "en") {
          value = sss.value;
          if (this.language != "es") {
            label = sss.value;
          }
        } else {
          if (this.language == "es") {
            label = sss.value;
          }
        }
      }
      this.FilterOfficeAutomationlist.push({ name: label, value: value });
    }
    return this.FilterOfficeAutomationlist;
  }

  getOfficeAutomationLabel(name) {
    if (this.language == "en") {
      return name;
    } else {
      return this.FilterOfficeAutomationlist.find((o) => o.value == name)?.name || name;
    }
  }

  filterbyTypeManually(type) {
    this.Filterlist = [];
    let value = type
    if (value == 'Programming Languages / Frameworks') {
      value = 'programming languages';
    } else if (value == 'Database') {
      value = 'databases';
    } else if (value == 'Hardware / Servers / Cloud') {
      value = 'server and cloud';
    }
    const TempItSkillList = this.ItSkillList.filter((s) => s.type.toLowerCase() == value.toLowerCase());
    for (var product of TempItSkillList) {
      for (var sss of product.name) {
        this.Filterlist.push({ name: sss.value });
      }
    }
    return this.Filterlist;
  }

  filterbyType(event) {
    this.Filterlist = [];
    let value = event.target.value;
    if (value == 'Programming Languages / Frameworks') {
      value = 'programming languages';
    } else if (value == 'Database') {
      value = 'databases';
    } else if (value == 'Hardware / Servers / Cloud') {
      value = 'server and cloud';
    }
    const TempItSkillList = this.ItSkillList.filter((s) => s.type.toLowerCase() == value.toLowerCase());
    for (var product of TempItSkillList) {
      for (var sss of product.name) {
        this.Filterlist.push({ name: sss.value });
      }
    }
    return this.Filterlist;
  }


  filterbySkill(event) {
    this.SkillsFilterlist = [];
    const value = event.target.value;
    this.service.GetAll("api/v1/itskills/" + value).then(data => {
      data.forEach((value, index) => {
        this.SkillsFilterlist.push({ name: value.name[0].value })
      });
      this.SkillsFilterlist.push()
    }, err => {
      this.helper.ErrorToastr("Cannot fetch");
    })
    return this.SkillsFilterlist;
  }

  onSubmit(): void {
    if (this.itemResultList.length === 0) {
      // this.layoutUtilsService.showActionNotification(MessageType.warning, "Need to add Skills!", "You need to add minimum one skill to continue");
      return;
    }

    if (!this.isUpdated) {
      this.ref.close(true);
      return;
    }
    const data: any = {};
    data.computerSkills = this.skillList;
    data.isAdditionalCourse = true;
    try {
      this.ResumeComputerData["candidateId"] =
        this.helper.GetUserInfo().candidateId;
      this.service
        .CommonPost(
          data,
          "resume/api/additionalSkills/" +
          this.helper.GetLocalStorage("ResumeId", true)
        )
        .then((data) => {
          this.helper.SucessToastr(
            this.translate.instant('RESUME.SKILLS.MESSAGES.SUCCESS')
            //"Your skills has been updated successfully."
          );
          this.ref.close(true);
        });
    } catch {
      this.helper.ErrorToastr("Cannot update, please try again");
    }

    this.loading = true;
    this.cdr.detectChanges();
  }

  async GetITList() {
    try {
      let res = await this.service.GetAll("api/v1/getITSkills");
      this.ItSkillList = res;
    } catch {
      this.helper.ErrorToastr("Cannot fetch");
    }
  }

  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addName: ["", Validators.compose([Validators.required])],
      addType: ["", Validators.compose([Validators.required])],
      addLevel: ["", Validators.compose([Validators.required])],
      name: ["", Validators.compose([Validators.required])],
      type: ["", Validators.compose([Validators.required])],
      level: ["", Validators.compose([Validators.required])],
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addName.setValue("");
    controls.addName.markAsPristine();
    controls.addName.markAsUntouched();
    if (this.actionTab === 1) {
      controls.addType.setValue("Office Automation");
    } else {
      controls.addType.setValue("");
    }
    controls.addType.markAsPristine();
    controls.addType.markAsUntouched();
    controls.addLevel.setValue("");
    controls.addLevel.markAsPristine();
    controls.addLevel.markAsUntouched();

    this.itemForAdd = new Skill();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }

  checkAddForm() {
    const controls = this.basicForm.controls;
    if (
      controls.addName.invalid ||
      controls.addType.invalid ||
      controls.addLevel.invalid
    ) {
      controls.addName.markAsTouched();
      controls.addType.markAsTouched();
      controls.addLevel.markAsTouched();
      return false;
    }
    return true;
  }

  addItemButtonOnClick(tab) {
    this.actionTab = tab;
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    if (this.actionTab === 1) {
      this.scrollTo("add-computer");
    } else {
      this.scrollTo("add-additional");
    }
  }

  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    if (this.actionTab === 1) {
      this.scrollTo("list-computer");
    } else {
      this.scrollTo("list-additional");
    }
  }

  saveNewItem() {
    const controls = this.basicForm.controls;

    if (!this.checkAddForm()) {
      return;
    }
    this.itemForAdd._id = moment().valueOf().toString();

    this.itemForAdd.name = controls.addName.value;
    this.itemForAdd.type = controls.addType.value;
    this.itemForAdd.level = controls.addLevel.value;

    const isSkillsAdded = this.skillList.find(
      (data) => (data.name.toLowerCase() === this.itemForAdd.name.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase())
    );

    if (isSkillsAdded) {
      this.skillList.map((data) => {
        if (data.name.toLowerCase() === this.itemForAdd.name.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase()) {
          data.type = this.itemForAdd.type;
          data.level = this.itemForAdd.level;
        }
      });
    } else {
      this.skillList.push(this.itemForAdd);
    }
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.name.setValue("");
    controls.name.markAsPristine();
    controls.name.markAsUntouched();
    if (this.actionTab === 1) {
      controls.type.setValue("Office Automation");
    } else {
      controls.type.setValue("");
    }
    controls.type.markAsPristine();
    controls.type.markAsUntouched();
    controls.level.setValue("");
    controls.level.markAsPristine();
    controls.level.markAsUntouched();

    this.itemForEdit = new Skill();
    this.itemForEdit.clear();
    this.itemForEdit._isEditMode = false;
  }

  checkEditForm() {
    const controls = this.basicForm.controls;
    if (
      controls.name.invalid ||
      controls.type.invalid ||
      controls.level.invalid
    ) {
      controls.name.markAsTouched();
      controls.type.markAsTouched();
      controls.level.markAsTouched();
      return false;
    }
    return true;
  }

  editItemButtonOnClick(_item: Skill, tab, index) { 
    const controls = this.basicForm.controls;
    this.actionTab = tab;
    this.type = _item.type;
    this.filterbyTypeManually(_item.type);
    this.name = _item.name; 
    this.level = _item.level;
    this.loaditemList(index, true);
    this.isSwitchedToEditMode = true;
    if (this.actionTab === 1) {
      this.scrollTo("edit-computer");
    } else {
      this.scrollTo("edit-additional");
    }
  }

  cancelEditButtonOnClick(_item: Skill) {
    let index;
    this.itemResultList.map((data, i) => {
      if (data._isEditMode) {
        index = i;
      }
    });
    this.loaditemList(index, false);
    this.isSwitchedToEditMode = false;
    if (this.actionTab === 1) {
      this.scrollTo("list-computer");
    } else {
      this.scrollTo("list-additional");
    }
  }

  saveUpdatedItem(_item: Skill) {
    const controls = this.basicForm.controls;

    const isSkillsAdded = this.itemResultList.find(
      (data) =>
        (data.name.toLowerCase() === controls.name.value.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase() && data._isEditMode === false)
    );

    if (isSkillsAdded) {
      let newList = [];
      this.itemResultList.forEach((item, i) => {
        if (!item._isEditMode) {
          newList.push(item);
          this.isUpdated = true;
        }
      });
      this.skillList = newList;

      this.skillList.map((data, index) => {
        if (data.name.toLowerCase() === controls.name.value.toLowerCase() && data.type.toLowerCase() === controls.type.value.toLowerCase()) {
          data.type = controls.type.value;
          data.level = controls.level.value;
          this.isUpdated = true;
        }
      });
    } else {
      this.skillList.forEach((item, i) => {
        if (item._isEditMode) {
          item.name = controls.name.value;
          item.type = controls.type.value;
          item.level = controls.level.value;
          this.isUpdated = true;
        }
      });
    }
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  deleteItem(index) {
    //let _type = "delete";
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _description = this.translate.instant('RESUME.SKILLS.MESSAGES.DELETE');
    let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
    let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (!res) {
        return;
      }

      let newItem: Skill[] = [];
      this.itemResultList.forEach((item, i) => {
        if (i !== index) {
          newItem.push(item);
        }
      });
      this.isUpdated = true;
      this.skillList = newItem;
      this.loaditemList();
    });
  }
  scrollTo(id): void {
    document
      .getElementById(id)
      .scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }

  baseFilter(array, sort, dir, filter: any = {}) {
    const queryParams = new QueryParamsModel(filter, dir, sort, 0, 1000);
    const result = this.httpUtils.baseFilter(array, queryParams, []);
    return result.items;
  }

  isControlHasError(
    controlName: string,
    validationType: string = undefined
  ): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result =
        control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }
}
