
import { GeneralDetails } from './general-details.model';
import { Commitment } from './commitment.model';
import { Family } from './family.model';
import { Extra } from './extra.model';
import { SocialLink } from './social-link.model';

export class Social {
  _id: string;
  createdAt: string;
  modifiedAt: string;
  completePercentage: any[];
  availableLanguage: string[];
  uid: string;
  __v: number;

  socialLinks: SocialLink[];
  general: GeneralDetails;
  location: {
    country: string,
    state: string,
    city: string,
    addressLine1: string,
    addressLine2: string,
    houseType: string,
    yearOfLiving: number,
    noOfPeople: number
  };
  family: Family[]
  recognitions: Extra[];
  hobbies: Extra[];
  religion: {
    religiondesc: string
  }
  commitments: Commitment[];

  clear(): void {
    this._id = undefined;
    this.createdAt = '';
    this.modifiedAt = '';
    this.completePercentage = [];
    this.availableLanguage = [];
    this.uid = undefined;
    this.__v = 0;

    this.socialLinks = [];
    this.general = new GeneralDetails();
    this.general.clear();
    this.location = {
      country: '',
      state: '',
      city: '',
      addressLine1: '',
      addressLine2: '',
      houseType: 'own house',
      yearOfLiving: undefined,
      noOfPeople: undefined
    };
    this.family = []
    this.recognitions = [];
    this.hobbies = [];
    this.religion = {
      religiondesc: ''
    }
    this.commitments = [];
  }
}
