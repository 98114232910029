export class Extra {
    id: string;
    title: string;
    description: string;
    _isEditMode: boolean;

    clear(): void {
        this.id = undefined;
        this.title = '';
        this.description = '';
        this._isEditMode = false;
    }
}

