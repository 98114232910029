import { Component, OnInit, HostListener, AfterViewInit, Renderer2 } from '@angular/core';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Router } from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS
} from "@angular/material/core";
import { CommonService } from 'src/Service/Common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  timeoutId;
  userInactive: Subject<any> = new Subject();

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity() {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(
      () => this.userInactive.next("User has been inactive for 15 minutes"), 900000
    );
  }

  title = 'Candidate Listo Para Trabajar';
  constructor(
    public helper: CommonHelper,
    private service: CommonService,
    public translate: TranslateService,
    private _adapter: DateAdapter<any>,
    public router: Router,
    private renderer: Renderer2) {
    translate.addLangs(['en', 'es']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
    let checkUser = localStorage.getItem('User');
    if (!checkUser) {
      this.changeLang(null, 'es');
    }

    this.checkTimeOut();
    this.userInactive.subscribe(async (message) => {
      let x = await this.service.CommonGetParam("/auth/logout/" + this.helper.GetUserInfo().candidateId).toPromise();
      localStorage.removeItem('User');
      localStorage.clear();
      this.router.navigate(["/Login"]);
    }
    );
  }
  ngAfterViewInit(): void {
    let loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  ngOnInit() {
    ``
  }

  changeLang(event, language) {
    localStorage.setItem('locale', language);
    this._adapter.setLocale(language);
    this.translate.use(language);
  }
}