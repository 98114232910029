export class Language {
    _id: string;
    name: any[];
    spoken: string;
    written: string;
    levelValue: number;
    _isEditMode: boolean;

    clear(): void {
        this._id = undefined;
        this.name = [];
        this.spoken = '';
        this.written = '';
        this.levelValue = 0;
        this._isEditMode = false;
    }
}
