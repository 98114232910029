<form class="form" *ngIf="basicForm" [formGroup]="basicForm">

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.NATIONALITY' | translate}}</label>
    <div class="col-lg-6 col-xl-4">
      <p-multiSelect [options]="countryList" display="chip" formControlName="nationality" optionLabel="name" placeholder="{{'SOCIAL.GENERAL.FIELD.NATIONALITY_INPUT' | translate}}" aria-placeholder="{{'SOCIAL.GENERAL.FIELD.NATIONALITY' | translate}}"  [(ngModel)]="socialDetails.general.nationality"></p-multiSelect>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('nationality','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.NATIONALITY_REQUIRED' | translate}}</span>
        </div>
      </div>


    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.ID_NUMBER' | translate}}:</label>
    <div class="col-lg-4 col-xl-4">
      <input type="text" class="form-control form-control-lg form-control-solid" placeholder="{{'SOCIAL.GENERAL.FIELD.ID_NUMBER_INPUT' | translate}}"
        formControlName="idCardType" list="idType" maxlength="40" />
      <datalist id="idType">
        <option value="Cedula">
        <option value="CURP">
        <option value="DNI">
        <option value="DPI">
        <option value="DUI">
        <option value="CIP">
        <option value="CIC">
        <option value="Passport">
        <option value="ID Card">
      </datalist>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('idCardType','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.ID_NUMBER_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4">
      <input formControlName="idCard" placeholder="{{'SOCIAL.GENERAL.FIELD.ID_NUMBER' | translate}}"
        class="form-control form-control-lg form-control-solid" type="text">
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('idCard','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.ID_NUMBER' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.NIT_NUMBER' | translate}}</label>
    <div class="col-lg-4 col-xl-4">
      <input type="text" class="form-control form-control-lg form-control-solid" placeholder="{{'SOCIAL.GENERAL.FIELD.NIT_NUMBER_INPUT' | translate}}"
        formControlName="nitNumber" maxlength="40" />
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('nitNumber','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.NIT_NUMBER_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.BLOOD_GROUP' | translate}}</label>
    <div class="col-lg-6 col-xl-4">
      <select formControlName="bloodGroup" class="form-control form-control-lg form-control-solid">
        <option value="">{{'SOCIAL.GENERAL.FIELD.BLOOD_GROUP_INPUT' | translate}}</option>
        <option value="o-">O-</option>
        <option value="o+">O+</option>
        <option value="a-">A-</option>
        <option value="a+">A+</option>
        <option value="b-">B-</option>
        <option value="b+">B+</option>
        <option value="ab-">AB-</option>
        <option value="ab+">AB+</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('bloodGroup','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.BLOOD_GROUP_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.MARITAL_STATUS' | translate}}</label>
    <div class="col-lg-6 col-xl-4">
      <select formControlName="maritalStatus" class="form-control form-control-lg form-control-solid">
        <option value="">{{'SOCIAL.GENERAL.FIELD.MARITAL_STATUS_INPUT' | translate}}</option>
        <option value="single">{{'SOCIAL.GENERAL.MARITAL_STATUS.SINGLE' | translate}}</option>
        <option value="united">{{'SOCIAL.GENERAL.MARITAL_STATUS.COHABITANTS' | translate}}</option>
        <option value="married">{{'SOCIAL.GENERAL.MARITAL_STATUS.MARRIED' | translate}}</option>
        <option value="separated">{{'SOCIAL.GENERAL.MARITAL_STATUS.SEPARATED' | translate}}</option>
        <option value="divorced">{{'SOCIAL.GENERAL.MARITAL_STATUS.DIVORCED' | translate}}</option>
        <option value="widower">{{'SOCIAL.GENERAL.MARITAL_STATUS.WIDOWER' | translate}}</option>
      </select>
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('maritalStatus','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.MARITAL_STATUS_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.VISA_PERMIT' | translate}}</label>
    <div class="col-lg-6 col-xl-4">
      <!-- <select id="kt_visa" formControlName="visaPermit" class="form-control select2 form-control-lg form-control-solid">
          <option *ngFor="let country of countryList" [value]="country.isoCode">{{country.name}}</option>
        </select> -->
      <!-- <select id="kt_visa" formControlName="visaPermit" class="form-control select2 form-control-lg form-control-solid">
            <option *ngFor="let country of countryList" [value]="country.name">{{country.name}}</option>
          </select> -->
      <p-multiSelect [options]="countryList" display="chip"  formControlName="visaPermit" placeholder="{{'SOCIAL.GENERAL.FIELD.VISA_PERMIT_INPUT' | translate}}" 
      optionLabel="name" [(ngModel)]="socialDetails.general.visaPermit" (onClick)="socialDetails.general.visaPermit = elm.value" 
      #elm></p-multiSelect>      
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.SOCIAL_S_NUMBER' | translate}}</label>
    <div class="col-lg-4 col-xl-4">
      <input type="text" class="form-control form-control-lg form-control-solid" placeholder="{{'SOCIAL.GENERAL.FIELD.SOCIAL_S_TYPE_INPUT' | translate}}"
        formControlName="ssnType" list="idType" maxlength="40" />
      <datalist id="idType">
        <option value="Cedula">
        <option value="CURP">
        <option value="DNI, DPI, DUI, CIP, CIC">
        <option value="Passport">
        <option value="ID Card">
      </datalist>
      
    </div>
    <div class="col-lg-4 col-xl-4">
      <input formControlName="ssNumber" placeholder="{{'SOCIAL.GENERAL.FIELD.SOCIAL_S_NUMBER_INPUT' | translate}}"
        class="form-control form-control-lg form-control-solid" type="text">
      
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.RELIGION' | translate}}</label>
    <div class=" col-lg-4 col-xl-4">
      <div class="input-group input-group-lg input-group-solid">
        <input class="form-control form-control-solid" formControlName="religion" placeholder="{{'SOCIAL.GENERAL.FIELD.RELIGION_INPUT' | translate}}"
          list="religionList" />
          <datalist  id="religionList">
            <!---->
            <option  value="African traditional religions" >African traditional religions</option>
            <option  value="Animism" >Animism</option>
            <option  value="Baháʼí" >Baháʼí</option>
            <option  value="Buddhism" >Buddhism</option>
            <option  value="Cao Dai" >Cao Dai</option>
            <option  value="Chinese traditional religion" >Chinese traditional religion</option>
            <option  value="Christianity" >Christianity</option>
            <option  value="Ethnic religions" >Ethnic religions</option>
            <option  value="Hinduism" >Hinduism</option>
            <option  value="Islam" >Islam</option>
            <option  value="Jainism" >Jainism</option>
            <option  value="Judaism" >Judaism</option>
            <option  value="Neo-Paganism" >Neo-Paganism</option>
            <option  value="Rastafari" >Rastafari</option>
            <option  value="Secular/Nonreligious/Agnostic/Atheist" >Secular/Nonreligious/Agnostic/Atheist</option>
            <option  value="Shinto" >Shinto</option>
            <option  value="Sikhism" >Sikhism</option>
            <option  value="Spiritism" >Spiritism</option>
            <option  value="Tenrikyo" >Tenrikyo</option>
            <option  value="Unitarian Universalism" >Unitarian</option>
            <option  value="Zoroastrianism">Zoroastrianism</option>
         </datalist>
      </div>
      
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('religion','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.RELIGION_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.NO_OF_DEPENDENTS' | translate}}</label>
    <div class="col-lg-4 col-xl-4">
      <input formControlName="dependentsNumber" class="form-control form-control-lg form-control-solid" type="number"
        min="0">
      <div class="fv-plugins-message-container" *ngIf="isControlHasError('dependentsNumber','required')">
        <div class="fv-help-block">
          <span>{{'SOCIAL.GENERAL.FIELD.NO_OF_DEPENDENTS_REQUIRED' | translate}}</span>
        </div>
      </div>
    </div>
  </div>

  <!--begin::Form Group-->
  <div class="separator separator-dashed my-5"></div>
  <!--begin::Form Group-->
  <div class="row">
    <label class="col-xl-3"></label>
  </div>
  <!--begin::Form Group-->

  <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{'SOCIAL.GENERAL.FIELD.OWN_VEHICLE' | translate}}</label>
    <div class="col-lg-9 col-xl-3 col-form-label">
      <div class="custom-switch">
        <span class="mr-2">{{'SOCIAL.GENERAL.FIELD.NO' | translate}}</span>
        <input type="checkbox" id="haveVehicle" formControlName="haveVehicle" class="custom-control-input">
        <label class="custom-control-label" for="haveVehicle" style="margin-left: 40px;"></label>
        <span class="ml-2">{{'SOCIAL.GENERAL.FIELD.YES' | translate}}</span>
      </div>
    </div>
    <div class="fv-plugins-message-container" *ngIf="isVehicleError">
      <div class="fv-help-block">        
        <span *ngIf="language == 'en'">Vehicle Required</span>
        <span *ngIf="language == 'es'">Vehículo requerido</span>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="basicForm && basicForm.get('haveVehicle').value">
    <div class="col-lg-9 col-xl-6">
      <h5 class="font-weight-bold mb-6">{{'SOCIAL.GENERAL.FIELD.VEHICLE_DETAILS' | translate}}</h5>
    </div>
  </div>
</form>

<ng-container *ngIf="basicForm && basicForm.get('haveVehicle').value">
  <div class="form-group row" *ngFor="let vehicle of vehicleList">
    <div class="col-lg-3 col-xl-3">
      <label>{{'SOCIAL.GENERAL.FIELD.VEHICLE' | translate}} {{'SOCIAL.GENERAL.FIELD.TYPE' | translate}}</label>
      <!-- <input class="form-control form-control-sm form-control-solid" type="text" [placeholder]="'SOCIAL.GENERAL.FIELD.TYPE' | translate"> -->
      <select [(ngModel)]="vehicle.type" class="form-control form-control-sm form-control-solid">
        <option value="Motorbike">{{'SOCIAL.GENERAL.FIELD.MOTEROBIKE' | translate}}</option>
        <option value="Car">{{'SOCIAL.GENERAL.FIELD.CAR' | translate}}</option>
      </select>
    </div>
    <div class="col-lg-3 col-xl-3">
      <label>{{'SOCIAL.GENERAL.FIELD.MAKE' | translate}}</label>
      <input [(ngModel)]="vehicle.make" class="form-control form-control-sm form-control-solid" type="text"
        placeholder="{{'SOCIAL.GENERAL.FIELD.MAKE' | translate}}">
      <datalist [id]="vehicle.id+'-make'">
        <option value="Make1">{{'SOCIAL.GENERAL.FIELD.MAKE' | translate}}s</option>
        <option value="Make 2">{{'SOCIAL.GENERAL.FIELD.MAKE' | translate}} 2</option>
      </datalist>
    </div>
    <div class="col-lg-3 col-xl-3">
      <label>{{'SOCIAL.GENERAL.FIELD.MODEL' | translate}}</label>
      <input [(ngModel)]="vehicle.model" class="form-control form-control-sm form-control-solid" type="text"
        placeholder="{{'SOCIAL.GENERAL.FIELD.MODEL' | translate}}">
      <datalist [id]="vehicle.id+'-model'">
        <option value="Model 1">{{'SOCIAL.GENERAL.FIELD.MODEL' | translate}} 1</option>
        <option value="Model 2">{{'SOCIAL.GENERAL.FIELD.MODEL' | translate}} 2</option>
      </datalist>
    </div>
    <div class="col-lg-2 col-xl-2">
      <label>{{'SOCIAL.GENERAL.FIELD.YEAR' | translate}}</label>
      <select [(ngModel)]="vehicle.year" class="form-control form-control-sm form-control-solid">
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
        <option value="2016">2016</option>
        <option value="2015">2015</option>
        <option value="2014">2014</option>
        <option value="2013">2013</option>
        <option value="2012">2012</option>
        <option value="2011">2011</option>
        <option value="2010">2010</option>
        <option value="2009">2009</option>
        <option value="2008">2008</option>
        <option value="2007">2007</option>
        <option value="2006">2006</option>
        <option value="2005">2005</option>
        <option value="2004">2004</option>
        <option value="2003">2003</option>
        <option value="2002">2002</option>
        <option value="2001">2001</option>
        <option value="2000">2000</option>
        <option value="1999">1999</option>
        <option value="1998">1998</option>
        <option value="1997">1997</option>
        <option value="1996">1996</option>
        <option value="1995">1995</option>
        <option value="1994">1994</option>
        <option value="1993">1993</option>
        <option value="1992">1992</option>
        <option value="1991">1991</option>
        <option value="1990">1990</option>
        <option value="1989">1989</option>
        <option value="1988">1988</option>
        <option value="1987">1987</option>
        <option value="1986">1986</option>
        <option value="1985">1985</option>
        <option value="1984">1984</option>
        <option value="1983">1983</option>
        <option value="1982">1982</option>
        <option value="1981">1981</option>
        <option value="1980">1980</option>
        <option value="1979">1979</option>
        <option value="1978">1978</option>
        <option value="1977">1977</option>
        <option value="1976">1976</option>
        <option value="1975">1975</option>
        <option value="1974">1974</option>
        <option value="1973">1973</option>
        <option value="1972">1972</option>
        <option value="1971">1971</option>
        <option value="1970">1970</option>
      </select>
    </div>
    <div class="col-lg-1 col-xl-1">
      <button (click)="deleteVehicle(vehicle)" class="mt-8 btn btn-sm font-weight-bolder btn-light-danger btn-icon">
        <i class="la la-trash"></i>
      </button>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-4 col-xl-4">
      <button (click)="addVehicle()" class="btn btn-sm font-weight-bolder btn-light-primary">
        <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
    </div>
  </div>
</ng-container>

<!--begin: Wizard Actions-->
<div class="d-flex justify-content-between border-top mt-5 pt-10">
  <div class="mr-2">
    <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
  </div>
  <div>
    <ng-container *ngIf="loading">
      <span class="spinner spinner-primary mr-10"></span>
    </ng-container>
    <button type="button" (click)="onSubmit()" class="btn btn-primary font-weight-bolder text-uppercase">{{'BUTTON.SAVE_EXIT' | translate}}</button>
  </div>
</div>
<!--end: Wizard Actions-->