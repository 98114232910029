import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { QueryParamsModel } from "src/app/core/model/query-models/query-params.model";
import { HttpUtilsService } from "src/app/core/utils/http-utils.service";
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";
import { MultiSelectModule } from "primeng/multiselect";

//Moment
import * as moment from "moment";

declare var $: any;
import { Social } from "../../../../core/socialprofile/social.model";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-spgeneral",
  templateUrl: "./spgeneral.component.html",
  styleUrls: ["./spgeneral.component.css"],
})
export class SpgeneralComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;
  selectedNationality: any[] = []
  vehicleList: any[] = [];
  countryList: any = [];
  language: string = "en";
  isVehicleError: boolean = false;


  // Subscriptions
  private subscriptions: Subscription[] = [];
  socialDetails: Social = new Social();
  constructor(
    public fb: FormBuilder,
    private httpUtils: HttpUtilsService,
    private cdr: ChangeDetectorRef,
    private helper: CommonHelper,
    private service: CommonService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((res) => {
      this.language = localStorage.getItem("locale");
    })
    this.socialDetails.clear();
    if (this.config.data && this.config.data.socialprofile && this.config.data.socialprofile.general) {
      this.socialDetails = this.config.data.socialprofile;
      if (this.socialDetails.general.vehicles && this.socialDetails.general.vehicles.length > 0) {
        this.socialDetails.general.vehicles.map((data, index) => {
          this.vehicleList.push({ ...data, id: index })
        });
      }
    }
    this.GetCountryList();
    this.initForm();
  }



  GetCountryList() {
    this.service.GetAll("api/v1/allcountry").then(
      (res) => {
        this.countryList = res.sort((a, b) => a.name.localeCompare(b.name));
        for (let index = 0; index < this.countryList.length; index++) {
          const element = this.countryList[index];
          let flag: boolean = false;
          if (element.name.toLocaleLowerCase() == "united states") {
            element.name += " (USA)";
            flag = true;
          }
          else if (element.name.toLocaleLowerCase() == "united kingdom") {
            element.name += " (UK)";
            flag = true;
          }
          else if (element.name.toLocaleLowerCase() == "united arab emirates") {
            element.name += " (UAE)";
            flag = true;
          }
        }
        if (this.socialDetails.general.nationality.length > 0) {
          let natioanlities = [];
          this.socialDetails.general.nationality.map(data => {
            natioanlities.push(this.countryList.find(cData => cData.name === data))
          });
          this.socialDetails.general.nationality = natioanlities;
        }

        if (this.socialDetails.general.visaPermit.length > 0) {
          let visaPermit = [];
          this.socialDetails.general.visaPermit.map(data => {
            visaPermit.push(this.countryList.find(cData => cData.name === data))
          });
          this.socialDetails.general.visaPermit = visaPermit;
        }
      },
      (err) => {
        this.helper.ErrorToastr("Cannot fetch");
      }
    );
  }
  public onVisaChange(event) {
    this.socialDetails.general.visaPermit  = event.value;
  }
  async onSubmit() {
    const controls = this.basicForm.controls;
    ///check vehicle control has value or not
    //if has value true -> check vehicles count
    //else go
    let x = controls['haveVehicle'].value;
    if (x) {
      if (this.vehicleList.length <= 0) {
        this.isVehicleError = true;
        return;
      }
    }

    ///
    // check form
    if (this.basicForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    if (!this.basicForm.errors) {
      //  this.stepCount$.next(3);
      let nationality = [],
        visaPermit = [];
      this.basicForm.value.nationality.map((data) => {
        if (data) {
          nationality.push(data.name);
        }
      });
      this.basicForm.value.visaPermit.map((data) => {
        if (data) {
          visaPermit.push(data.name);
        }
      });
      const res = {
        ...this.basicForm.value,
        vehicles: this.getCompletedVehicle(),
        visaPermit,
        nationality,
        candidateId: this.helper.GetUserInfo().candidateId,
        socialProfileId: this.config.data.socialprofile && this.config.data.socialprofile.general
          ? this.config.data.socialprofile.general.socialProfileId
          : null,
      }
      this.loading = true;

      let finalRes = await this.service
        .CommonPostObservable(res, "socialprofile/api/general")
        .subscribe((data) => {
          this.loading = false;
          this.ref.close(true);
        }, err => {
          this.helper.ErrorToastr("Cannot Update! Please retry");
        });
      return;
    }

    this.cdr.detectChanges();
  }

  initForm() {
    this.basicForm = this.fb.group({
      nationality: [
        this.socialDetails?.general?.nationality,
        Validators.compose([Validators.required]),
      ],
      idCardType: [
        this.socialDetails?.general?.idCardType,
        Validators.compose([Validators.required]),
      ],
      idCard: [
        this.socialDetails?.general?.idCard,
        Validators.compose([Validators.required]),
      ],
      dependentsNumber: [
        this.socialDetails?.general?.dependentsNumber,
        Validators.compose([Validators.required]),
      ],
      nitNumber: [
        this.socialDetails?.general?.nitNumber,
        Validators.compose([Validators.required]),
      ],
      bloodGroup: [
        this.socialDetails?.general?.bloodGroup,
        Validators.compose([Validators.required]),
      ],
      visaPermit: [
        this.socialDetails?.general?.visaPermit,
        Validators.compose([]),
      ],
      ssnType: [
        this.socialDetails?.general?.ssnType,
        Validators.compose([]),
      ],
      ssNumber: [
        this.socialDetails?.general?.ssNumber,
        Validators.compose([]),
      ],
      religion: [this.socialDetails?.general?.religion, Validators.compose([Validators.required])],
      haveVehicle: [
        this.socialDetails?.general?.haveVehicle,
        Validators.compose([]),
      ],
      maritalStatus: [
        this.socialDetails?.general?.maritalStatus,
        Validators.compose([Validators.required]),
      ],
    });
    console.log("Log : " + this.basicForm);
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  prevStep() {
    // this.stepCount$.next(1);
  }

  baseFilter(array, sort, dir, filter: any = {}) {
    const queryParams = new QueryParamsModel(filter, dir, sort, 0, 1000);
    const result = this.httpUtils.baseFilter(array, queryParams, []);
    return result.items;
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result =
        control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  addVehicle() {
    this.isVehicleError = false;
    this.isUpdated = true;
    let newVehicle: any = {
      id: moment().valueOf(),
      type: "Car",
      make: "",
      model: "",
      year: "2021",
    };
    this.vehicleList.push(newVehicle);
    this.cdr.detectChanges();
  }

  deleteVehicle(vehicle) {
    this.isUpdated = true;
    let newList = [];
    this.vehicleList.map((item, index) => {
      if (item.id === vehicle.id) {
        this.vehicleList.splice(index, 1);
      }
    });
    this.cdr.detectChanges();
  }

  getCompletedVehicle() {
    const controls = this.basicForm.controls;
    let newList = [];
    this.vehicleList.forEach((item) => {
      if (item.make && item.model) {
        var vehitem = item;
        delete vehitem.id;
        newList.push(vehitem);
      }
    });
    if (controls.haveVehicle.value) {
      return newList;
    }
    return [];
  }
}
