import { Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CommonHelper } from "./CommonHelper";
import { DecimalPipe } from "@angular/common";

@Injectable()
export class AlphaInterceptor implements HttpInterceptor {
  constructor(private helper: CommonHelper) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = "";
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next.handle(request).pipe(map((val: HttpEvent<any>) => {
      if (val instanceof HttpResponse) {
        return val;
      }
    }));
  }
}
