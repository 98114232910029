import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Language} from '../../core/resume/model/Language.module';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {LayoutserviceService} from '../../core/utils/layoutservice.service';
import {ConfirmationService, PrimeNGConfig} from 'primeng/api';
import * as moment from 'moment';
import { CommonService } from 'src/Service/Common.service';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { QueryParamsModel } from 'src/app/core/model/query-models/query-params.model';
import { HttpUtilsService } from 'src/app/core/utils/http-utils.service';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;
  ResumeLanguageData: any = {};
  isSwitchedToEditMode = false;
  itemForEdit: Language;
  @Input() wizardsetup: number;
  @Input() isPopup: boolean;
  
  itemForAdd: Language;
  language: string = 'en';
  levelList: any[] = [
    {
      level: 'basic',
      levelValue: 1
    },
    {
      level: 'intermediate',
      levelValue: 2
    },
    {
      level: 'advanced',
      levelValue: 3
    },
    {
      level: 'native',
      levelValue: 4
    },
  ];
  itemResultList: Language[] = [];
  private GetValueLevel(input: string): number {
    if (input == "basic")
      return 1;
    else if (input == "intermediate")
      return 2;
    if (input == "advanced")
      return 3;
    if (input == "native")
      return 4;

  }
  languageList: Language[] = [];
  private subscriptions: Subscription[] = [];
  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef,private helper: CommonHelper,
    private service: CommonService,public fb: FormBuilder,private primengConfig: PrimeNGConfig, 
    private confirmationService: ConfirmationService,private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutserviceService,private httpUtils: HttpUtilsService) { }

  ngOnInit(): void {
   
    this.ResumeLanguageData = this.config.data;
    
    this.languageList = this.ResumeLanguageData;
    this.primengConfig.ripple = true;
    this.createFormGroup();
    this.loaditemList();
    if (this.languageList.length === 0) {
      this.addItemButtonOnClick();
    }else {
      this.cancelAddButtonOnClick();
    }
  }
  confirm2() {
    this.confirmationService.confirm({
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          //  this.msgs = [{severity:'info', summary:'Confirmed', detail:'Record deleted'}];
        },
        reject: () => {
        //    this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
        }
    });
}

onSubmit(){
  if ((this.itemResultList.length === 0)) {
    this.helper.ErrorToastr("You need to add minimum one language to continue.");
  //  this.layoutUtilsService.showActionNotification(MessageType.warning, "Need to add language!", "You need to add minimum one language to continue");
    return;
  }
  if (!this.isUpdated) {
    this.wizardsetup=5;
    return;
  }
  this.loading = true;
  this.cdr.detectChanges();
  const data: any = {};
    data._id = this.ResumeLanguageData?._id;
    data.type = "languages";
    data.language = this.language;
    data.languages = this.languageList;
    try {
    this.ResumeLanguageData["candidateId"] = this.helper.GetUserInfo().candidateId;
    this.service.CommonPost(data.languages, "resume/api/language/" + this.helper.GetLocalStorage("ResumeId", false));
    this.helper.SucessToastr("Your language has been updated successfully.");
    if(this.isPopup==false){
      this.wizardsetup=this.wizardsetup+1;
    }else {
    this.ref.close(true);
    }
    } catch
    {
        this.helper.ErrorToastr("Cannot update, please try again");
    }
    this.cdr.detectChanges();
    this.subscriptions.push(data);
}
checkAddForm() {
  const controls = this.basicForm.controls;
  if (controls.addLanguage.invalid || controls.addSpoken.invalid || controls.addWritten.invalid) {
    controls.addLanguage.markAsTouched();
    controls.addSpoken.markAsTouched();
    controls.addWritten.markAsTouched();
    return false;
  }
  return true;
}
saveNewItem() {
  const controls = this.basicForm.controls;

  if (!this.checkAddForm()) {
    return;
  }
  this.itemForAdd._id = moment().valueOf().toString();

  this.itemForAdd.name = controls.addLanguage.value;
  this.itemForAdd.spoken = controls.addSpoken.value;
  this.itemForAdd.written = controls.addWritten.value;

  this.languageList.push(this.itemForAdd);
  this.isSwitchedToEditMode = false;
  this.isUpdated = true;
  this.clearAddForm();
  this.loaditemList();
}
scrollTo(id): void {
  document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
}
  addItemButtonOnClick() {
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo('add-language');
  }
  checkEditForm() {
    const controls = this.basicForm.controls;
    if (controls.language.invalid || controls.spoken.invalid || controls.written.invalid) {
      controls.language.markAsTouched();
      controls.spoken.markAsTouched();
      controls.written.markAsTouched();
      return false;
    }
    return true;
  }
  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addLanguage: ['', Validators.compose([Validators.required])],
      addSpoken: ['', Validators.compose([Validators.required])],
      addWritten: ['', Validators.compose([Validators.required])],
      language: ['', Validators.compose([Validators.required])],
      spoken: ['', Validators.compose([Validators.required])],
      written: ['', Validators.compose([Validators.required])],
    });
    this.clearAddForm();
    this.clearEditForm();
  }
  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.language.setValue('');
    controls.language.markAsPristine();
    controls.language.markAsUntouched();
    controls.spoken.setValue('');
    controls.spoken.markAsPristine();
    controls.spoken.markAsUntouched();
    controls.written.setValue('');
    controls.written.markAsPristine();
    controls.written.markAsUntouched();

    this.itemForEdit = new Language();
    this.itemForEdit.clear();
    this.itemForEdit._isEditMode = false;
  }
  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addLanguage.setValue('');
    controls.addLanguage.markAsPristine();
    controls.addLanguage.markAsUntouched();
    controls.addSpoken.setValue('');
    controls.addSpoken.markAsPristine();
    controls.addSpoken.markAsUntouched();
    controls.addWritten.setValue('');
    controls.addWritten.markAsPristine();
    controls.addWritten.markAsUntouched();

    this.itemForAdd = new Language();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }
  deleteItem(_item: Language) {
    let newItem: Language[] = [];
      this.itemResultList.forEach(item => {
        if (item._id !== _item._id) {
          newItem.push(item);
        }
      })
      this.isUpdated = true;
      this.languageList = newItem;
      this.loaditemList();
  }
  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
  }
  editItemButtonOnClick(_item: Language) {
    const controls = this.basicForm.controls;

    controls.language.setValue(_item.name);
    controls.spoken.setValue(_item.spoken);
    controls.written.setValue(_item.written);
    this.loaditemList(_item._id, true);
    this.isSwitchedToEditMode = true;
    this.scrollTo('edit-language');
  }
  saveUpdatedItem(_item: Language) {
    if (!this.checkEditForm()) {
      return;
    }

    const controls = this.basicForm.controls;

    this.languageList.forEach(item => {
      if (item._id === _item._id) {
        item.name = controls.language.value;
        item.spoken = controls.spoken.value;
        item.written = controls.written.value;
      }
    });
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }
  cancelEditButtonOnClick(_item: Language) {
    this.loaditemList(_item._id, false);
    this.isSwitchedToEditMode = false;
    this.scrollTo('list-language');
  }
  baseFilter(array, sort, dir, filter: any = {}) {
    const queryParams = new QueryParamsModel(filter, dir, sort, 0, 100);
    const result = this.httpUtils.baseFilter(array, queryParams, []);
    return result.items;
  }
  loaditemList(id: string = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.languageList.forEach(item => {
      let newItem = new Language();
      newItem.clear();
      newItem._id = item._id;
      newItem.name = item.name;
      newItem.spoken = item.spoken;
      newItem.written = item.written;
      newItem.levelValue = this.GetValueLevel(item.spoken);
      newItem._isEditMode = false;
      if (item._id === id) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.languageList =  this.baseFilter(this.itemResultList, 'levelValue', 'desc');
    this.cdr.detectChanges();
  }
  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }
}
