import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { LayoutserviceService } from "src/app/core/utils/layoutservice.service";
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";

@Component({
  selector: "app-sociallinks",
  templateUrl: "./sociallinks.component.html",
  styleUrls: ["./sociallinks.component.css"],
})
export class SociallinksComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    public service: CommonService,
    public helper: CommonHelper,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService
  ) {}
  loading: boolean;
  submitted: boolean;
  isAddOrEditShow: boolean;
  basicForm: FormGroup;
  socialLinks: any[] = [];
  editId: any;
  iconList: any[] = [
    { icon: "google", name: "Google", sampleUrl: "https://www.google.com/" },
    {
      icon: "facebook",
      name: "Facebook",
      sampleUrl: "https://www.facebook.com/",
    },
    {
      icon: "linkedin",
      name: "LinkedIn",
      sampleUrl: "https://www.linkedin.com/",
    },
    {
      icon: "instagram",
      name: "Instagram",
      sampleUrl: "https://www.instagram.com/",
    },
    { icon: "twitter", name: "Twitter", sampleUrl: "https://twitter.com/" },
    { icon: "youtube", name: "Youtube", sampleUrl: "https://www.youtube.com/" },
    { icon: "telegram", name: "Telegram", sampleUrl: "https://telegram.org/" },
    {
      icon: "whatsapp",
      name: "Whatsapp",
      sampleUrl: "https://www.whatsapp.com/",
    },
    { icon: "skype", name: "Skype", sampleUrl: "https://www.skype.com/" },
    {
      icon: "google-plus",
      name: "Google Plus",
      sampleUrl: "https://www.google.com/",
    },
    { icon: "dropbox", name: "Dropbox", sampleUrl: "https://www.dropbox.com/" },
    { icon: "github", name: "Github", sampleUrl: "https://github.com/" },
    {
      icon: "pinterest",
      name: "Pinterest",
      sampleUrl: "https://pinterest.com/",
    },
    {
      icon: "github",
      name: "Bit Bucket",
      sampleUrl: "https://bitbucket.org/",
    },
    {
      icon: "snapchat",
      name: "Snapchat",
      sampleUrl: "https://www.snapchat.com/",
    },
    { icon: "yahoo", name: "Yahoo", sampleUrl: "https://yahoo.com/" },
    { icon: "amazon", name: "Amazon", sampleUrl: "https://www.amazon.com/" },
    { icon: "xbox", name: "Codepen", sampleUrl: "https://codepen.io/" },
    { icon: "behance", name: "Behance", sampleUrl: "https://www.behance.net/" },
    { icon: "github", name: "Gitlab", sampleUrl: "https://gitlab.com/" },
    { icon: "wechat", name: "Wechat", sampleUrl: "https://www.wechat.com/" },
    {
      icon: "wikipedia",
      name: "Wikipedia",
      sampleUrl: "https://www.wikipedia.org/",
    },
  ];
  ngOnInit(): void {
    if(this.config.data && this.config.data.socialLinks){
      this.socialLinks = this.config.data.socialLinks
    }
    this.createSocialLink();
  }

  addSocialLink() {
    this.isAddOrEditShow = true;
  }
  get f() {
    return this.basicForm.controls;
  }
  createSocialLink() {
    this.basicForm = this.fb.group({
      type: ["", Validators.required],
      title: ["", Validators.required],
      custom: [false, Validators.required],
      url: ["https://", [Validators.required, Validators.pattern]],
    });
  }
  editSocialLink(data) {
    this.editId = data.id;
    this.basicForm = this.fb.group({
      type: [data.type, Validators.required],
      title: [data.title, Validators.required],
      custom: [data.custom, Validators.required],
      url: [data.url, [Validators.required, Validators.pattern]],
    });
  }
  add() {
    this.submitted = true;
    const controls = this.basicForm.controls;
    if (controls.custom.value) {
      if (controls.title.invalid || controls.url.invalid) {
        controls.title.markAsTouched();
        controls.url.markAsTouched();
        return false;
      }
      this.addAndResetForm();
      return true;
    } else {
      if (controls.type.invalid || controls.url.invalid) {
        controls.type.markAsTouched();
        controls.url.markAsTouched();
        return false;
      }
      this.addAndResetForm();
      return true;
    }
  }

  addAndResetForm() {
    const controls = this.basicForm.controls;
    if (!this.editId) {
      this.socialLinks.push({
        ...this.basicForm.value,
        id: moment().valueOf(),
        type: this.basicForm.value.custom ? "" : this.basicForm.value.type,
      });
    } else {
      this.socialLinks = this.socialLinks.map((data) => {
        if (data.id === this.editId) {
          return {
            ...data,
            ...this.basicForm.value,
            type: this.basicForm.value.custom ? "" : this.basicForm.value.type,
          };
        }
        return data;
      });
    }
    this.createSocialLink();
    this.submitted = false;
    this.editId = null;
    this.close();
  }
  edit(data) {
    this.isAddOrEditShow = true;
    this.editSocialLink(data);
  }

  delete(deleteData) {
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _description = this.translate.instant('SOCIAL.SOCIAL_LINKS.MESSAGES.DELETE');
      let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
      let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (res) {
    this.socialLinks.map((data, index) => {
      if (data.id === deleteData.id) {
        this.socialLinks.splice(index, 1);
      }
    });
  }
})
  }
  close() {
    this.isAddOrEditShow = false;
    this.editId = null;
  }
  onSubmit() {
    this.loading = true;
    this.service
      .CommonPostObservable(
        this.socialLinks,
        "/socialprofile/api/socialLink/" +
          this.config.data.general.socialProfileId
      )
      .subscribe((data) => {
this.ref.close(true);
this.loading = false;
      },err=>{
        this.loading = true;
        this.helper.ErrorToastr('Cannot update try again');
      });
  }
}
