import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './Shared/layout/layout.component';
import { AuthGuard } from '../Helper/AuthGuard';
import { AccountComponent } from './account/account.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { VerifyComponent } from './SignUp/verify/verify.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ToastCalendarComponent } from './toast-calendar/toast-calendar.component';
import { VerifycandidateComponent } from './verifycandidate/verifycandidate.component';
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
};

const routes: Routes = [
  { path: "", redirectTo: "/Login", pathMatch: "full" },
  { path: "home", loadChildren: () => import('./home/home.module').then(o => o.HomeModule) },
  { path: "Login", loadChildren: () => import('./Signin/signin').then(o => o.SigninModule) },
  { path: "auth-browser", loadChildren: () => import('./authenticate-browser/authenticate-browser.module').then(o => o.AuthenticateBrowserModule) },
  { path: "documents", component: LayoutComponent, loadChildren: () => import('./documents/documents.module').then(o => o.DocumentsModule), canActivate: [AuthGuard] },
  { path: "Register", loadChildren: () => import('./SignUp/sign-up').then(o => o.SignUpModule) },
  { path: "Dashboard", component: LayoutComponent, loadChildren: () => import('./Dashboard/dashboard').then(o => o.DashboardModule), canActivate: [AuthGuard] },
  { path: "Reference", component: LayoutComponent, loadChildren: () => import('./reference/reference').then(o => o.ReferenceModule), canActivate: [AuthGuard] },
  { path: "Resume", component: LayoutComponent, loadChildren: () => import('./cv-resume/cv-resume.module').then(o => o.CvResumeModule), canActivate: [AuthGuard] },
  { path: "SocialProfile", component: LayoutComponent, loadChildren: () => import('./socialprofile/socialprofile.component').then(o => o.Socialprofile), canActivate: [AuthGuard] },
  { path: "privacyPolicy", component: LayoutComponent, loadChildren: () => import('./privacy-policy/privacy-policy.component').then(o => o.PrivacyPolicy) },
  { path: "TemplateOne", component: LayoutComponent, loadChildren: () => import('./TemplateOne/template-one').then(o => o.TemplateOneModule), canActivate: [AuthGuard] },
  { path: "TemplateTwo", component: LayoutComponent, loadChildren: () => import('./TemplateTwo/template-two').then(o => o.TemplateTwoModule), canActivate: [AuthGuard] },
  { path: "TemplateThree", component: LayoutComponent, loadChildren: () => import('./TemplateThree/template-three').then(o => o.TemplateThreeModule), canActivate: [AuthGuard] },
  { path: "Template", component: LayoutComponent, loadChildren: () => import('./Template/template').then(o => o.TemplateModule), canActivate: [AuthGuard] },
  { path: "account", component: LayoutComponent, loadChildren: () => import('./account/account.module').then(o => o.AccountModule), canActivate: [AuthGuard] },
  { path: "verification", component: VerifyComponent },
  { path: "calendar", loadChildren: () => import('./calendar/calendar.module').then(o => o.CalendarModule) },
  { path: "toast-calendar", component: ToastCalendarComponent },
  { path: "searchJobs", component: LayoutComponent, loadChildren: () => import('./Jobs/activejobs/activejobs.module').then(o => o.ActiveJobsModule), canActivate: [AuthGuard] },
  { path: 'JobDetail/:id', component: LayoutComponent, loadChildren: () => import('./Jobs/jobdetail/jobdetail.module').then(o => o.JobdetailModule), canActivate: [AuthGuard] },
  { path: 'favouriteJobs', component: LayoutComponent, loadChildren: () => import('./Jobs/favouritejobs/favouritejobs.module').then(o => o.FavouriteJobsModule), canActivate: [AuthGuard] },
  { path: 'appliedJobs', component: LayoutComponent, loadChildren: () => import('./Jobs/appliedjobs/appliedjobs.module').then(o => o.AppliedJobsModule), canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  })],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
