<div class="mt-0 pt-0" id="list-family">
        <div class="form-group row" *ngIf="itemResultList.length > 0">
            <div class="col-lg-12">
                <div class="row" *ngIf="(itemResultList | filter : 'immediate').length > 0">
                    <div class="col-xl-12 mt-4">
                        <div class="bg-light-primary pl-5 font-weight-bold py-4">
                            {{'SOCIAL.FAMILY.IMMEDIATE_TITLE' | translate}}
                        </div>
                    </div>
                    <ng-container *ngFor="let item of (itemResultList | filter : 'immediate')">
                        <div class="col-xl-6" *ngIf="!item._isEditMode">
                            <div class="card card-custom bg-gray-100 card-shadowless mb-4 mt-4">
                                <!--begin::Body-->
                                <div class="card-body p-3">
                                    <div class="font-size-sm">
                                        <span class="text-dark-75 font-weight-bold">{{item.name | titlecase}} {{item.lastName | titlecase}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm">
                                        {{'SOCIAL.FAMILY.FIELD.RELATION' | translate}}
                                        <span class="font-size-sm text-dark-50">{{item.relation}}</span>
                                        <span *ngIf="item.isDependent" class="font-size-sm text-success ml-5"><i
                                                class="fa fa-users"></i></span>
                                    </div>
                                    <div class="text-muted font-size-sm">
                                        {{'SOCIAL.FAMILY.FIELD.AGE' | translate}}
                                        <span class="font-size-sm text-dark-50">{{ item.alive ? item.age+' years old'
                                            : '-'}}</span>
                                        <span *ngIf="!item.alive"
                                            class="font-size-sm text-danger font-weight-bolder ml-5">{{'SOCIAL.FAMILY.FIELD.ALIVE_RIP' | translate}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm" *ngIf="item.alive">
                                        <!-- {{'SOCIAL.FAMILY.FIELD.PROFESSION' | translate}} -->
                                        <span class="font-size-sm text-dark-50">{{item.profession | titlecase}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm" *ngIf="item.alive && item.working">
                                        {{'SOCIAL.FAMILY.FIELD.PLACE_OF_WORK' | translate}}
                                        <span class="font-size-sm text-dark-50">{{item.company | titlecase}}</span>
                                    </div>
                                    <div class="font-size-sm" *ngIf="item.alive && item.phone">
                                        <span class="text-dark-60"><i class="la la-phone text-secondary"></i>
                                            {{item.phone}}</span>
                                    </div>
                                    <div class="font-size-sm" *ngIf="item.alive && item.email">
                                        <span class="text-dark-60"><i class="la la-envelope text-secondary"></i>
                                            {{item.email}}</span>
                                    </div>
                                    <div class="action-button">
                                        <a href="javascript:;" (click)="editItemButtonOnClick(item)"
                                            *ngIf="!isSwitchedToEditMode" class="action-primary"><i
                                                class="fa fa-pen text-success"></i></a>
                                        <a href="javascript:;" (click)="deleteItem(item)" *ngIf="!isSwitchedToEditMode"
                                            class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                                    </div>
                                </div>
                                <!--end:: Body-->
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="(itemResultList | filter : 'direct').length > 0">
                    <div class="col-xl-12 mt-4">
                        <div class="bg-light-primary pl-5 font-weight-bold py-4">
                            {{'SOCIAL.FAMILY.DIRECT_TITLE' | translate}}
                        </div>
                    </div>
                    <ng-container *ngFor="let item of (itemResultList | filter : 'direct')">
                        <div class="col-xl-6" *ngIf="!item._isEditMode">
                            <div class="card card-custom bg-gray-100 card-shadowless mb-4 mt-4">
                                <!--begin::Body-->
                                <div class="card-body p-3">
                                    <div class="font-size-sm">
                                        <span class="text-dark-75 font-weight-bold">{{item.name | titlecase}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm">
                                        {{'SOCIAL.FAMILY.FIELD.RELATION' | translate}}
                                        <span class="font-size-sm text-dark-50">{{item.relation}}</span>
                                        <span *ngIf="item.isDependent" class="font-size-sm text-success ml-5"><i
                                                class="fa fa-users"></i></span>
                                    </div>
                                    <div class="text-muted font-size-sm">
                                        {{'SOCIAL.FAMILY.FIELD.AGE' | translate}}
                                        <span class="font-size-sm text-dark-50">{{ item.alive ? item.age+' years old'
                                            : '-'}}</span>
                                        <span *ngIf="!item.alive"
                                            class="font-size-sm text-danger font-weight-bolder ml-5">{{'SOCIAL.FAMILY.FIELD.ALIVE_RIP' | translate}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm" *ngIf="item.alive">
                                        {{'SOCIAL.FAMILY.FIELD.PROFESSION' | translate}}
                                        <span class="font-size-sm text-dark-50">{{item.profession | titlecase}}</span>
                                    </div>
                                    <div class="text-muted font-size-sm" *ngIf="item.alive && item.isWorking">
                                        {{'SOCIAL.FAMILY.FIELD.PLACE_OF_WORK' | translate}}
                                        <span class="font-size-sm text-dark-50">{{item.company | titlecase}}</span>
                                    </div>
                                    <div class="font-size-sm" *ngIf="item.alive && item.phone">
                                        <span class="text-dark-60"><i class="la la-phone text-secondary"></i>
                                            {{item.phoneCode}}-{{item.phone}}</span>
                                    </div>
                                    <div class="font-size-sm" *ngIf="item.alive && item.email">
                                        <span class="text-dark-60"><i class="la la-envelope text-secondary"></i>
                                            {{item.email}}</span>
                                    </div>
                                    <div class="action-button">
                                        <a href="javascript:;" (click)="editItemButtonOnClick(item)"
                                            *ngIf="!isSwitchedToEditMode" class="action-primary"><i
                                                class="fa fa-pen text-success"></i></a>
                                        <a href="javascript:;" (click)="deleteItem(item)" *ngIf="!isSwitchedToEditMode"
                                            class="action-secondary"><i class="fa fa-trash text-danger"></i></a>
                                    </div>
                                </div>
                                <!--end:: Body-->
                            </div>
                        </div>
                    </ng-container>
                </div>
                </div>
                </div>
                

                <form class="" [formGroup]="basicForm" *ngIf="basicForm">

        <div id="add-family">
            <div class="form-group row border-top pt-5" *ngIf="itemForAdd._isEditMode">
                <div class="col-lg-12">
                    <div class="form-group row align-items-center">
                        <h3 class="col-lg-12  font-weight-bold">{{'SOCIAL.FAMILY.ADD_NEW_TITLE' | translate}}</h3>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-5 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.NAME' | translate}}</label>
                                    <input type="text" class="form-control-solid form-control-lg form-control rounded-lg" placeholder="{{'SOCIAL.FAMILY.FIELD.NAME_INPUT' | translate}}"
                                        formControlName="addName" (keypress)="keyPressAlpha($event)" minlength="3" maxlength="40" />
                                        <div class="fv-plugins-message-container float-right">
                                            <div class="fv-help-block">
                                                <span>({{(basicForm.get('addName').value?.length || 0)}}/40)</span>
                                              <!--<span>({{ 40 - (basicForm.addName ? basicForm.addName.length : 0) }}/40)</span>-->
                                            </div>
                                          </div>
                                   <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addName','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.NAME_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.LASTNAME' | translate}}</label>
                                    <input type="text" class="form-control-solid form-control-lg form-control rounded-lg" placeholder="{{'SOCIAL.FAMILY.FIELD.LASTNAME_INPUT' | translate}}"
                                        formControlName="addLastName" (keypress)="keyPressAlpha($event)" minlength="3" maxlength="40"/>
                                        <div class="fv-plugins-message-container float-right">
                                            <div class="fv-help-block">
                                              <span>({{(basicForm.get('addLastName').value?.length || 0)}}/40)</span>
                                            </div>
                                          </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addLastName','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.LASTNAME_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.RELATION' | translate}}</label>
                                    <select formControlName="addRelation" class="form-control-solid form-control-lg form-control rounded-lg">
                                        <option value="">{{'SOCIAL.FAMILY.FIELD.RELATION_INPUT' | translate}}</option>
                                        <option *ngFor="let relation of typeList" [value]="relation.relation">
                                            {{relation.relation}}</option>
                                    </select>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addRelation','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.RELATION_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 mt-15">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" formControlName="addIsAlive" />
                                            <!-- <span></span>{{'SOCIAL.FAMILY.FIELD.ALIVE' | translate}}</label> -->
                                            <span></span>{{'SOCIAL.FAMILY.FIELD.ALIVE_RIP' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!basicForm.get('addIsAlive').value">
                                <div class="col-lg-4 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.AGE' | translate}}</label>
                                    <div class="input-group date">
                                        <input class="form-control-solid form-control-lg form-control rounded-lg" placeholder="{{'SOCIAL.FAMILY.FIELD.AGE_INPUT' | translate}}" type="text"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'  formControlName="addAge" maxlength="2" max="99"/>
                                    </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addAge','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.AGE_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 mt-15">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox"
                                                formControlName="addIsDependent" />
                                            <span></span>{{'SOCIAL.FAMILY.FIELD.DEPENDENT' | translate}}</label>
                                    </div>
                                </div>
                                <div class="col-lg-3 mt-15">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" formControlName="addIsWorking" />
                                            <span></span>{{'SOCIAL.FAMILY.FIELD.WORKING' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!basicForm.get('addIsAlive').value">
                                <div class="col-lg-5 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.EMAIL' | translate}}</label>
                                    <input type="text" class="form-control-solid form-control-lg form-control rounded-lg" placeholder="{{'SOCIAL.FAMILY.FIELD.EMAIL_INPUT' | translate}}"
                                        formControlName="addEmail" />
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addEmail','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.EMAIL_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addEmail','pattern')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.EMAIL_INVALID' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.PHONE' | translate}}</label>
                                    <div class="input-group">
                                            
                                        <div class="col-xs-2">
                                              <!--<select formControlName="addPhoneCode" class="form-control w-10">    
                                                  <option value="" selected disabled>Select Phonecode</option>                                            
                                                <option *ngFor="let item of phoneCodeList" [value]="item.phoneCode">
                                                    {{ item.iso3 + ' (' + item.phoneCode + ')' }}</option>
                                            </select>-->
                                            <p-dropdown class="form-control p-0 form-control-solid form-control-lg form-control border-0 rounded-lg p-0" name="phoneCode" 
                                            formControlName="addPhoneCode" placeholder="{{'AUTH.FIELD.PHONE_CODE_INPUT' | translate}}" 
                                                      [options]="phonecodeiso"
                                                      optionLabel="value"
                                                      optionValue="value"
                                                      [filter]="true"
                                                      filterBy="value"
                                                      
                                                    >
                                                     </p-dropdown>
                                            </div>
                                         <input type="text" class="form-control-solid form-control-lg form-control border-0 rounded-lg" placeholder="{{'SOCIAL.FAMILY.FIELD.PHONE_INPUT' | translate}}"
                                         onkeypress='return event.charCode <= 57'  formControlName="addPhone" maxlength="13" minlength="8"/>
                                         <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addPhone','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PHONE_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addPhone','pattern')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PHONE_INVALID' | translate}}</span>
                                        </div>
                                    </div>
                                     </div>
                                     </div>
                                     </div>
                                <!--<div class="col-lg-5 mt-6">
                                    <label>{{'SOCIAL.FAMILY.FIELD.PHONE' | translate}}</label>
                                    <input type="text" class="form-control" placeholder="{{'SOCIAL.FAMILY.FIELD.PHONE_INPUT' | translate}}"
                                    onkeypress='return event.charCode <= 57'  formControlName="addPhone" maxlength="13" minlength="8"/>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addPhone','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PHONE_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addPhone','pattern')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PHONE_INVALID' | translate}}</span>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                            <div class="row">
                                <div class="col-lg-4 mt-6" *ngIf="!basicForm.get('addIsAlive').value">
                                    <label>{{'SOCIAL.FAMILY.FIELD.PROFESSION' | translate}}</label>
                                    <textarea autosize type="text" rows="2" data-expandable minlength="100" maxlength="180" class="form-control"
                                        placeholder="{{'SOCIAL.FAMILY.FIELD.PROFESSION_INPUT' | translate}}"
                                        formControlName="addProfession"></textarea>
                                        <div class="fv-plugins-message-container float-right">
                                            <div class="fv-help-block">
                                              <span>({{ 180 - (basicForm.addProfession ? basicForm.addProfession.length : 0) }}/180)</span>
                                            </div>
                                          </div>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addProfession','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PROFESSION_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 mt-6"
                                    *ngIf="basicForm.get('addIsWorking').value && !basicForm.get('addIsAlive').value">
                                    <label>{{'SOCIAL.FAMILY.FIELD.PLACE_OF_WORK' | translate}}</label>
                                    <textarea autosize type="text" rows="2" data-expandable maxlength="100" class="form-control"
                                        placeholder="{{'SOCIAL.FAMILY.FIELD.PLACE_OF_WORK_INPUT' | translate}}"
                                        formControlName="addCompany"></textarea>
                                    <div class="fv-plugins-message-container"
                                        *ngIf="isControlHasError('addCompany','required')">
                                        <div class="fv-help-block">
                                            <span>{{'SOCIAL.FAMILY.FIELD.PLACE_OF_WORK_REQUIRED' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 mt-15" *ngIf="isEditing">
                                    <a href="javascript:;" (click)="saveUpdatedItem()"
                                        class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                                        <i class="la la-check"></i></a>
                                    <a href="javascript:;" (click)="cancelEditButtonOnClick(itemForAdd)"
                                        class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                                        <i class="la la-close"></i></a>
                                </div>
                                <div class="col-lg-4 mt-15" *ngIf="!isEditing">
                                    <a href="javascript:;" (click)="saveNewItem()"
                                        class="btn btn-sm font-weight-bolder btn-light-success mr-5">
                                        <i class="la la-check"></i></a>
                                    <a href="javascript:;" (click)="cancelAddButtonOnClick()"
                                        class="btn btn-sm font-weight-bolder btn-light-danger mr-5">
                                        <i class="la la-close"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </form>

    <div *ngIf="itemResultList.length === 0 && !isSwitchedToEditMode"
        class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b" role="alert">
        <div class="alert-text">{{'SOCIAL.FAMILY.NO_DATA' | translate}}. </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-4">
            <button *ngIf="!itemForAdd._isEditMode && !isSwitchedToEditMode" (click)="addItemButtonOnClick()"
                class="btn btn-sm font-weight-bolder btn-light-primary">
                <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}</button>
        </div>
    </div>
</div>

<div class="d-flex justify-content-between border-top mt-5 pt-10">
    <div class="mr-2">
      <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
    </div>
    <div>
      <ng-container *ngIf="loading">
        <span class="spinner spinner-primary mr-10"></span>
      </ng-container>
      <button
        type="button"
        (click)="onSubmit()"
        class="btn btn-primary font-weight-bolder text-uppercase"
      >
      {{'BUTTON.SAVE_EXIT' | translate}}    </button>
    </div>
  </div>