import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl) {
		const newPassword = control.get('password').value;
		const confirmPassword = control.get('confirmPassword').value;
		if (newPassword !== confirmPassword) {
			if (confirmPassword) {
				control.get('confirmPassword').setErrors({ConfirmPassword: true});
			}else {
				control.get('confirmPassword').setErrors({required: true});
			}
		} else {
			return null;
		}
	}
}
