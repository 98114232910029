import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './Shared/layout/layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AlphaInterceptor } from 'src/Helper/http.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {MatDatepickerModule,} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon'
import {DropdownModule} from 'primeng/dropdown';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!


import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LanguageComponent } from './Overlay/language/language.component';
import { SkillsComponent } from './Overlay/skills/skills.component';
import { ItskillsComponent } from './Overlay/itskills/itskills.component';

import { Pipe, PipeTransform } from '@angular/core';


import {HttpUtilsService} from './core/utils/http-utils.service';
import { ConfirmationEntityComponentComponent } from './core/common/confirmation-entity-component/confirmation-entity-component.component';
import { PConfirmationEntityComponentComponent } from './core/common/pconfirmation-entity-component/pconfirmation-entity-component.component';
import { SpgeneralComponent } from './socialprofile/edit-popup/pages/spgeneral/spgeneral.component';
import { FamilyComponent } from './socialprofile/edit-popup/pages/family/family.component';
import { HobbiesComponent } from './socialprofile/edit-popup/pages/hobbies/hobbies.component';
import { LocationComponent } from './socialprofile/edit-popup/pages/location/location.component';
import { RecognitionsComponent } from './socialprofile/edit-popup/pages/recognitions/recognitions.component';
import { ReligionComponent } from './socialprofile/edit-popup/pages/religion/religion.component';
import { SociallinksComponent } from './socialprofile/edit-popup/pages/sociallinks/sociallinks.component';
import { CommitmentsComponent } from './socialprofile/edit-popup/pages/commitments/commitments.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PhotoEditorComponent } from './Shared/photo-editor/photo-editor.component';
import { PhotoEditorComponentResize } from './Shared/photo-editor-resize/photo-editor-resize.component';
import { AutosizeModule } from 'ngx-autosize';
import {MultiSelectModule} from 'primeng/multiselect';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { AccountComponent } from './account/account.component';
import { AccountInfoComponent } from './account/account-info/account-info.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { NotificationPreferenceComponent } from './account/notification-preference/notification-preference.component';
import { TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VerifyComponent } from './SignUp/verify/verify.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ToastCalendarComponent } from './toast-calendar/toast-calendar.component';
import { MenubarModule } from 'primeng/menubar';
export const createTranslateLoader = (http: HttpClient) => {
return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,    
    TermsAndConditionsComponent,   
    LanguageComponent,
    SkillsComponent,
    ItskillsComponent,
    ConfirmationEntityComponentComponent,
    SpgeneralComponent,
    FamilyComponent,
    HobbiesComponent,
    LocationComponent,
    RecognitionsComponent,
    ReligionComponent,
    SociallinksComponent,
    CommitmentsComponent,
    PhotoEditorComponent,
    PhotoEditorComponentResize,
    PConfirmationEntityComponentComponent,
    AccountComponent,
    AccountInfoComponent,
    ChangePasswordComponent,
    NotificationPreferenceComponent,
    VerifyComponent,
    TemplatePreviewComponent,
    CalendarComponent,
    ToastCalendarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ToastModule,MatDatepickerModule,MatInputModule,MatNativeDateModule,MatIconModule,
    ConfirmDialogModule,
    NgbModule,
    DynamicDialogModule,
    ImageCropperModule,
    AutosizeModule,
    MenubarModule,
    MultiSelectModule,
    Ng2SearchPipeModule,
    SocialLoginModule,
    DropdownModule,
    FullCalendarModule, // register FullCalendar with you app
    TranslateModule.forRoot({
      loader: {
        provide:TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
],
providers: [
  TranslateService,
    {
        provide: 'SocialAuthServiceConfig',
        useValue: {
            autoLogin: false,
            providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(
                        environment.google_Client_Id
                    )
                },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider(environment.fb_Client_Id)
                }
            ]
        } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlphaInterceptor,
      multi: true
    },
    ConfirmationService,
    MessageService,
    HttpUtilsService,
    DialogService
  ],
  exports:[TranslateModule],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
