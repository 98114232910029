import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { Family } from "src/app/core/socialprofile/family.model";
import { Social } from "src/app/core/socialprofile/social.model";
//Moment
import * as moment from "moment";
import { CommonService } from "src/Service/Common.service";
import { CommonHelper } from "src/Helper/CommonHelper";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { LayoutserviceService, MessageType } from "src/app/core/utils/layoutservice.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-family",
  templateUrl: "./family.component.html",
  styleUrls: ["./family.component.css"],
})
export class FamilyComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;
  isUpdated = false;

  isSwitchedToEditMode = false;
  isEditing: boolean;
  itemForEdit: Family;
  itemForAdd: Family;
  itemResultList: Family[] = [];
  phoneCodeList: any[] = [];
  phonecodeiso: any = [];

  language: string = "en";

  @Input() stepCount$: BehaviorSubject<number>;

  @Input() socialDetails$: BehaviorSubject<Social>;

  socialDetails: Social = new Social();

  familyList: Family[] = [];
  typeList: any[] = [
    {
      type: "immediate",
      relation: this.translate.instant('SOCIAL.FAMILY.RELATIONSHIP.WIFE_HUSBAND'),
    },
    {
      type: "immediate",
      relation: this.translate.instant('SOCIAL.FAMILY.RELATIONSHIP.SON_DAUGHTER'),
    },
    {
      type: "direct",
      relation: this.translate.instant('SOCIAL.FAMILY.RELATIONSHIP.FATHER'),
    },
    {
      type: "direct",
      relation: this.translate.instant('SOCIAL.FAMILY.RELATIONSHIP.MOTHER'),
    },
    {
      type: "direct",
      relation: this.translate.instant('SOCIAL.FAMILY.RELATIONSHIP.BROTHER_SISTER'),
    },
  ];

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public fb: FormBuilder,
    public service: CommonService,
    public helper: CommonHelper,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public layoutUtilsService: LayoutserviceService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.socialDetails.clear();
    // this.clearAddForm();
    // this.clearEditForm();
    if (
      this.config.data &&
      this.config.data.family &&
      this.config.data.family.length > 0
    ) {
      this.config.data.family.map((data, index) => {
        this.familyList.push({ ...data });
      });
      this.socialDetails = this.config.data;
      this.loaditemList();
    }
    this.createFormGroup();
    this.GetPhoneCode();
  }
  GetPhoneCode() {
    this.service.GetAll("api/v1/allcountry").then(
      (res) => {
        this.phoneCodeList = res.sort((a, b) => a.iso3.localeCompare(b.iso3));
        this.phoneCodeList.forEach(element => {
          this.phonecodeiso.push({'value': "("+element.iso3+")"+element.phoneCode});
      });
      });
    }
    
  loaditemList(id: number = undefined, newStatus: boolean = false) {
    let newId = 1;
    this.itemResultList = [];
    this.familyList.forEach((item) => {
      let newItem = new Family();
      newItem.clear();
      newItem.id = item.id;
      newItem.type = item.type;
      newItem.name = item.name;
      newItem.lastName = item.lastName;
      newItem.relation = item.relation;
      newItem.email = item.email;
      newItem.phoneCode = item.phoneCode;
      newItem.phone = item.phone;
      newItem.age = item.age;
      newItem.alive = item.alive;
      newItem.dependent = item.dependent;
      newItem.working = item.working;
      newItem.profession = item.profession;
      newItem.company = item.company;
      newItem._isEditMode = false;
      if (item.id === id) {
        newItem._isEditMode = newStatus;
      }
      this.itemResultList.push(newItem);
    });
    this.familyList = this.itemResultList;
  }

  createFormGroup(_item = null) {
    this.basicForm = this.fb.group({
      addName: ["", Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(20),
        Validators.pattern('^[a-zA-Z ]*$')])],
      addLastName: ["", Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(20),
        Validators.pattern('^[a-zA-Z ]*$')])],
      addRelation: ["", Validators.compose([Validators.required])],
      addEmail: [
        "",
        Validators.compose([
          Validators.pattern(
            "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          ),
        ]),
      ],
      addPhoneCode: [
        "",
      ],
      addPhone: [
        "",
        Validators.compose([Validators.pattern("[0-9]{8,13}")]),
      ],
      addAge: ["", Validators.compose([])],
      addIsAlive: ["", Validators.compose([])],
      addIsDependent: ["", Validators.compose([])],
      addIsWorking: ["", Validators.compose([])],
      addProfession: ["", Validators.compose([])],
      addCompany: ["", Validators.compose([])],
      name: ["", Validators.compose([Validators.required])],
      lastName: ["", Validators.compose([Validators.required])],
      relation: ["", Validators.compose([Validators.required])],
      email: [
        "",
        Validators.compose([
          Validators.pattern(
            "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          ),
        ]),
      ],
      phone: [
        "",
        Validators.compose([Validators.pattern("[0-9]{8,13}")]),
      ],
      age: ["", Validators.compose([])],
      isAlive: ["", Validators.compose([])],
      isDependent: ["", Validators.compose([])],
      isWorking: ["", Validators.compose([])],
      profession: ["", Validators.compose([])],
      company: ["", Validators.compose([])],
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  clearAddForm() {
    const controls = this.basicForm.controls;
    controls.addName.setValue("");
    controls.addName.markAsPristine();
    controls.addName.markAsUntouched();
    controls.addLastName.setValue("");
    controls.addLastName.markAsPristine();
    controls.addLastName.markAsUntouched();
    controls.addRelation.setValue("");
    controls.addRelation.markAsPristine();
    controls.addRelation.markAsUntouched();
    controls.addEmail.setValue("");
    controls.addEmail.markAsPristine();
    controls.addEmail.markAsUntouched();
    controls.addPhoneCode.setValue("");
    controls.addPhoneCode.markAsPristine();
    controls.addPhoneCode.markAsUntouched();
    controls.addPhone.setValue("");
    controls.addPhone.markAsPristine();
    controls.addPhone.markAsUntouched();
    controls.addAge.setValue("");
    controls.addAge.markAsPristine();
    controls.addAge.markAsUntouched();
    controls.addIsAlive.setValue(false);
    controls.addIsAlive.markAsPristine();
    controls.addIsAlive.markAsUntouched();
    controls.addIsDependent.setValue(false);
    controls.addIsDependent.markAsPristine();
    controls.addIsDependent.markAsUntouched();
    controls.addIsWorking.setValue(false);
    controls.addIsWorking.markAsPristine();
    controls.addIsWorking.markAsUntouched();
    controls.addProfession.setValue("");
    controls.addProfession.markAsPristine();
    controls.addProfession.markAsUntouched();
    controls.addCompany.setValue("");
    controls.addCompany.markAsPristine();
    controls.addCompany.markAsUntouched();

    this.itemForAdd = new Family();
    this.itemForAdd.clear();
    this.itemForAdd._isEditMode = false;
  }

  checkAddForm() {
    const controls = this.basicForm.controls;
    if (
      controls.addName.invalid ||
      controls.addLastName.invalid ||
      controls.addRelation.invalid ||
      controls.addEmail.invalid ||
      controls.addPhoneCode.invalid ||
      controls.addPhone.invalid ||
      controls.addAge.invalid ||
      controls.addIsAlive.invalid ||
      controls.addIsWorking.invalid ||
      controls.addIsDependent.invalid 
      //controls.addProfession.invalid ||
      //controls.addCompany.invalid
    ) {
      controls.addName.markAsTouched();
      controls.addLastName.markAsTouched();
      controls.addRelation.markAsTouched();
      controls.addEmail.markAsTouched();
      controls.addPhoneCode.markAsTouched();
      controls.addPhone.markAsTouched();
      controls.addAge.markAsTouched();
      controls.addIsAlive.markAsTouched();
      controls.addIsWorking.markAsTouched();
      controls.addIsDependent.markAsTouched();
      //controls.addProfession.markAsTouched();
      //controls.addCompany.markAsTouched();
      return false;
    }
    return true;
  }

  addItemButtonOnClick() {
    this.clearAddForm();
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.scrollTo("add-family");
  }

  cancelAddButtonOnClick() {
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    this.scrollTo("list-family");
  }

  saveNewItem() {

    const controls = this.basicForm.controls;

    this.itemForAdd.id = moment().valueOf();

    this.itemForAdd.name = controls.addName.value;
    this.itemForAdd.lastName = controls.addLastName.value;
    this.itemForAdd.relation = controls.addRelation.value;
    this.itemForAdd.email = controls.addEmail.value;
    this.itemForAdd.phoneCode = controls.addPhoneCode.value;
    this.itemForAdd.phone = controls.addPhone.value;
    this.itemForAdd.age = +controls.addAge.value;
    this.itemForAdd.alive = !controls.addIsAlive.value;
    this.itemForAdd.working = controls.addIsWorking.value;
    this.itemForAdd.dependent = controls.addIsDependent.value;
    this.itemForAdd.profession = controls.addProfession.value;
    this.itemForAdd.company = controls.addCompany.value;
    this.itemForAdd.type = this.getMemberType(controls.addRelation.value);
    this.itemForAdd._isEditMode = false;

    this.familyList.push(this.itemForAdd);
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  getMemberType(relation) {
    let selected = this.typeList.filter((el) => el.relation === relation);
    if (selected.length > 0) {
      return selected[0].type;
    } else {
      return "immediate";
    }
  }

  clearEditForm() {
    const controls = this.basicForm.controls;
    controls.addName.setValue("");
    controls.addName.markAsPristine();
    controls.addName.markAsUntouched();
    controls.addLastName.setValue("");
    controls.addLastName.markAsPristine();
    controls.addLastName.markAsUntouched();
    controls.addRelation.setValue("");
    controls.addRelation.markAsPristine();
    controls.addRelation.markAsUntouched();
    controls.addEmail.setValue("");
    controls.addEmail.markAsPristine();
    controls.addEmail.markAsUntouched();
    controls.addPhoneCode.setValue("");
    controls.addPhoneCode.markAsPristine();
    controls.addPhoneCode.markAsUntouched();
    controls.addPhone.setValue("");
    controls.addPhone.markAsPristine();
    controls.addPhone.markAsUntouched();
    controls.addAge.setValue("");
    controls.addAge.markAsPristine();
    controls.addAge.markAsUntouched();
    controls.addIsAlive.setValue(false);
    controls.addIsAlive.markAsPristine();
    controls.addIsAlive.markAsUntouched();
    controls.addIsWorking.setValue(false);
    controls.addIsWorking.markAsPristine();
    controls.addIsWorking.markAsUntouched();
    controls.addIsDependent.setValue(false);
    controls.addIsDependent.markAsPristine();
    controls.addIsDependent.markAsUntouched();
    controls.addProfession.setValue("");
    controls.addProfession.markAsPristine();
    controls.addProfession.markAsUntouched();
    controls.addCompany.setValue("");
    controls.addCompany.markAsPristine();
    controls.addCompany.markAsUntouched();

    this.itemForEdit = new Family();
    this.itemForEdit.clear();
    this.itemForEdit._isEditMode = false;
  }

  checkEditForm() {
    const controls = this.basicForm.controls;
    if (
      controls.addName.invalid ||
      controls.addLastName.invalid ||
      controls.addRelation.invalid ||
      controls.addEmail.invalid ||
      controls.addPhoneCode.invalid ||
      controls.addPhone.invalid ||
      controls.addAge.invalid ||
      controls.addIsAlive.invalid ||
      controls.addIsWorking.invalid ||
      controls.addIsDependent.invalid ||
      controls.addProfession.invalid ||
      controls.addCompany.invalid
    ) {
      controls.addRelation.markAsTouched();
      controls.addName.markAsTouched();
      controls.addLastName.markAsTouched();
      controls.addEmail.markAsTouched();
      controls.addPhoneCode.markAsTouched();
      controls.addPhone.markAsTouched();
      controls.addAge.markAsTouched();
      controls.addIsAlive.markAsTouched();
      controls.addIsWorking.markAsTouched();
      controls.addIsDependent.markAsTouched();
      controls.addProfession.markAsTouched();
      controls.addCompany.markAsTouched();
      return false;
    }
    return true;
  }

  editItemButtonOnClick(_item: Family) {
    const controls = this.basicForm.controls;

    controls.addName.setValue(_item.name);
    controls.addLastName.setValue(_item.lastName);
    controls.addRelation.setValue(_item.relation);
    controls.addEmail.setValue(_item.email);
    controls.addPhoneCode.setValue(_item.phoneCode);
    controls.addPhone.setValue(_item.phone);
    controls.addAge.setValue(_item.age);
    controls.addIsAlive.setValue(!_item.alive);
    controls.addIsWorking.setValue(_item.working);
    controls.addIsDependent.setValue(_item.dependent);
    controls.addProfession.setValue(_item.profession);
    controls.addCompany.setValue(_item.company);

    this.loaditemList(_item.id, true);
    this.itemForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
    this.isEditing = true;
    this.scrollTo("add-family");
  }

  cancelEditButtonOnClick(_item: Family) {
    this.loaditemList(_item.id, false);
    this.isEditing = false;
    this.itemForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
    this.scrollTo("list-family");
  }

  saveUpdatedItem(_item: Family) {
    if (!this.checkAddForm()) {
      return;
    }


    if (!_item) {
      _item = this.itemResultList.find((data) => (data._isEditMode === true));
    }

    console.log("Item Updating : " + JSON.stringify(_item));

    const controls = this.basicForm.controls;

    this.familyList.forEach((item) => {
      if (item.id === _item.id) {
        console.log("Item : " + JSON.stringify(item));
        item.name = controls.addName.value;
        item.lastName = controls.addLastName.value;
        item.relation = controls.addRelation.value;
        item.email = controls.addEmail.value;
        item.phoneCode = controls.addPhoneCode.value;
        item.phone = controls.addPhone.value;
        item.age = +controls.addAge.value;
        item.alive = !controls.addIsAlive.value;
        item.working = controls.addIsWorking.value;
        item.dependent = controls.addIsDependent.value;
        item.profession = controls.addProfession.value;
        item.company = controls.addCompany.value;
        item.type = this.getMemberType(controls.addRelation.value);
      }
    });
    this.isEditing = false;
    this.isSwitchedToEditMode = false;
    this.isUpdated = true;
    this.clearAddForm();
    this.loaditemList();
  }

  deleteItem(_item: Family) {
    let _type = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _title = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _description = this.translate.instant('SOCIAL.FAMILY.MESSAGES.DELETE');
  let _confirmButton = this.translate.instant('DOCUMENTS.DELETE.TITLE');
  let _cancelButton = this.translate.instant('BUTTON.CANCEL');
    
    const dialogRef = this.layoutUtilsService.confirmationPopUp(
      _type,
      _title,
      _description,
      _confirmButton,
      _cancelButton
    );
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        let newItem: Family[] = [];
        this.itemResultList.forEach((item) => {
          if (item.id !== _item.id) {
            newItem.push(item);
          }
        });
        this.isUpdated = true;
        this.familyList = newItem;
        this.loaditemList();
      }
    });
  }

  onSubmit(): void {
    if(this.isSwitchedToEditMode){
      this.layoutUtilsService.showActionNotification(
        MessageType.warning,
        localStorage.getItem("locale") == "es" ? "¡Estás editando algo! Guarde o cancele para continuar" : "'You are editing something!. Please save or cancel to proceed further'"
      )
      return;
    }

    const data = [];
    this.loading = true;
    this.service
      .CommonPostObservable(
        this.familyList,
        "socialprofile/api/family/" + this.config.data.general.socialProfileId
      )
      .subscribe(
        (data) => {
          this.loading = false;
          console.log(data);
          this.ref.close(true);
        },
        (err) => {
          this.helper.ErrorToastr("Cannot Update! Please retry");
        }
      );
  }
  scrollTo(id): void {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  getAge(dob) {
    let a = moment();
    let b = moment(dob);

    var years = a.diff(b, "years");
    var months = a.diff(b, "months") % 12;

    return years + " years old";
  }

  isControlHasError(
    controlName: string,
    validationType: string = undefined
  ): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result =
        control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  keyPressAlpha(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z\s]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
