import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutserviceService, MessageType } from 'src/app/core/utils/layoutservice.service';
import { CommonHelper } from 'src/Helper/CommonHelper';
import { CommonService } from "src/Service/Common.service";
import { NgbTimeStructAdapter } from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time-adapter";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification-preference',
  templateUrl: './notification-preference.component.html',
  styleUrls: ['./notification-preference.component.css']
})
export class NotificationPreferenceComponent implements OnInit {

  notificationUpdateForm: FormGroup;
  user: Array<string>;
  candidateId = null;
  basicData: any = {};


  NotifyOptions = [
    { label: "instantly", value: "instantly" },
    { label: "daily", value: "daily" },
    { label: "weekly", value: "weekly" },
  ];

  GenderOptionsEng = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Confidential", value: "Confidential" },
  ];
  constructor(
    public fb: FormBuilder,
    private service: CommonService,
    private layoutUtilsService: LayoutserviceService,
    public helper: CommonHelper,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.candidateId = this.helper.GetUserInfo()?.candidateId;
    this.service
    .GetById(this.candidateId, "profile/api/notifications")
    .then(
      (res) => {
        if (res) {
          this.basicData = res;
          this.initForm();
        }
      });    
  }

  onSubmit()
  {
    this.user = this.notificationUpdateForm.value;
    this.service
      .CommonPost(
        this.user,
        "profile/api/notification-preference/"
      )
      .then((data) => {
        if(data) {
          this.layoutUtilsService.showActionNotification(
            MessageType.success,
            this.translate.instant('RESUME.UPDATED_SUCCESSFULLY')
          ); 
        }
      })
}

  initForm() {
    this.candidateId = this.helper.GetUserInfo()?.candidateId;
    this.notificationUpdateForm = this.fb.group({
      enable: [this.basicData.enable, Validators.compose([
        Validators.required,
      ])
      ],
      email: [this.basicData.email, Validators.compose([
        Validators.required
      ])
      ],
      sms: [this.basicData.sms, Validators.compose([
        Validators.required
      ])
      ],
      push: [this.basicData.push, Validators.compose([
        Validators.required
      ])
      ],
      whenToNotify: [this.basicData.whenToNotify, Validators.compose([
        Validators.required
      ])
      ],
      jobMatch: [this.basicData.jobMatch, Validators.compose([
        Validators.required,
      ])
      ],
      newsletter: [this.basicData.newsletter, Validators.compose([
        Validators.required,
      ])
      ],
      processStatus: [this.basicData.processStatus, Validators.compose([
        Validators.required,
      ])
      ],
      profileView: [this.basicData.profileView, Validators.compose([
        Validators.required,
      ])
      ],
      resumeView: [this.basicData.resumeView, Validators.compose([
        Validators.required,
      ])
      ],
      profileUpdateSuggestion: [this.basicData.profileUpdateSuggestion, Validators.compose([
        Validators.required,
      ])
      ],
      timeZone: [this.basicData.timeZone, Validators.compose([
        Validators.required,
      ])
      ],
      candidateId: [this.candidateId, Validators.compose([
        Validators.required,
      ])
      ],
      
    });
  }
  
}
