import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Social } from "src/app/core/socialprofile/social.model";
import { CommonHelper } from "src/Helper/CommonHelper";
import { CommonService } from "src/Service/Common.service";

@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.css"],
})
export class LocationComponent implements OnInit {
  basicForm: FormGroup;
  loading = false;

  language: string = "en";

  socialDetails: Social = new Social();
  countryList: any = [];
  StateList: any = [];
  CityList: any = [];
  country: any;
  state: any;
  city: any;
  userInfo:  any;

  constructor(
    private helper: CommonHelper,
    private service: CommonService,
    public fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}
  GetCountryList() {
    this.service.GetAll("api/v1/country").then(
      (res) => {
        //this.countryList = res;
        this.countryList = res.sort((a, b) => a.name.localeCompare(b.name));
      },
      (err) => {
        this.helper.ErrorToastr("Cannot fetch");
      }
    );
  }
  isControlHasError(
    controlName: string,
    validationType: string = undefined
  ): boolean {
    const control = this.basicForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result =
        control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  getStateList(event) {
    this.state = '';
    this.city = '';
    if (event.target.value) {
      this.service.GetAll("api/v1/states/" + event.target.value).then(
        (res) => {
          //this.StateList = res;
          this.StateList = res.sort((a, b) => a.name.localeCompare(b.name));
        },
        (err) => {
          this.helper.ErrorToastr("Cannot fetch");
        }
      );
    } else {
      this.StateList = [];
      this.CityList = [];
    }
  }

  getCityList(event) {
    this.city = '';
    if (event.target.value) {
      this.service.GetAll("api/v1/cities/" + event.target.value).then(
        (res) => {
          //this.CityList = res;
          this.CityList = res.sort((a, b) => a.name.localeCompare(b.name));
        },
        (err) => {
          this.helper.ErrorToastr("Cannot fetch");
        }
      );
    } else {
      this.CityList = [];
    }
  }

  ngOnInit(): void {
    // this.socialDetails.clear();

    this.GetCountryList();
    if (this.config.data && this.config.data.location) {
      this.socialDetails = this.config.data;
      this.socialDetails.location = this.config.data.location[0];
      this.initForm();
      this.getStateList({target: {value: this.socialDetails.location.country}});
      this.getCityList({target: {value: this.socialDetails.location.state}});
      this.userInfo = this.helper.GetUserInfo();
      this.country = this.socialDetails?.location?.country ? this.socialDetails?.location?.country : this.helper.GetUserInfo().country;
      this.state = this.socialDetails?.location?.state ? this.socialDetails?.location?.state : this.helper.GetUserInfo().state;
      this.city = this.socialDetails?.location?.city ? this.socialDetails?.location?.city : this.helper.GetUserInfo().city;
    } else {
      this.initForm();
    }
    // this.socialDetails$.subscribe(res => {
    //   console.log(res);
    //   this.socialDetails = JSON.parse(JSON.stringify(Object.assign({}, this.socialDetails, res)));
    //   if (res) {
    // //    this.selectChange(this.socialDetails?.location?.country, 'country', true);
    //   }
    //   this.initForm();
    // })
  }

  

  initForm() {
    this.basicForm = this.fb.group({
      houseType: [
        this.socialDetails?.location?.houseType,
        Validators.compose([Validators.required]),
      ],
      yearOfLiving: [
        this.socialDetails?.location?.yearOfLiving,
        Validators.compose([Validators.required]),
      ],
      noOfPeople: [
        this.socialDetails?.location?.noOfPeople,
        Validators.compose([Validators.required]),
      ],
      addressLine1: [
        this.socialDetails?.location?.addressLine1,
        Validators.compose([Validators.required]),
      ],
      addressLine2: [
        this.socialDetails?.location?.addressLine2,
        Validators.compose([Validators.required]),
      ],
      country: [
        this.socialDetails?.location?.country
          ? this.socialDetails?.location?.country
          : "",
        Validators.compose([Validators.required]),
      ],
      state: [
        this.socialDetails?.location?.state
          ? this.socialDetails?.location?.state
          : "",
        Validators.compose([Validators.required]),
      ],
      city: [
        this.socialDetails?.location?.city
          ? this.socialDetails?.location?.city
          : "",
        Validators.compose([Validators.required]),
      ],
    });
  }

  onSubmit(): void {
    this.service
      .CommonPostObservable(
        { ...this.basicForm.value },
        "socialprofile/api/location/" +
          this.config.data?.general?.socialProfileId
      )
      .subscribe((data) => {
        console.log(data);
        this.ref.close(true);
      });
    // const controls = this.basicForm.controls;

    // // check form
    // if (this.basicForm.invalid) {
    //   Object.keys(controls).forEach((controlName) =>
    //     controls[controlName].markAsTouched()
    //   );
    //   return;
    // }
  }
}
