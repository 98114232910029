import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class CommonHelper {
  constructor(
    private router: Router,
    private messageService: MessageService
  ) {
    this.ApiURL = environment.API_URL;
    this.StorageName = "User";
  }
  ApiURL: string;
  StorageName: string;
  GetUserInfo(): any {
    let login = this.GetLocalStorage(this.StorageName, true);
    if (login == null) {
      return {};
    }
    else {
      return login;
    }
  }
  SetLocalStorage(name: string, data: any, jsonformat: boolean = true) {
    if (jsonformat) {
      window.localStorage.setItem(name, JSON.stringify(data));
    }
    else {
      window.localStorage.setItem(name, data);
    }
  }
  GetLocalStorage(name: string, jsonformat: boolean = false) {
    if (jsonformat)
      return JSON.parse(window.localStorage.getItem(name));
    else
      //return this.Decrypt(window.localStorage.getItem(name));
      return window.localStorage.getItem(name);
  }
  DeleteAllLocalStorage() {
    return window.localStorage.clear();
  }
  DeleteLocalStorage(name: string) {
    return window.localStorage.removeItem(name);
  }
  SucessToastr(message: string, title: string = "") {
    this.messageService.add({ severity: 'success', detail: message, life:3000 });
  }
  ErrorToastr(message: string, title: string = "") {
    this.messageService.add({ severity: 'error', detail: message });
  }
  ShowSpinner() {
    var x = document.getElementById("spinnerloading");
    x.style.display = "flex";
  }
  HideSpinner() {
    var x = document.getElementById("spinnerloading");
    x.style.display = "none";
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  redirectTo(uri: string) {
    this.router.navigate([uri]);
  }
  RefreshredirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  TimeSpanToDate(Date) {
    if (Date) {
      let date = new Date(Date);
      return date;
    }
    else {
      return;
    }
  }
}

