import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Toast
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationEntityComponentComponent } from '../common/confirmation-entity-component/confirmation-entity-component.component';
import { PConfirmationEntityComponentComponent } from '../common/pconfirmation-entity-component/pconfirmation-entity-component.component';
export enum MessageType {
  success,
  danger,
  info,
  warning
}

@Injectable({
  providedIn: 'root'
})
export class LayoutserviceService {

  public activeToast: ActiveToast<any>;
  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService,
    public pdialog: DialogService) {
  }
  showActionNotification(
    _type: MessageType,
    _title: string,
    _message: string = "",
    _duration: number = 3000
  ) {
    if (this.activeToast) {
      this.activeToast.toastRef.close();
    }
    switch (_type) {
      case 0:
        this.activeToast = this.toastr.success('', _title, {
          timeOut: _duration,
        });
        break;
      case 1:
        this.activeToast = this.toastr.error('', _title, {
          timeOut: _duration,
        });
        break;
      case 2:
        this.activeToast = this.toastr.info('', _title, {
          timeOut: _duration,
        });
        break;
      case 3:
        this.activeToast = this.toastr.warning('', _title, {
          timeOut: _duration,
        });
        break;
      default:
        this.activeToast = this.toastr.success('', _title, {
          timeOut: _duration,
        });
        break;
    }
  }
  confirmationEntity(type: string = 'info', title: string = '', description: string = '', confirmButton: string = 'Confirm') {
    return this.dialog.open(ConfirmationEntityComponentComponent, {
      data: { type, title, description, confirmButton },
      width: '400px'
    });
  }

  confirmationPopUp(type: string = 'info', title: string = '', description: string = '', confirmButton: string = 'Confirm', cancelButton: string = "Cancel") {
    return this.pdialog.open(PConfirmationEntityComponentComponent, {
      data: { type, title, description, confirmButton, cancelButton },
      width: '400px'
    });
  }
}
