<div id="list-recognition" class="mt-5">
    
  <div class="form-group row" *ngIf="itemResultList.length > 0">
    <div class="col-lg-12">
      <div class="row" *ngIf="itemResultList.length > 0">
        <ng-container *ngFor="let item of itemResultList">
          <div class="col-xl-4" *ngIf="!item._isEditMode">
            <div
              class="card card-custom bg-gray-100 card-shadowless mb-2 mt-2"
            >
              <!--begin::Body-->
              <div class="card-body p-3">
                <div class="font-size-md">
                  <span class="text-dark-75 font-weight-bold">
                  {{item.title}}</span>
                </div>
                <div class="font-size-sm">
                  <span class="text-muted">
                  {{item.description}}</span>
                </div>
                <div class="action-button">
                  <a
                    href="javascript:;"
                    (click)="editItemButtonOnClick(item)"
                    *ngIf="!isSwitchedToEditMode"
                    class="action-primary"
                    ><i class="fa fa-pen text-success"></i
                  ></a>
                  <a
                    href="javascript:;"
                    (click)="deleteItem(item)"
                    *ngIf="!isSwitchedToEditMode"
                    class="action-secondary"
                    ><i class="fa fa-trash text-danger"></i
                  ></a>
                </div>
              </div>
              <!--end:: Body-->
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <div
        id="edit-recognition"
        *ngFor="let item of itemResultList; let i = index"
      >
        <div
          class="form-group row align-items-center border-top pt-5"
          *ngIf="item._isEditMode"
        >
          <div class="col-lg-12">
            <div class="form-group row align-items-center">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9 mt-6">
                    <label>
                        Name your Sport or Hobbie:</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="title"
                      placeholder="
                      Name your Sport or Hobbie"
                      list="hobbies"
                    />
                    <datalist id="hobbies">
                            
                            <option _ngcontent-slt-c24="" value="Act in theater" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Adventure travels" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aerial dance" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aerobics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aeromodelling" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aikido" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Airsoft" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Alpine ski" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Amateur radio" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Animation (Creation of animated and cartoon films)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aquariums" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Archery" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Aromatherapy" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Art prints" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Art: attend cultural shows (Theater, music, dance, opera, zarzuela)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Artistic drawing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Artistic paint" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Associations (Membership of social groups of artistic, sports, festive, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Astrology" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Astronomy" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Audiobooks" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="BMX (motocross bike)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Backgammon" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Badminton" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Baking / pastry and cupcakes" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Balloons (work with moldable balloons)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Baseball" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Basketball" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Basketry" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Batik and fabric printing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Beauty and aesthetics (Hobby a)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Beer (Homemade from)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bees (Beekeeping or beekeeping)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bibliophilia" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bicycle (cycling)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Billiards" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bingo" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bird watching" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Blog (and videoblog)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Board games (general)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bodyboard" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bodybuilding" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bonsai (dwarf trees)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Book binding" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bowling (Bowling, bowling)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Boxing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bricolage or DYI" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Bulls (fondness for)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Buy (or sell: clothes, accessories, objects, etc)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Calligraphy and lettering" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Camping" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Canariculture" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Candles (Creation of candles)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Canoeing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Canyoning" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Capoeira (type of Brazilian wrestling)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Car driving (sports car)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Card games or playing cards" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Carpentry" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Carrovela and blokart (Land sailing)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Ceramics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Chalkpaint (chalk paint)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Checkers (game of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Chess" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Chi kung or qui gong (healthy relaxation activity)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Chorus" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Cinema (movie hobby, watching movies)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Classic / Antique Cars" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Climbing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Clothing customization" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Clothing design (textile)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Collectibles (Stamps, coins, antiques, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Comics (Creation of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Comics (Reading)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Comidista (foodie)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Computer programming" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Conferences (Attend)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Cosmetics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Croquet" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Cross-country or Nordic skiing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Cruises (trips of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Cultural volunteering" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Curling" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dance" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Darts (throwing darts)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Decoupage (Surface decoration)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Design and creation of web pages" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Diary (write a)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Digital art" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dioramas (and nativity scenes)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Diving (scuba diving)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dj (disk jockey)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Documentaries (fondness for)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dogs (contests / exhibitions)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dollhouses (construction of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dolls (make)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Domino" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Dried flowers (work with)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Electronics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Enamels on metal (on fire)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Environmental volunteering" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Falconry (training of birds of prey)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fantasy Sports (Fantasy League)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fashion (Fashion hobby)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fencing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Figure painting (Polychrome, gold)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fishing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fitness and maintenance gymnastics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Football" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Framing pictures" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Frisbee (flying disc)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Fronton" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Furniture restoration" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Games with electronic devices (PC, Tablet, mobile)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Gardening" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Genealogy and family history" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Geocaching and treasure hunting" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Go (game)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Golf" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Gotcha / Paintball" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Graffiti and urban art" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Grass hockey" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Handball" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Hobbies (crosswords, sudokus, children's, memory, etc ...)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Home garden" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Horse (riding, horse riding, horseback riding)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Hydroponics (liquid culture)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Ice Hockey" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Ice skating" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Ikebana (Floral Art)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Indoor football" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Inside plants" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Interior decoration" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Internet (browse)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Invest in the stock market" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Jams (prepare)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Jet ski" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Jewelery and costume jewelery (Creation of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Jiu Jitsu" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Judo" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kanzashi flowers" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Karaoke" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Karate" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kendo" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kitchen" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kites (fly kites)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kitesurf" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kokedamas (Plants on moss)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Kung Fu" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Labor (Knitting, embroidery, lace, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Leather work as a hobby" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Lego (constructions with pieces)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Lomography (type of photography)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Lottery" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Magazines and newspapers (read)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Magic (illusionism, sleight of hand)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mahjong (Chinese tile game)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Markets / flea markets" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Meditation" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Metals (search for)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mindfulness or mindfulness (Relaxing technique)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Minerals (search for)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Model making with matches" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Modeling (Planes, cars, boats, drones, figures, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Motor boats" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Motorcycle driving (motorcycling)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mountain bike" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mountain races" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mountaineering / mountaineering" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Mushrooms (searching or gathering)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Music (Hobby)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Musical composition" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Nails (decoration of), manicure and pedicure" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Nautical or water skiing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Nordic walk or walk (Nordic walking)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Observation of trains and planes" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Orientation" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Origami (or origami)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Paddle" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Paddle surf (paddle surf)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Painting on silk" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Paper mache" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Paragliding" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Parkour (freerunning)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Patchwork and bedspreads" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Petanque" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Photography" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Pigeons (Pigeon breeding, pigeon racing and colombiculture)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Pilates" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Ping pong or table tennis" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Play a musical instrument" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Podcasts (Hobby / Creation)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Puppets (theater of)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Puzzles" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Rafting" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Reading" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Reiki (spiritual healing technique)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Remote Control Vehicles (RC)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Robotics" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Role playing games" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Roller hockey" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Roller skating" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Rubber stamps or stamps (created with)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Rubik's cube (puzzle)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Rugby" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Running" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sailing (gliding)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Scrapbook (scrapbook)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sculpture" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sewing, cutting and tailoring" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Skateboard" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Skydiving" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Slime (play with slime, a slimy mass)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Snow rackets" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Snowboard" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Soap (Make)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Soccer" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Social networks (Facebook, Youtube, twitter, Instagram, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Social volunteering" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Speleology" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sport (Attend sporting events)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sport (See sport on tv, online, magazines or newspapers, radio)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sport hunting)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sport shooting (Olympic) with firearm" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sports (and recreational) aviation" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sports and recreational sailing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Sports rowing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Squash" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Study (Languages, other subjects, etc.)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Surf" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Swim (Swimming)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="TV series (love of series)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Table hockey (air hockey)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Taekwondo" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tai Chi" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Take a walk" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tarot" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tattoos" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Taxidermy" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tejo (Colombian game)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tennis" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Themed board games" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tinted glass" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Token Games (General)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Tourism cycle" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Travel" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Trekking" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Triathlon, Marathon, Ironman" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Underwater fishing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Urban exploration (urbex)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Urban visits" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Video (Making videos)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Video game" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Visit monuments and archaeological sites" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Visit museums and exhibitions" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Volleyball" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Volunteering in sport" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Wakeboard" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Water polo" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Weaving (with a homemade loom)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Weightlifting (weight lifting)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Wild plants, including trees and shrubs (Hobby)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Windsurfing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Wine tasting" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Wood (wood carvings)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Wrestling (Olympic, Greco-Roman… ..)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Write literature (prose, poetry, theater, essay, scripts)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Yoga" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Youtuber" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="Zumba (Gymnastic dance)" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="kick boxing" class="ng-star-inserted"></option>
                            <option _ngcontent-slt-c24="" value="pole" class="ng-star-inserted"></option>
                         </datalist>
   
                    <div
                      class="fv-plugins-message-container"
                      *ngIf="isControlHasError('title', 'required')"
                    >
                      <div class="fv-help-block">
                        <span>
                         Hobbies is required</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-7 mt-6">
                    <label>
                        Description</label>
                    <textarea
                      class="form-control"
                      type="text"
                      formControlName="description"
                      placeholder="
                      Description
                      "
                      maxlength="100"
                    ></textarea>
                    <div
                      class="fv-plugins-message-container"
                      *ngIf="isControlHasError('description', 'required')"
                    >
                      <div class="fv-help-block">
                        <span>
                          "Description"</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 mt-15">
                    <button
                      (click)="saveUpdatedItem(item)"
                      class="btn btn-sm font-weight-bolder btn-light-success mr-5"
                    >
                      <i class="la la-check"></i>
                    </button>
                    <button
                      (click)="cancelEditButtonOnClick(item)"
                      class="btn btn-sm font-weight-bolder btn-light-danger mr-5"
                    >
                      <i class="la la-close"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <form class="" [formGroup]="basicForm">
  <div id="add-recognition">
    <div class="form-group row" *ngIf="itemForAdd && itemForAdd._isEditMode">
      <div class="col-lg-12">
        <div class="form-group row align-items-center">
          <h3 class="col-lg-12 font-weight-bold">{{'SOCIAL.HOBBIES.NEW_HOBBY' | translate}}</h3>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-9 mt-6">
                <label>{{'SOCIAL.HOBBIES.FIELD.TITLE' | translate}}</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="addTitle"
                  placeholder="{{'SOCIAL.HOBBIES.FIELD.TITLE_INPUT' | translate}}"
                  list="hobbies"
                />
                <datalist id="hobbies">
                    <option _ngcontent-slt-c24="" value="Act in theater" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Adventure travels" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aerial dance" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aerobics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aeromodelling" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aikido" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Airsoft" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Alpine ski" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Amateur radio" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Animation (Creation of animated and cartoon films)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aquariums" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Archery" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Aromatherapy" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Art prints" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Art: attend cultural shows (Theater, music, dance, opera, zarzuela)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Artistic drawing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Artistic paint" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Associations (Membership of social groups of artistic, sports, festive, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Astrology" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Astronomy" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Audiobooks" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="BMX (motocross bike)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Backgammon" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Badminton" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Baking / pastry and cupcakes" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Balloons (work with moldable balloons)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Baseball" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Basketball" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Basketry" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Batik and fabric printing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Beauty and aesthetics (Hobby a)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Beer (Homemade from)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bees (Beekeeping or beekeeping)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bibliophilia" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bicycle (cycling)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Billiards" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bingo" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bird watching" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Blog (and videoblog)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Board games (general)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bodyboard" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bodybuilding" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bonsai (dwarf trees)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Book binding" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bowling (Bowling, bowling)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Boxing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bricolage or DYI" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Bulls (fondness for)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Buy (or sell: clothes, accessories, objects, etc)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Calligraphy and lettering" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Camping" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Canariculture" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Candles (Creation of candles)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Canoeing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Canyoning" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Capoeira (type of Brazilian wrestling)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Car driving (sports car)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Card games or playing cards" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Carpentry" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Carrovela and blokart (Land sailing)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Ceramics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Chalkpaint (chalk paint)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Checkers (game of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Chess" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Chi kung or qui gong (healthy relaxation activity)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Chorus" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Cinema (movie hobby, watching movies)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Classic / Antique Cars" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Climbing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Clothing customization" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Clothing design (textile)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Collectibles (Stamps, coins, antiques, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Comics (Creation of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Comics (Reading)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Comidista (foodie)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Computer programming" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Conferences (Attend)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Cosmetics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Croquet" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Cross-country or Nordic skiing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Cruises (trips of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Cultural volunteering" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Curling" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dance" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Darts (throwing darts)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Decoupage (Surface decoration)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Design and creation of web pages" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Diary (write a)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Digital art" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dioramas (and nativity scenes)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Diving (scuba diving)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dj (disk jockey)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Documentaries (fondness for)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dogs (contests / exhibitions)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dollhouses (construction of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dolls (make)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Domino" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Dried flowers (work with)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Electronics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Enamels on metal (on fire)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Environmental volunteering" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Falconry (training of birds of prey)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fantasy Sports (Fantasy League)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fashion (Fashion hobby)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fencing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Figure painting (Polychrome, gold)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fishing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fitness and maintenance gymnastics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Football" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Framing pictures" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Frisbee (flying disc)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Fronton" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Furniture restoration" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Games with electronic devices (PC, Tablet, mobile)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Gardening" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Genealogy and family history" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Geocaching and treasure hunting" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Go (game)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Golf" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Gotcha / Paintball" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Graffiti and urban art" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Grass hockey" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Handball" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Hobbies (crosswords, sudokus, children's, memory, etc ...)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Home garden" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Horse (riding, horse riding, horseback riding)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Hydroponics (liquid culture)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Ice Hockey" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Ice skating" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Ikebana (Floral Art)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Indoor football" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Inside plants" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Interior decoration" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Internet (browse)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Invest in the stock market" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Jams (prepare)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Jet ski" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Jewelery and costume jewelery (Creation of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Jiu Jitsu" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Judo" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kanzashi flowers" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Karaoke" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Karate" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kendo" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kitchen" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kites (fly kites)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kitesurf" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kokedamas (Plants on moss)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Kung Fu" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Labor (Knitting, embroidery, lace, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Leather work as a hobby" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Lego (constructions with pieces)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Lomography (type of photography)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Lottery" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Magazines and newspapers (read)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Magic (illusionism, sleight of hand)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mahjong (Chinese tile game)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Markets / flea markets" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Meditation" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Metals (search for)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mindfulness or mindfulness (Relaxing technique)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Minerals (search for)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Model making with matches" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Modeling (Planes, cars, boats, drones, figures, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Motor boats" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Motorcycle driving (motorcycling)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mountain bike" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mountain races" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mountaineering / mountaineering" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Mushrooms (searching or gathering)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Music (Hobby)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Musical composition" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Nails (decoration of), manicure and pedicure" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Nautical or water skiing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Nordic walk or walk (Nordic walking)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Observation of trains and planes" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Orientation" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Origami (or origami)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Paddle" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Paddle surf (paddle surf)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Painting on silk" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Paper mache" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Paragliding" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Parkour (freerunning)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Patchwork and bedspreads" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Petanque" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Photography" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Pigeons (Pigeon breeding, pigeon racing and colombiculture)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Pilates" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Ping pong or table tennis" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Play a musical instrument" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Podcasts (Hobby / Creation)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Puppets (theater of)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Puzzles" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Rafting" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Reading" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Reiki (spiritual healing technique)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Remote Control Vehicles (RC)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Robotics" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Role playing games" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Roller hockey" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Roller skating" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Rubber stamps or stamps (created with)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Rubik's cube (puzzle)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Rugby" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Running" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sailing (gliding)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Scrapbook (scrapbook)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sculpture" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sewing, cutting and tailoring" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Skateboard" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Skydiving" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Slime (play with slime, a slimy mass)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Snow rackets" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Snowboard" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Soap (Make)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Soccer" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Social networks (Facebook, Youtube, twitter, Instagram, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Social volunteering" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Speleology" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sport (Attend sporting events)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sport (See sport on tv, online, magazines or newspapers, radio)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sport hunting)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sport shooting (Olympic) with firearm" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sports (and recreational) aviation" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sports and recreational sailing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Sports rowing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Squash" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Study (Languages, other subjects, etc.)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Surf" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Swim (Swimming)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="TV series (love of series)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Table hockey (air hockey)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Taekwondo" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tai Chi" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Take a walk" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tarot" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tattoos" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Taxidermy" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tejo (Colombian game)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tennis" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Themed board games" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tinted glass" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Token Games (General)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Tourism cycle" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Travel" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Trekking" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Triathlon, Marathon, Ironman" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Underwater fishing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Urban exploration (urbex)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Urban visits" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Video (Making videos)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Video game" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Visit monuments and archaeological sites" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Visit museums and exhibitions" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Volleyball" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Volunteering in sport" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Wakeboard" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Water polo" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Weaving (with a homemade loom)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Weightlifting (weight lifting)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Wild plants, including trees and shrubs (Hobby)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Windsurfing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Wine tasting" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Wood (wood carvings)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Wrestling (Olympic, Greco-Roman… ..)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Write literature (prose, poetry, theater, essay, scripts)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Yoga" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Youtuber" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="Zumba (Gymnastic dance)" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="kick boxing" class="ng-star-inserted"></option>
                    <option _ngcontent-slt-c24="" value="pole" class="ng-star-inserted"></option>
               
                </datalist>
                
                <div
                  class="fv-plugins-message-container"
                  *ngIf="isControlHasError('addTitle', 'required')"
                >
                  <div class="fv-help-block">
                    <span>{{'SOCIAL.HOBBIES.FIELD.TITLE_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7 mt-6">
                <label>{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION' | translate}}:</label>
                <textarea
                  class="form-control"
                  type="text"
                  formControlName="addDescription"
                  placeholder="{{'SOCIAL.HOBBIES.FIELD.DESCRIPTION_INPUT' | translate}}"
                  maxlength="300"
                ></textarea>
                <div class="fv-plugins-message-container float-right">
                  <div class="fv-help-block">
                    <span>({{ 300 - (basicForm.addDescription ? basicForm.addDescription.length : 0) }}/300)</span>
                  </div>
                </div>
                <div
                  class="fv-plugins-message-container"
                  *ngIf="isControlHasError('addDescription', 'required')"
                >
                  <div class="fv-help-block">
                    <span>
                      {{'SOCIAL.HOBBIES.FIELD.DESCRIPTION_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mt-15" *ngIf="!isEditing">
                <a
                  href="javascript:;"
                  (click)="saveNewItem()"
                  class="btn btn-sm font-weight-bolder btn-light-success mr-5"
                >
                  <i class="la la-check"></i
                ></a>
                <a
                  href="javascript:;"
                  (click)="cancelAddButtonOnClick()"
                  class="btn btn-sm font-weight-bolder btn-light-danger mr-5"
                >
                  <i class="la la-close"></i
                ></a>
              </div>
              <div class="col-lg-3 mt-15" *ngIf="isEditing">
                <button
                  (click)="saveUpdatedItem('')"
                  class="btn btn-sm font-weight-bolder btn-light-success mr-5"
                >
                  <i class="la la-check"></i>
                </button>
                <button
                  (click)="cancelEditButtonOnClick('')"
                  class="btn btn-sm font-weight-bolder btn-light-danger mr-5"
                >
                  <i class="la la-close"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div
  *ngIf="itemResultList && itemResultList.length === 0 && !isSwitchedToEditMode"
  class="alert alert-custom alert-light-primary alert-shadow fade show gutter-b"
  role="alert"
>
  <div class="alert-text">{{'SOCIAL.HOBBIES.NO_DATA' | translate}}</div>
</div>
<div class="form-group row">
  <div class="col-lg-4">
    <button
      *ngIf="itemForAdd && !itemForAdd._isEditMode && !isSwitchedToEditMode"
      (click)="addItemButtonOnClick()"
      class="btn btn-sm font-weight-bolder btn-light-primary"
    >
      <i class="la la-plus"></i>{{'BUTTON.ADD' | translate}}
    </button>
  </div>
</div>
</div>
<!--begin: Wizard Actions-->
<div class="d-flex justify-content-between border-top mt-5 pt-10">
<div class="mr-2">
  <!-- <button *ngIf="!isPopup" type="button" class="btn btn-light-primary font-weight-bolder text-uppercase" (click)="prevStep()">Previous</button> -->
</div>
<div>
  <ng-container *ngIf="loading">
    <span class="spinner spinner-primary mr-10"></span>
  </ng-container>
  <button
    type="button"
    (click)="onSubmit()"
    class="btn btn-primary font-weight-bolder text-uppercase"
  >
  {{'BUTTON.SAVE_EXIT' | translate}}   </button>
</div>
</div>
<!--end: Wizard Actions-->
